import "../styles/Select.css";
import { useEffect, useRef, useState } from 'react';

const Select = ({ label, options, selectedOption, setSelectedOption }) => { 
  const [searchText, setSearchText] = useState('');
  const [isListShown, setIsListShown] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    if(!!selectedOption){
      const selectOpt = options.filter((opt) => opt.id === selectedOption)
      .map(opt => opt.description);
      if(selectOpt.length > 0){
        setSearchText(selectOpt[0]);
      }
    }
  }, [selectedOption, options])

  const filteredOptions = options.filter((option) =>
    option.description.includes(searchText)
  );

  const handleSelect = ({id, description}) => {
    setSearchText(description);
    setSelectedOption(id);
    setIsListShown(false);
  };

  useEffect(() => {
    // Add click event listener to the document body
    document.body.addEventListener('click', handleGlobalClick);

    // Clean up the event listener on component unmount
    return () => {
      document.body.removeEventListener('click', handleGlobalClick);
    };
  }, []);

  const handleGlobalClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsListShown(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value); 
    setIsListShown(true)
  }

  const clearInput = () => {
    setSearchText("");
    setSelectedOption(0);
  }

  return (
    <div className="select">
      {label && <label className="select-label">{label}</label>}
      <input
        ref={selectRef}
        className="select__main"
        type="text"
        placeholder="לחץ לבחירה"
        value={searchText}
        onChange={handleInputChange}
        onClick={() => setIsListShown(!isListShown)}
      />
      {!!searchText
      && <svg onClick={clearInput} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" 
          className="bi bi-x-circle-fill close-btn" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>
      }
      {isListShown && <div className="select__options">
        {filteredOptions.map((option) => (
          <div
            key={option.id}
            className={'select__option'}
            onClick={() => handleSelect(option)}
          >
            {option.description}
          </div>
        ))}
      </div>}

      
    </div>

    
    // <div className="select">
    //   {label && <label className="select-label">{label}</label>}
    //   <select className="select__main" value={selectedOption} onChange={handleSelect}>
    //     {options.map((option, index) => (
    //       <option className="select__option" key={index} value={option.id}>
    //         {option.description}
    //       </option>
    //     ))}
    //   </select>
    // </div>
  );
};

export default Select;