import Button from "../Button";
import Input from "../Input";
import image from "../../assets/rafiki.png";
import axiosService from "../../utils/axiosService";
import localStorageService from "../../utils/localStorageService";
import { useEffect, useState } from "react";
import "../../styles/PhoneVerification/SendCode.css";
import magen from "../../assets/logo-magen.png";
import HomeScreen from "../HomeScreen";
import { useNavigate } from "react-router-dom";


const SendCode = ({
  handleButtonClick,
  phoneNumber,
  setPhoneNumber,
  userName,
  resetPassword,
}) => {
  // more then 9 digits and only digits
  const phoneRegex = /^\d{10,}$/;
  const [btnText, setBtnText] = useState("לשלב הבא")
  const navigate = useNavigate();
  let showError = !phoneRegex.test(phoneNumber) && phoneNumber.length > 0;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);


  const resetUserPhoneVerification = async(data) => {
    try {
      setBtnText("אנא המתן...")
      const response = await axiosService.post(
        "/api/forgotPassword",
        data
      );
      console.log(response.data);

      const tempToken = response.data;
      localStorageService.store(tempToken, "tempToken")
  
      handleButtonClick();
    } catch (error) {
      console.log(error);
      if(error.response.data.error === "Not Found"){
        setBtnText("מספר לא קיים")
        setPhoneNumber("")
      } else {
        setBtnText("אנא נסה שנית")
      }
  

    }
  }

  const createUserPhoneVerification = async(data) => {
    try {
      setBtnText("אנא המתן...")
      const response = await axiosService.post(
        "/api/reguser/verification",
        data
      );

      const tempToken = response.data;
      localStorageService.store(tempToken, "tempToken")

      handleButtonClick();
    } catch (error) {
      if(error.response.data === "User already exists"){
        setBtnText("מספר קיים במערכת")
        setPhoneNumber("")
      } else {
        setBtnText("אנא נסה שנית")
      }
  

    }
  }

  const handleSubmit = async () => {
    if(isButtonDisabled) return;
    const data = {
      phone: phoneNumber,
    };
    if(resetPassword) return await resetUserPhoneVerification(data)
    await createUserPhoneVerification(data);
  };

  useEffect(() => {
    const isDisabled = !phoneRegex.test(phoneNumber)
    setIsButtonDisabled(isDisabled)
    if(isDisabled && phoneNumber.length > 0){
      setBtnText("לשלב הבא")
    }
  }, [phoneNumber, phoneRegex])
  

  return (
    <div className="send-code" style={{width:"100vw"}}>
      <div className="div">
        <div className="rectangle-2" />
       
        <img className="fire-prevention" alt="Fire prevention" src={image} />
        <div className="group">
          {
            !resetPassword?
            <>
              <div className="text-wrapper-2">
                <img width="70px" src={magen} alt="" />
                <p>הוזמנת ל</p>
              </div>
              {userName ? <p className="p">הוזמנת ע"י {userName} למערכת שומרי הארץ <br /> מערכת לקישור בין זקוקים ומתנדבים לעזרה בשעת חרום
              </p>: <p className="p">ברוך הבא למערכת שומרי הארץ  - מערכת לקישור בין זקוקים לעזרה בשעת חירום</p>}
            </>
          : <div className="text-wrapper-2">
              <p>שכחתי סיסמא</p>
            </div>
          }
          <div className="group-2">
            
            <div className="component">
              <div className="text-wrapper-3">מה מספר הטלפון שלך?</div>
              <Input
                style={{maxWidth: "95vw", width:"50vw"}}
                className="component-22"
                divClassName="component-22-instance"
                overlapGroupClassName="component-instance"
                property1="variant-2"
                text="מספר טלפון"
                onChange={()=>{}}
                inputValue={phoneNumber}
                setInputValue={setPhoneNumber}
                inputType={'tel'}
              />
              {showError?<span style={{color: 'red'}}>מספר לא תקין</span>:null}
            </div>
          </div>
        </div>
        <div className="btn-container">
        <Button
          textButton={btnText}
          disabled={isButtonDisabled}
          onClick={handleSubmit}
          className={isButtonDisabled ? "button-instance disabled" : "button-instance text-element-class"}/>
        </div>
        {!resetPassword && <HomeScreen/>}
        {resetPassword?<div onClick={() => navigate("/")} style={{textAlign:"center", fontSize:"18px", margin:"10px auto", padding:"20px auto", color:"#014886"}}>חזרה לדף הבית</div>:<></>}
      </div>
    </div>
  );
};

export default SendCode;
