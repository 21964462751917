import { useState } from "react";
import AdminVerifyPhone from "../components/AdminPhoneVerification/AdminVerifyPhone";
import AdminSendCode from "../components/AdminPhoneVerification/AdminSendCode";

const AdminPhoneVerification = () => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleButtonClick = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <>
      {step === 1 && (
        <AdminSendCode
          handleButtonClick={handleButtonClick}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
        />
      )}
      {step === 2 && (
        <AdminVerifyPhone phoneNumber={phoneNumber} />
      )}
    </>
  );
};

export default AdminPhoneVerification;
