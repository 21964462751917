import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../styles/AdminPanel/NavBarItem.css";

const NavBarItem = ({ onComponentClick, imageSrc, text, isActive }) => {
  return (
    <div
      className={`component ${isActive ? "active" : "gray"}`}
      onClick={() => {
        onComponentClick && onComponentClick();
      }}
    >
      <FontAwesomeIcon icon={imageSrc} className={`icon`} />
      <div className="text-wrapper-6">{text}</div>
    </div>
  );
};

export default NavBarItem;
