// all numbers
export function validateNumber(inputValue) {
    const pattern = /^\d+$/;
    return pattern.test(inputValue);
}
  
  //all letters
export function validateAlphabetic(inputValue) {
    const alphabeticPattern = /^[A-Za-zא-ת\s'"` -]+$/;
    return alphabeticPattern.test(inputValue);
}
  
// if input empty, return true if empty
export function isInputEmpty(value) {
    return !value || value.trim() === "";
}
  
// if this email
export function validateEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
}

//minimum 8 characters
export function minChracters(str) {
    return str.length >= 6;
}

export function leastOneCapitalLetter(str) {//לפחות אות גדולה אחת
    return str.match(/[A-Z]/)
}

export function leastOneLowercaseLetter(str) {//לפחות אות קטנה אחת
    return str.match(/[a-z]/)
}

export function leastOneSpecialLetter(str) {//לפחות תו אחד מיוחד
    return str.match(/[!@#$%^&*(),.?":{}|<>]/)
}

export function isValidIDNumber(id) {
	// id = String(id).trim();
    console.log("trimed Id", id);
	if (id.length > 9 || isNaN(id)) return false;
	id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
		return Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) % 10 === 0;
}

// Checks if the string starts with "05" and contains exactly 10 digits
export function isPhoneNumber(phoneNumber) {
    return /^05\d{8}$/.test(phoneNumber);
}

  