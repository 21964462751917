import { useState, useContext } from "react";
import CAStepOne from "../components/CreateAdmin/CAStepOne";
import CAStepTwo from "../components/CreateAdmin/CAStepTwo";
import CALoading from "../components/CreateAdmin/CALoading";
import CASuccess from "../components/CreateAdmin/CASuccess";
import axiosInstance from "../utils/axiosService";
import localStorageService from "../utils/localStorageService";
import UserContext from "../utils/UserContext";

function CreateAdmin() {
  const [step, setStep] = useState(1);
  const {setUser} = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  // const [areaName, setAreaName] = useState("");

  const [profileImage, setProfileImage] = useState(null);
  const [IDImage, setIDImage] = useState(null);


  const handleButtonClick = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const submitRegistertion = async (e) => {
    e.preventDefault();
    try {
      axiosInstance.disableAuthHeader();
      const tempToken = localStorageService.get("token", "tempToken");
      // const item = areas.find((obj) => obj.description === areaName || obj.id === Number(areaName));
      const requestBody = {
        firstName: firstName,
        lastName: lastName,
        password: password,
        token: tempToken,
      };

      let url = "/api/areaadmin/register";

      // if (uuid) {
      //   url = "/api/reguser/register/withInviter";
      // }

      const response = await axiosInstance.post(url, requestBody);

      console.log("Registration successful:", response.data);
      localStorageService.store(response.data);

      axiosInstance.enableAuthHeader();
      const userDetails = await axiosInstance.get("/api/user/details");
      console.log("User details:", userDetails.data);
      setUser(userDetails.data)

    if (userDetails) { 
      handleButtonClick();

    }
    } catch (error) {
      // console.error("Error during registration:", error);
    }
  };

  const saveUserAndProcceed = async() => {
    axiosInstance.enableAuthHeader();
    const userDetails = await axiosInstance.get("/api/user/details");
    console.log("User details:", userDetails.data);
    setUser(userDetails.data);
    handleButtonClick();
  }

  return (
    <>
      
      {step === 1 && (
        <CAStepOne
          onButtonClick={handleButtonClick}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          password={password}
          setPassword={setPassword}
          verifyPassword={verifyPassword}
          setVerifyPassword={setVerifyPassword}
          submitRegistertion={submitRegistertion}
          // areas={areas}
          // setAreas={setAreas}
          // setAreaName={setAreaName}
          // areaName={areaName}
        />
      )}
      {step === 2 && (
        <CAStepTwo
          onButtonClick={saveUserAndProcceed}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          IDImage={IDImage}
          setIDImage={setIDImage}
        />
      )}
      {step === 3 && <CALoading onButtonClick={handleButtonClick} />}
      {step === 4 && <CASuccess />}
    </>
  );
}

export default CreateAdmin;
