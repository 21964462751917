

const EventDetailsTable = ({event}) => {
  return (
    <div className="details-table-wrapper">
    <table className="event-details__table">
        <tbody>
            <tr>
                <td>{event.isResolved ? "בוצע" : "לא בוצע"}</td>
                <td>האם בוצע ?</td>
            </tr>
            <tr>
                <td><a href={`https://wa.me/${event.reportedBy}`}>{event.reportedBy}</a></td>
                <td>דווח על ידי:</td>
            </tr>
            <tr>
                <td>{event.createdAt}</td>
                <td>תאריך דיווח:</td>
            </tr>
            <tr>
                <td>{event.reportedByFullName}</td>
                <td>שולח הקריאה:</td>
            </tr>
            <tr>
                <td>{event.description}</td>
                <td>תיאור</td>
            </tr>
            <tr>
                <td>{event.areaDescription}</td>
                <td>אזור</td>
            </tr>
            <tr>
                <td>{event.eventTypeDescription}</td>
                <td>סוג המקרה:</td>
            </tr>
            <tr>
                <td>{event.numberOfAttackers || 'לא מצוין'}</td>
                <td>כמות תוקפים:</td>
            </tr>
            <tr>
                <td>{event.address}</td>
                <td>כתובת:</td>
            </tr>
        </tbody>
    </table>
    <div className="dummy-bottom"></div>
</div>
  )
}

export default EventDetailsTable