
const encrypt = (data) => {
  try {
    return btoa(unescape(encodeURIComponent(data)));
  } catch (e) {
    console.error('Failed to encrypt:', e);
    return null;
  }
}

const decrypt = (data) => {
  try {
    return decodeURIComponent(escape(atob(data)));
  } catch (e) {
    console.error('Failed to decrypt:', e);
    return null;
  }
}



const localStorageService = {
  store: (data, LOCAL_STORAGE_KEY = "שומרי הארץ") => {
    try {
      console.log('check')

      const currentData = localStorageService.get(null, LOCAL_STORAGE_KEY) || {};
      const updatedData = { ...currentData, ...data };

      const {token} = updatedData;

      if (token && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler) {
        window.webkit.messageHandlers.toggleMessageHandler.postMessage({token});
      }

      if(token && window.Android) {
        window.Android.sendToken(token)
      }

      const serializedData = JSON.stringify(updatedData);
      const encryptedData = encrypt(serializedData);

      if (encryptedData) {
        localStorage.setItem(LOCAL_STORAGE_KEY, encryptedData);
      }
    } catch (error) {
      console.error("Failed to store data to localStorage:", error);
    }
  },

  get: (property, LOCAL_STORAGE_KEY = "שומרי הארץ") => {
    try {
      const encryptedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (!encryptedData) return null;
      
      const serializedData = decrypt(encryptedData);
      if (!serializedData) return null;
      
      const data = JSON.parse(serializedData);
      if (property && typeof data === "object") {
        return data[property];
      }
      
      return data;
    } catch (error) {
      console.error("Failed to get data from localStorage:", error);
      return null;
    }
  },

  delete: (LOCAL_STORAGE_KEY = "שומרי הארץ") => {
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (error) {
      console.error("Failed to delete data from localStorage:", error);
    }
  },
};

export default localStorageService;
