import { ButtonApproveChanges } from '../../AdminPanel/AdminPanelComponents/ButtonApproveChanges';
import Input from '../../Input';
import { useEffect, useState } from 'react'
import { isInputEmpty, isValidIDNumber, validateEmail } from '../../validation/inputValidation';
import Dropdown from '../../DropDown';

const EditPrivateDetails = ({user, submitUserUpdate}) => {

  const [userToEdit, setUserToEdit] = useState({
    ...user,
    age: user["age"] || 0,
    email: user["email"] || "",
    homeAddress: user["homeAddress"] || "",
    idNumber: user["idNumber"] || ""
  });
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [errors, setErrors] = useState({})
  const title = "פרטים אישיים";

  const inputs = [
    {text: "גיל", name: "age", type: "number"},
    {text: "כתובת", name: "homeAddress", type: "text"},
    {text: "תעודת זהות", name: "idNumber", type: "number"},
    {text: "אימייל", name: "email", type: "text"},
  ]

  const languages = [
    {id:"englishSpeaker" ,description:'אנגלית'},
    {id:"arabicSpeaker" ,description:'ערבית'},
    {id:"russianSpeaker", description: 'רוסית' },
    {id:"frenchSpeaker", description:'צרפתית'},
    {id:"amharicSpeaker", description:'אמהרית'},
    {id:"otherSpeakingLanguages", description:'אחר'},
    // !!user.otherSpeakingLanguages ? user.otherSpeakingLanguages : '',
  ]

  const setInitLanguages = () => {
    const selected = [
      user.englishSpeaker ? 'englishSpeaker': "",
      user.arabicSpeaker ? 'arabicSpeaker': "",
      user.russianSpeaker ? 'russianSpeaker' : "",
      user.frenchSpeaker ? 'frenchSpeaker': "",
      user.amharicSpeaker ? 'amharicSpeaker': "",
    ].filter(lan => !!lan);
    setSelectedLanguages(selected)
  } 
  const updateLanguages = () => {
    ['englishSpeaker','arabicSpeaker','russianSpeaker','frenchSpeaker','amharicSpeaker']
    .forEach(lan => userToEdit[lan] = selectedLanguages.includes(lan))
  }

  const updateOtherLanguages = (value) => {
    userToEdit["otherSpeakingLanguages"] = value;
  }

  const updateValue = (inputName, inputCurrentValue) => {
    setUserToEdit((prev) => { return { ...prev, [inputName]: inputCurrentValue}; });
  }

  const handleNameAndAddressValidation = (keyName) => {
    if(isInputEmpty(userToEdit[keyName]) || !userToEdit[keyName]){
      return setErrors({...errors, [keyName]: "שדה חובה"})
    }
    setErrors({...errors, [keyName]: ""})
  }
  const handleAgeValidation = () => {
    if(!userToEdit["age"]) return setErrors({...errors, age: "שדה חובה"});
    if(userToEdit["age"] < 1 || userToEdit["age"] > 120) return setErrors({...errors, age: "חייב להיות מספר בין 1ל120"});
    setErrors({...errors, age: ""})
  }
  const handleIdNumberValidation = () => {
    if(!userToEdit["idNumber"]) return setErrors({...errors, idNumber: "שדה חובה"});
    if(!isValidIDNumber(userToEdit["idNumber"])) return setErrors({...errors, idNumber: "מספר תז לא תקין"});
    setErrors({...errors, idNumber: ""})
  }
  const handleEmailValidation = () => {
    if(!userToEdit["email"]) return setErrors({...errors, email: "שדה חובה"});
    if(!validateEmail(userToEdit["email"])) return setErrors({...errors, email: "אימייל לא תקין"});
    setErrors({...errors, email: ""})
  }

  const onSubmit = () => {
    const isValid = Object.values(errors).filter(err => !!err).length === 0;
    if(!isValid) return;
    // setUser(userToEdit);
    submitUserUpdate(userToEdit);
  }

  useEffect(() => { setInitLanguages(); }, [user])

  useEffect(() => {handleNameAndAddressValidation("homeAddress")}, [userToEdit["homeAddress"]]);
  useEffect(() => {handleAgeValidation()}, [userToEdit["age"]]);
  useEffect(() => {handleIdNumberValidation()}, [userToEdit["idNumber"]]);
  useEffect(() => {handleEmailValidation()}, [userToEdit["email"]]);
  useEffect(() => {updateLanguages()}, [selectedLanguages]);

  return (
    <section className="read-edit-container">
    <div className="overlap">
    <h2 style={{color:"white", textAlign: "end",width:"100%"}}>{title}</h2>

    </div>
    <div className='read-edit-form-wrapper '>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {inputs.map(inpt => { return (
          <div key={inpt.text} style={{padding: "10px 10px"}}>
            <Input
                className="component-22"
                divClassName="component-22-instance"
                overlapGroupClassName="component-instance"
                property1="variant-2"
                text={inpt.text}
                inputValue={userToEdit[inpt.name]}
                setInputValue={(newValue) => updateValue(inpt.name, newValue)}
                inputType={inpt.type}
              />
              {!!errors[inpt.name]?<div className='error-message'>{errors[inpt.name]}</div>: null}
          </div>)
        })}
        <div style={{ margin:"20px auto", padding: "auto", minWidth:"90vw", maxWidth: "90vw"}}>
          <Dropdown
            items={languages}
            selectedItems={selectedLanguages}
            setSelectedItems={setSelectedLanguages}
            label={"שפות"}
            otherItemOption={userToEdit["otherSpeakingLanguages"] || ""}
            setOtherOptionContent={updateOtherLanguages}
          />
        </div>
      </div>
    <ButtonApproveChanges style={{maxWidth:"80%"}} onClick={onSubmit}/>
    </div>
  </section>
  )
}

export default EditPrivateDetails