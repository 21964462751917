import InviteAdminPopupInput from "./InviteAdminPopup_Input";
import InviteAdminPopupApproval from "./InviteAdminPopup_Approval";
import '../../styles/InviteAdminPopup/InviteAdminPopup.css';
import { useState } from 'react';

export default function InviteAdminPopup({ setIsPopupOpen }) {
  const [phone, setPhone] = useState('');
  const [area, setArea] = useState('');
  const [isApprovalStage, setIsApprovalStage] = useState(false);

  return (
    <div className="invite-admin">
      <div className="invite-admin_popup">
        <div className="invite-admin_rectangle" />
        {!isApprovalStage
          ? <InviteAdminPopupInput
            handleSetPhone={setPhone}
            phoneNumber={phone}
            handleSetArea={setArea}
            setIsPopupOpen={setIsPopupOpen}
            setIsApprovalStage={setIsApprovalStage} />
          : <InviteAdminPopupApproval
            phone={phone}
            area={area}
            setIsPopupOpen={setIsPopupOpen}
            setIsApprovalStage={setIsApprovalStage} />}
      </div>
    </div>
  )
}