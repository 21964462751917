import PropTypes from "prop-types";
import { useEffect, useReducer, useState,  } from "react";
import "../styles/Input.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Input = ({
  property1,
  className,
  overlapGroupClassName,
  divClassName,
  text = "שם מלא",
  inputValue,
  setInputValue,
  inputType,
  required,
  validationText,
  handleKeyDown,
  disabled
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const [state] = useReducer(reducer, {
    property1: property1 || "default",
  });
  useEffect(() => {
    // console.log(validationText, "input")
  }, [validationText])

  const handleChange = (e) => {
    const newPassword = e.target.value;
    setInputValue(newPassword);
  }

  const togglePasswordVisibitily = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    setTimeout(() => {
      setShowPassword(false);
    }, 1000);
  }

  return (
    <div className={`component ${state.property1} ${className}`}>
      {state.property1 === "default" && (
        <>
          <input
            className="text-wrapper overlap-group"
            placeholder={text}
            value={inputValue}
            onChange={handleChange}
            type={inputType !== 'password' ? inputType : showPassword ? 'text' : 'password'}
            minLength={inputType === 'password' ? 6 : 0}
            required={required}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
          {(inputType === 'password' && inputValue)
            && <button onClick={togglePasswordVisibitily}
              className="pass-visibility">
              {showPassword
                ? <VisibilityOffIcon />
                : <VisibilityIcon />}
            </button>}
          {validationText !== "" ? <p className="valText">{validationText}</p> : ""}
        </>

      )}
      {validationText !== "" ? <p className="valText">{validationText}</p> : ""}

      {state.property1 === "variant-2" && (
        <div className={`div-wrapper ${overlapGroupClassName}`}>
          <input
            className={`text-wrapper ${divClassName}`}
            placeholder={text}
            value={inputValue}
            onChange={handleChange}
            type={inputType !== 'password' ? inputType : showPassword ? 'text' : 'password'}
            minLength={inputType === 'password' ? 6 : 0}
            required={required}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
          {(inputType === 'password' && inputValue)
            && <button onClick={togglePasswordVisibitily}
              className="pass-visibility">
              {showPassword
                ? <VisibilityOffIcon />
                : <VisibilityIcon />}
            </button>}
        </div>
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "click":
        return {
          property1: "variant-2",
        };
      default:
        return state;
    }
  }

  if (state.property1 === "variant-2") {
    switch (action) {
      case "click":
        return {
          property1: "default",
        };
      default:
        return state;
    }
  }

  return state;
}

Input.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  text: PropTypes.string,
};

export default Input;
