import feCheck from "../../assets/icons/fe-check.svg";
import { useNavigate } from "react-router-dom";
import "../../styles/CreateUser/CUSuccess.css";
import axiosInstance from "../../utils/axiosService";
import localStorageService from "../../utils/localStorageService";

const CASuccess = () => {
  const navigate = useNavigate();

  const clearAndRedirect = () => {
    // localStorageService.delete();
    // axiosInstance.disableAuthHeader();
    // navigate("/login");
    localStorageService.delete("tempToken");
    navigate("/");
  }

  return (
    <div className="cu-success">
      <div className="div">
        <div className="rectangle" />
        <div className="group">
          <div className="overlap-group">
            <img className="fe-check" alt="Fe check" src={feCheck} />
          </div>
          <div className="text-wrapper">מנהל אזור נוצר</div>

          <button
            type="submit"
            className="rectangle-2"
            onClick={clearAndRedirect}
          >
            <p className="p">מעבר למסך הבית</p>
          </button>

          <button
            type="submit"
            className="rectangle-3"
            onClick={() => navigate("/create-assistant")}
          >
            <p className="p">אני רוצה להיות גם שומר</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CASuccess;
