import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import localStorageService from "./localStorageService";

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorageService.get("token");

  function isTokenExpired(token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        return true;
      }
      return false;
    } catch (e) {
      // console.error("Invalid token", e);
      localStorageService.delete();
      return false;
    }
  }

  const authRule = !token || isTokenExpired(token) ? false : true;

  useEffect(() => {
    if (!authRule) {
      navigate('/login', { state: { from: location } });
    }
  }, [authRule, navigate, location]);

  return authRule ? children : null;
}

export default PrivateRoute;

// import { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import jwtDecode from "jwt-decode";
// import localStorageService from "./localStorageService";

// function PrivateRoute({ children }) {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const [showAnimation, setShowAnimation] = useState(true);

//   const token = localStorageService.get("token");
//   navigate('/animation');



//   function isTokenExpired(token) {
//     try {
//       const decodedToken = jwtDecode(token);
//       const currentTime = Date.now() / 1000;

//       if (decodedToken.exp < currentTime) {
//         return true;
//       }
//       return false;
//     } catch (e) {
//       console.error("Invalid token", e);
//       localStorageService.delete();
//       return false;
//     }
//   }

//   const authRule = !token || isTokenExpired(token) ? false : true;




//   useEffect(() => {
//     if (!authRule && showAnimation) {
//       navigate('/animation');
//       setShowAnimation(false)
//     }
//     if(!authRule && !showAnimation)
//     {
//       navigate('/login', { state: { from: location } });
//     }
//   }, [authRule, navigate, location]);

//   return authRule ? children : null;
// }

// export default PrivateRoute;