import "../styles/Emergency.css";
import { useState, useEffect } from 'react';
import InviteAdminPopup from './InviteAdminPopup/InviteAdminPopup';
import Logout from "./Logout/Logout";
export default function Emergency() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="emergency">
      <div className="div">
        <header className="sysAdmin_header">
          <nav className="sysAdmin-rectangle2" >
            <div className="sysAdmin-nav_container">
              <Logout className="panic-nav_link" />
            </div>
          </nav>
        </header>
        <main className="sysAdmin-main">
          <img className="emergency-overlap" src={require('../assets/logo-magen.png')} alt="תמונה" />

          <button
            type="button"
            className="sysAdmin-button"
            onClick={() => setIsPopupOpen(true)}>
            להזמין מנהל אזור
            {/* <div className="text-wrapper-2">להזמין מנהל אזור</div> */}
          </button>
        </main>


        {isPopupOpen &&
          <InviteAdminPopup setIsPopupOpen={setIsPopupOpen} />}
      </div>
    </div>
  );
}
