import Input from "../Input";
import Button from "../Button";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosService";
import "../../styles/CreateUser/CUStepOne.css";

import {
  validateAlphabetic,
  minChracters,
  leastOneCapitalLetter,
  leastOneLowercaseLetter,
} from "../../components/validation/inputValidation";
import { useNavigate } from "react-router-dom";

const ResetPassword = ({
  password = "",
  setPassword,
  verifyPassword = "",
  setVerifyPassword,
  handleButtonClick,
  resetCode,
  setResetCode,
  sendCodeAgain,
  forgetPasswordTitle
}) => {
  
  const [resetCodeError, setResetCodeError] = useState("");
  const [btnText, setBtnText] = useState("אפס סיסמא");
  const [passwordError, setPasswordError] = useState("");
  const [verifyPasswordError, setVerifyPasswordError] = useState("");
  const navigate = useNavigate()

  const handleValidationAndSubmit = () => {
    let validationPassed = true;

    if (validationPassed) {
      setPasswordError("");
      setVerifyPasswordError("");
      setResetCodeError("")
    }

    if(resetCode.length === 0){
        setResetCodeError("שדה חובה")
        validationPassed = false
    }
    else {
        if(!handleCodeValidationErrors()) validationPassed = false
    }

    if (password.length === 0) {
      setPasswordError("שדה חובה");
      validationPassed = false;
    } else {
      if(!handlePasswordValidationErrors()) validationPassed = false;
    }
    
    // ------------password2 validation-------------------------------------
    if (verifyPassword.length === 0) {
      setVerifyPasswordError("שדה חובה");
      validationPassed = false;
    } else {
      if(!handleVerifyPasswordValidationErrors()) validationPassed = false
    }
    

    if (validationPassed) {
      setPasswordError("");
      setVerifyPasswordError("");
      setResetCodeError("")
      return true;
    }
  };
  

  const handlePasswordValidationErrors = () => {
    if(password.length === 0){
      setPasswordError("");
      return false;
    }
    if (!leastOneCapitalLetter(password)) {
      setPasswordError("חייב להכיל אות גדולה אחת");
      return false;
    }
    if (!leastOneLowercaseLetter(password)) {
      setPasswordError("חייב להכיל אות קטנה אחת");
      return false;
    }
    if (!minChracters(password)) {
      setPasswordError("חייב להכיל לפחות 6 תווים");
      return false;
    }

    setPasswordError("")
    return true;
  }

  const handleCodeValidationErrors = () => {
    if(resetCode.length === 0){
      setResetCodeError("");
      return false;
    }
    if(resetCode.length > 6){
      setResetCodeError("חייב להכיל 6 תווים");
      return false;
    }

    setResetCodeError("")
    return true;
  }

  const handleVerifyPasswordValidationErrors = () => {
      if(verifyPassword.length === 0){
        setVerifyPasswordError("");
        return false;
      }

      if (!leastOneCapitalLetter(verifyPassword)) {
        setVerifyPasswordError("חייב להכיל אות גדולה אחת");
        return false;
      }
      if (!leastOneLowercaseLetter(verifyPassword)) {
        setVerifyPasswordError("חייב להכיל אות קטנה אחת");
        return false;
      }
      if (verifyPassword !== password) {
        setVerifyPasswordError("סיסמאות חייבות להיות זהות");
        return false;
      }
      if (!minChracters(verifyPassword)) {
        setVerifyPasswordError("חייב להכיל לפחות 6 תווים");
        return false;
      }
    setVerifyPasswordError("");
    return true;
  }

  useEffect(() => {handlePasswordValidationErrors(); }, [password]);
  useEffect(() => {handleCodeValidationErrors(); }, [resetCode]);
  useEffect(() => {handleVerifyPasswordValidationErrors(); }, [verifyPassword, password]);

  const handleSubmit = (e) => {
    // @calanit - changed to validation called by submit
    e.preventDefault();
    const isValid = handleValidationAndSubmit();
    if (!isValid) return;
    setBtnText("מאפס...")
    handleButtonClick();
  };
  return (
    <div className="cu-one">
      <div className="div-2">
        <div className="rectangle" />
          <div className="progress-one">
            <div className="steps-btn-container">
            </div>
          </div>

            <div className="text-wrapper-2">איפוס סיסמא</div>
            <form className="frame" onSubmit={handleSubmit}>
              <Input
                className="design-component-instance-node"
                divClassName="component-22-instance"
                property1="variant-2"
                text="קוד איפוס שקיבלת"
                inputValue={resetCode}
                setInputValue={setResetCode}
                // required={true}
              />
              <div className="error-message">{resetCodeError}</div>

              <Input
                className="design-component-instance-node"
                divClassName="component-3"
                property1="variant-2"
                text="סיסמה"
                inputValue={password}
                setInputValue={setPassword}
                inputType="password"
                // required={true}
              />
              <div className="error-message">{passwordError}</div>

              <Input
                className="design-component-instance-node"
                divClassName="component-4"
                property1="variant-2"
                text="הקלד/י סיסמה שוב"
                inputValue={verifyPassword}
                setInputValue={setVerifyPassword}
                inputType="password"
                // required={true}
              />
              <div className="error-message">{verifyPasswordError}</div>

              <Button
                type="submit"
                textButton={btnText}
                className={"button-instance"}
              />
            </form>
            <div className="frame-22">
              <p className="code-p" onClick={sendCodeAgain}>{forgetPasswordTitle}</p>
            </div>
        <div onClick={() => navigate("/")} style={{
            textAlign:"center", 
            fontSize:"18px", 
            padding:"10px auto",
            color:"#014886"}}
            >חזרה לדף הבית
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;