import React, { useContext } from 'react';
import { useState } from "react";
import NavBarItem from "./NavBarItem";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faHome, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../../utils/UserContext";
import "../../../styles/AdminPanel/NavBar.css";

const NavBar = ({ changeActiveComponent }) => {
  const [activeItem, setActiveItem] = useState("AdminHome");
  const { user } = useContext(UserContext);

  const handleComponentClick = (componentName) => {
    setActiveItem(componentName);
    changeActiveComponent(componentName);
  };


  return (
    <div className={`nav-bar nav-bar-instance`}>
      {user?.canApprove && user?.role !== "AREAADMIN" ? (
        <div className="frame-items">
          <NavBarItem
            imageSrc={faHome}
            text="בית"
            onComponentClick={() => handleComponentClick("AdminHome")}
            isActive={activeItem === "AdminHome"}
          />
        </div>
      ) : (
        <div className="frame-items">
          <NavBarItem
            imageSrc={faUserFriends}
            text="שומרים"
            onComponentClick={() => handleComponentClick("ActiveGuards")}
            isActive={activeItem === "ActiveGuards"}
          />
          <NavBarItem
            imageSrc={faHome}
            text="בית"
            onComponentClick={() => handleComponentClick("AdminHome")}
            isActive={activeItem === "AdminHome"}
          />
          <NavBarItem
            imageSrc={faExclamationCircle}
            text="ממתינים"
            onComponentClick={() => handleComponentClick("OpenEvents")}
            isActive={activeItem === "OpenEvents"}
          />
        </div>
      )}

    </div>
  );
};

export default NavBar;
