import { useState, useRef, useEffect } from "react";
import Button from "../Button";
import uploadIcon from "../../assets/icons/upload.svg";
import Progress from "../../icons/progressBar2.svg";
import "../../styles/CreateUser/CUStepTwo.css";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TakeIDPhotoModal from "./TakeIDPhotoModal";

const CUStepTwo = ({ 
  onButtonClick, setStep,
  idFile, setIdFile, 
  profilePhoto, setProfilePhoto }) => {

  const [profilePhotoError, setProfilePhotoError] = useState("");
  const [idFileError, setIdFileError] = useState("");
  const [userHasClicked, setUserHasClicked] = useState(false);
  const [isIdModalOpen, setIsIdModalOpen] = useState(false)
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const streamRef = useRef(null);
  const buttonDisable = !idFile || !profilePhoto;

  const [
    // eslint-disable-next-line
    hasPhoto,
    setHasPhoto,
  ] = useState(false);
  const [takeAphoto, setTakeAphoto] = useState(false);

  const closeIdModal = () => setIsIdModalOpen(false);
  const openIdModal = () => setIsIdModalOpen(true);

  const getVideo = () => {
    setTakeAphoto(true);
    
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        video.style.transform = "scaleX(-1)"; 

        const aspectRatio = video.videoWidth / video.videoHeight;
        const width = 200;
        const height = width / aspectRatio;
        video.width = width;
        video.height = height;
        
        streamRef.current = stream;
      })
      .catch((err) => {
        // Handle errors
      });
  };

  const takePhoto = () => {

    let video = videoRef.current;
    let photo = photoRef.current;

    photo.width = video.videoWidth;
    photo.height = video.videoHeight;

    photo.style.transform = "scaleX(-1)";
    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, photo.width, photo.height);

    let dataURL = photo.toDataURL("image/jpeg");
    dataURLToBlob(dataURL).then((blob) => {
      let imageFile = new File([blob], "photo.jpg", { type: "image/jpeg" });
      setProfilePhoto(imageFile);
    });

    setHasPhoto(true);
    setTakeAphoto(false);

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const dataURLToBlob = async (dataURL) => {
    return fetch(dataURL).then((response) => response.blob());
  };

  const renderPopup = () => {

    const handleCancelBtnClick = () => {
      setTakeAphoto(false);

      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };

    if (takeAphoto) {
      return (
        <div className="popup-wrapper">
          <div className="popup">
            <video ref={videoRef}></video>
            <div className="button-container">
              <Button
                className="button-cancel text-element-class"
                textButton="ביטול"
                onClick={handleCancelBtnClick}
              />
              <Button
                className="button-save text-element-class"
                textButton="שמור"
                onClick={takePhoto}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const uploadImage = async () => {
    setUserHasClicked(true);
    if (!idFile || !profilePhoto) return;
    onButtonClick();
  };

  useEffect(() => {
    console.log("is user id photo: ", idFile);
  }, [idFile]);

  useEffect(() => {
    if(userHasClicked){
      if(!idFile) setIdFileError("יש להוסיף תמונת תעודת זהות")
      else setIdFileError("")
      if(!profilePhoto) setProfilePhotoError("יש להוסיף תמונת פרופיל")
      else setProfilePhotoError("")
    }
  }, [userHasClicked, idFile, profilePhoto])

  return (<>
    <div className={takeAphoto ? "cu-two darker" : "cu-two"}>
      <div className={takeAphoto ? "div darker" : "div"}>
        <div className="rectangle" />
          <div className="progress-one">
            <img src={Progress} alt="שלב 2" />
            <div className="steps-btn-container">
              <div className="box"></div>
              <div className="box"></div>
              <div className="box"></div>
            </div>
          </div>
            <div className="text-wrapper-4">יצירת משתמש</div>
            <div className="group">
              <div className="container">
                <div
                  className={`overlap-group ${idFile ? "uploaded-file-border uploaded-file-title" : ""}`}
                  onClick={openIdModal}
                >

                  <div className="text-wrapper-2">צילום תעודה מזהה</div>
                  {!idFile ? <img
                    className="solar-upload-bold"
                    alt="Solar upload bold"
                    src={uploadIcon}
                  />:
                  <TaskAltIcon className="solar-upload-bold"  style={{fill: "green"}} />}

                </div>
              </div>
              {!!idFileError && <p>{idFileError}</p>}

              <div className="overlap-wrapper">
                <div
                  className={`overlap ${profilePhoto ? "uploaded-file-border uploaded-file-title uploaded-file" : ""}`}
                  onClick={getVideo}
                >

                  <div className="text-wrapper-3">תמונת פרופיל</div>
                  {!profilePhoto? <img
                    className="solar-upload-bold"
                    alt="Solar upload bold"
                    src={uploadIcon}
                  />:
                  <TaskAltIcon className="solar-upload-bold"  style={{fill: "green"}} />}
                </div>
                <div className="takeApick__container">
                  <canvas ref={photoRef}></canvas>

                </div>
              </div>
              {!!profilePhotoError && <p>{profilePhotoError}</p>}

              {renderPopup()}
            </div>
            <Button
              className="button-active-instance text-element-class"
              textButton="סיום ויצירת משתמש"
              onClick={uploadImage}
              disabled={buttonDisable}
            />
      </div>
    </div>
    {
      isIdModalOpen? 
      <TakeIDPhotoModal 
        closeModal={closeIdModal}
        idFile={idFile}
        setIdFile={setIdFile}
      />
      : null
    }
  </>
  );
};

export default CUStepTwo;
