import "../../../../styles/AdminPanel/SlideUpModal.css";


const SlideUpEditModal = ({children, isActive, toggle }) => {
    // const [isOpen, setIsOpen] = useState(false)
    /* */
    if (isActive) {
        return (
            <div className={`modal edit-modal slide-up-edit-modal-zIndex`} style={{height: '100%'}}>
                    <button className="close-button" onClick={toggle}>
                  &times;
                </button>
              <div className="modal-content" style={{height: '100vh'}}>
                {children}
              </div>
            </div>
          );
    }
 
};

export default SlideUpEditModal;
