import "../styles/Loader.css"


const Loader = ({newLoader = "loader"}) => {
  return (
    <div className="loader-container">
      <div className={newLoader}></div>
    </div>
  );
};

export default Loader;