import '../styles/Avatar.css';

function Avatar({ user }) {

  const getInitials = () => {
    const firstInitial = user.firstName ? user.firstName[0] : '';
    const lastInitial = user.lastName ? user.lastName[0] : '';
    return firstInitial + lastInitial;
  };

  return (
    <div className="avatar-container">
      {user.avatar ? (
        <img
          src={user.avatar}
          className="avatar-image"
        />
      ) : (
        getInitials()
      )}
    </div>
  );
}

export default Avatar;
