import "../styles/pages/EventDetails.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from '../assets/logo-magen.png';
import HomeIcon from '@mui/icons-material/Home';
import { createRef, useContext, useEffect, useState } from "react";
import axiosInstance from "../utils/axiosService";
import { useJsApiLoader } from '@react-google-maps/api';
import { calculateDistance } from "../helpers/distance-calculator-helper";
import UserContext from '../utils/UserContext'
import ActiveGuardCard from "../components/EventDetails/ActiveGuardCard";
import CancelEvent from "../components/CancelEvent";
import MapDisplay from "../components/EventDetails/MapDisplay";
import EventDetailsTable from "../components/EventDetails/EventDetailsTable";


const EventDetails = () => {
    const { uuid } = useParams();
    const [event, setEvent] = useState({});
    const [screen, setScreen] = useState(2);
    const { user } = useContext(UserContext);
    const [isMapReady, setIsMapReady] = useState(false);
    const [zelloSession, setZeloSession] = useState(null);
    const [eventParticipants, setEventParticipants] = useState([]);
    const [stream, setStream] = useState(null);
    const WKButtonRef = createRef();
    const navigate = useNavigate();
    const [isCancelEvent, setIsCancelEvent] = useState(false);


    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyD5Mfm8l-OYK6wBoizmx5qzlMczMnF_XCA",
        language: "he"
    })

    useEffect(() => {
        if(!!uuid){
            setEventData();
            const eventInterval = setInterval(() => {
                if(!event || event.isResolved) return clearInterval(eventInterval);
                setUserLocation();
                updateParticipants();
            }, 10000)
            return () => clearInterval(eventInterval)
        }
    }, [uuid]);

    const setUserLocation = () => {
        try{
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(
                  function (position) {
                    const location = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    sendUserLocationToServer(location);
                  },
                  function (error) {
                    console.log("Failed to get location");
                  }
                );
              }
        }catch(err){}
    }

    const sendUserLocationToServer = async(location) => {
        try{
            axiosInstance.enableAuthHeader();
            await axiosInstance.post(`/api/user/location`, location)
        }
        catch(err){ console.log(" Failed to update location") }
    }

    useEffect(() => {
        if (!!event && event.latitude && event.longitude) setIsMapReady(true);
    }, [event]);

    const getEvent = async() => {
        try{
            await axiosInstance.enableAuthHeader();
            const eventData = await axiosInstance.get(`/api/event/event/${uuid}`)
                .then(response => {
                    const ED = response.data;
                    
                    ED.eventParticipants = participantsDistancePipe(ED);
                    return ED;
                })
                .catch((error) => null);
            return eventData;
        }
        catch(err){
            return null;
        }
    }

    const setEventData = async () => {
        const eventData = await getEvent();
        if(!!eventData && !!eventData.eventParticipants){
            setEvent(eventData);
            setEventParticipants(eventData.eventParticipants);
        }
    }

    const updateParticipants = async () => {
        const eventData = await getEvent();
        if(!!eventData && !!eventData.eventParticipants){
            console.log("updated participants! (for location purposes)");
            setEventParticipants(eventData.eventParticipants);
        }
    }

    const startSession  = (channel, authToken, username, password) => {
        var session = new window.ZCC.Session({
            serverUrl: 'wss://zellowork.io/ws/shomrey',
            username: username,
            password: password,
            channel: channel,
            authToken: authToken,
            maxConnectAttempts: 5,
            connectRetryTimeoutMs: 1000,
            autoSendAudio: true
        });
        setZeloSession(session)
    }

    const createConnection = () => {
        try{
            window.ZCC.Sdk.init({
                player: true,  // true by default
                decoder: true, // true by default
                recorder: true, // true by default
                encoder: true, // true by default
            }, function(err) {
                if (err) {
                    console.trace(err);
                    return;
                }
                const { zelloUser: {
                    password, 
                    token, 
                    zelloUsername, 
                    channels}} = event;
                if(!!password && !!token && !!zelloUsername && !!channels){
                    startSession(channels[0],token,zelloUsername, password);
                }
            })
        }
        catch(err){
            console.log("error on connection");
        }
    }

    useEffect(() => {
        if(!!zelloSession){
            zelloSession.connect()
        }
    }, [zelloSession])

    // Add distance to participant and sort participants by closest to event location
    const participantsDistancePipe = (ED) => {
        const mappedParticipants = ED.eventParticipants.map(p => {
            const distance = calculateDistance(ED, p);
            let distInWords;
            if(distance === -1) distInWords = "אין מיקום"
            else if(distance < 1) distInWords = `מרחק ${(distance * 1000).toFixed(0)} מטר`
            else distInWords = `מרחק ${(distance).toFixed(1)} ק"מ`;
            return { ...p, distance, distInWords }
        }).sort(compareDistances)

        function compareDistances(a, b){
            if (a.distance === -1 && b.distance === -1) { return 0; } 
            else if (a.distance === -1) { return 1; } 
            else if (b.distance === -1) { return -1; } 
            else { return a.distance - b.distance; }
        };
        return mappedParticipants;
    }

    const closeEvent = () => {
        try {
          axiosInstance.enableAuthHeader();
          axiosInstance.put(`/api/event/event/${uuid}/close`)
          .then((res) => {
            setEvent(res.data);
            const updatedEvent = {...event, isResolved: true}
            setEvent(updatedEvent)
            setIsCancelEvent(false);
            // navigate("/");
          });
        } catch (error) {
          console.log(error);
        }
      };

    const startRecording = (event) => {
        event.preventDefault();
        try{
            if(!!zelloSession){
                if(!!stream) stream.stop();
                setStream(zelloSession.startVoiceMessage());
                WKButtonRef.current.style.backgroundColor = "#04ac04"
                WKButtonRef.current.style.borderColor = "#04ac04"
            } 
        }
        catch(err){
            stream.stop();
            console.log("Failed to start recording");
        }
    }

    const stopRecording = () => {
        try{
            if(!!zelloSession && !!stream){
                stream.stop();
                WKButtonRef.current.style.backgroundColor = "#013D70"
                WKButtonRef.current.style.borderColor = "#013D70"
            };
        }
        catch(err) {
            console.log("Failed to stop recording");
        }
    }

    const handleDontCloseEvent = () => {
        setIsCancelEvent(false);
    }

    const onUserCloseEventBtnClick = () => {
        setIsCancelEvent(true);
    }

    return (
        <>
            {isCancelEvent?
                <div className="ask-cancel-screen-wrapper">
                    <CancelEvent
                      gpsLocation={!!event && event.address? event.address: ""}
                      handleReturnToEvent={handleDontCloseEvent}
                      handleLeaveEvent={closeEvent} />
                </div>
            :
            <>
                {!event || event.isResolved?
                <div className="event-details">
                    <div className="event-details__header">
                        <img className="event-details__logo" src={logo} alt="logo"/>
                        <h1 className="event-details__title">פרטי אירוע</h1>
                        <Link to='/'>
                            <HomeIcon className="event-details__home" fontSize="large" />
                        </Link>
                    </div>
                    <h2>אירוע נסגר</h2>
                    <button className="go-back-btn" onClick={() => navigate('/')}
                        >חזרה לדף הבית
                    </button>
                </div>
                : <div className="event-details">
                    <div className="event-details__header">
                        <img className="event-details__logo" src={logo} alt="logo"/>
                        <h1 className="event-details__title">פרטי אירוע</h1>
                        <Link to='/'>
                            <HomeIcon className="event-details__home" fontSize="large" />
                        </Link>
                    </div>

                    {(isLoaded && isMapReady)
                        ? <MapDisplay 
                            center={{ lat: event.latitude, lng: event.longitude }}
                            participants={eventParticipants}/>
                        : <p>Loading...</p>}
                    
                    <div className="btns-wrapper">
                        <button className={screen === 1? "active": ""} onClick={() => setScreen(2)}>שומרים פעילים</button>
                        <button className={screen === 2? "active": ""} onClick={() => setScreen(1)}>פרטי אירוע</button>
                    </div>

                    
                    {screen === 1?
                    <EventDetailsTable event={event}/>
                    :<div className="active-guards-display-wrapper">
                        {eventParticipants.map((p, idx) => 
                            <ActiveGuardCard 
                                key={p.phone + idx} 
                                loggedUser={!!user && user.phone === p.phone}
                                {...p}/>
                        )}
                        <div className="dummy-bottom"></div>
                    </div>}

                    <div className="footer-wrapper">
                        <a 
                        className="open-ways-app-btn" 
                        target="_self"
                            href={`geo:${event?.latitude},${event?.longitude}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                            className="bi bi-sign-turn-slight-right" viewBox="0 0 16 16">
                                <path d="m8.335 6.982.8 1.386a.25.25 0 0 0 .451-.039l1.06-2.882a.25.25 0 0 0-.192-.333l-3.026-.523a.25.25 0 0 0-.26.371l.667 1.154-.621.373A2.5 2.5 0 0 0 6 8.632V11h1V8.632a1.5 1.5 0 0 1 .728-1.286l.607-.364Z"/>
                                <path fillRule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"/>
                            </svg>
                            <div className="btn-text">ניווט לאירוע</div>
                        </a>
                        {!!zelloSession? <div 
                            ref={WKButtonRef}
                            onMouseDown={startRecording} 
                            onMouseUp={stopRecording} 
                            onMouseLeave={stopRecording} 
                            onTouchStart={startRecording}
                            onTouchEnd={stopRecording}
                            onContextMenu={(event) => event.preventDefault()}
                            className="walkie-talkie-btn">
                            <img src="/images/walkie-talkie.png" alt="walkie talkie icon"/>
                        </div>
                        : <div 
                            onClick={createConnection}
                            className="muted-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                                className="bi bi-volume-mute-fill muted-icon" viewBox="0 0 16 16">
                                    <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>}
                        <div onClick={onUserCloseEventBtnClick} className="close-event-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                            className="bi bi-x-octagon" viewBox="0 0 16 16">
                                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                            <div className="btn-text">דחיית אירוע</div>
                        </div>
                    </div>
                </div>}
            </>
            }
        </>
    )
}
export default EventDetails;