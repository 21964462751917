import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ items, selectedItems, setSelectedItems, label, setOtherOptionContent = null, otherItemOption}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [otherInputValue, setOtherInputValue] = useState(otherItemOption || "");

  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    }
  };

  const handleOtherInputChange = (value) => {
    setOtherInputValue(value);
    if (setOtherOptionContent) {  // Check if setOtherOptionContent is provided
      setOtherOptionContent(value);
    }
  };

  const showSelected = () => {
    let lang = '';
    if (selectedItems.length === 0) {
      return label;
    } else {
      for (let i = 0; i < selectedItems.length; i++) {
        for (let j = 0; j < items.length; j++) {
          if (selectedItems[i] === items[j].id) {
            if(items[j].description === "אחר"){
              lang += otherInputValue + ', ';
            }else{ lang += items[j].description + ', ';}
          }
        }
      }
    }
    return lang;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <fieldset className="drop-down-container">
      <div className='dropDowne'>
        <button className='dropDown-button' onClick={() => setDropdownOpen(!dropdownOpen)}>
          {showSelected()}
        </button>
        <div ref={dropdownRef} className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
          {items.map((item) => (
            <div key={item.id}>
              <input
                type='checkbox'
                id={`item-${item.id}`}
                checked={selectedItems.includes(item.id)}
                onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
              />
              <label htmlFor={`item-${item.id}`}>{item.description}</label>
              {item.description === "אחר" && (
                <input
                  type='text'
                  style={{fontSize: "18px"}}
                  value={otherInputValue}
                  onChange={(e) => handleOtherInputChange(e.target.value)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
};

export default Dropdown;