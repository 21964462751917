import feCheck from "../../assets/icons/fe-check.svg";
import { useNavigate } from "react-router-dom";
import localStorageService from "../../utils/localStorageService";
import "../../styles/CreateUser/CUSuccess.css";
import axiosInstance from "../../utils/axiosService";
import { useContext } from "react";
import UserContext from "../../utils/UserContext";

const CUSuccess = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext)

  const clearAndRedirect = () => {
    localStorageService.delete("tempToken");
    navigate("/login");
  }

  return (
    <div className="cu-success">
      <div className="div">
        <div className="rectangle" />
        <div className="group">
          <div className="overlap-group">
            <img className="fe-check" alt="Fe check" src={feCheck} />
          </div>
          <div className="text-wrapper">משתמש נשמר</div>
          <br/>
          {user && !(user.role === "AREAADMIN") && 
          <div className="text-wrapper-2">ממתין לאישור מנהל</div>
          }

          <button
            type="submit"
            className="rectangle-2"
            onClick={clearAndRedirect}
          >
            <p className="p">מעבר למסך הבית</p>
          </button>

          <button
            type="submit"
            className="rectangle-3"
            onClick={() => navigate("/create-assistant")}
          >
            <p className="p">אני רוצה להיות גם שומר</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CUSuccess;
