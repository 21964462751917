import React from "react";
import '../../styles/OpenEmergency/OEStepThree.css';
import Location from "../Location";
import alertheader from '../../icons/emergency-alarm.svg';
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

export default function OEStepThree({ handleCancelEvent, eventUuid, emergencyType, gpsLocation, handleShowEventDetails }) {
  const navigate = useNavigate();

  function getTime() {
    let today = new Date();
    const minutes = today.getMinutes();
    const hours = today.getHours();
    return (hours < 10? "0" + hours: hours) + ':' + (minutes < 10? "0" + minutes:minutes);
  }
  return (
    <>
      {eventUuid?<div className="oe-step-three">
        <div className="oe-step-three__wrapper">
          <Location gpsLocation={gpsLocation} />
          <div className='oe-step-three__pulse' />
          <img src={alertheader} className="oe-step-three__alert-header" alt="alert" />

          <p className="oe-step-three__title">
            {emergencyType === 2 && 'אירוע נשק קר'}
            {emergencyType === 1 && 'אירוע נשק חם'}
            {emergencyType === 3 && 'אירוע אלימות'}
            {emergencyType === 4 && 'אירוע כללי'}
          </p>

          <div className="oe-step-three__subtitle">העזרה בדרך</div>
          <div className="oe-step-three__time">{getTime()}</div>

          <div className="oe-step-three__buttons-wrapper">
            <button
              onClick={handleCancelEvent}
              className="oe-step-three__button">
              ביטול מצב חירום
            </button>
            {<button
              onClick={handleShowEventDetails}
              className="oe-step-three__button oe-step-three__button_details">
              מעבר לדף אירוע
            </button>}
            <button
              className="oe-step-three__button oe-step-three__button_home"
              onClick={() => navigate('/home')}>לעמוד בית</button>
          </div>
        </div>
      </div>
      : <div className="event-loading">
        <Loader className="component" property1="loader-1" />
        <p className="event-loading-text" >
          שולח עזרה...
        </p>
      </div>}
    </>
  );
};
