import "../../styles/CreateUser/CUSuccess.css";
import feCheck from "../../assets/icons/fe-check.svg"
// import Progress from "../../icons/Group3.svg"
import { useNavigate } from "react-router-dom";
import localStorageService from "../../utils/localStorageService";
import { useContext, useEffect } from "react";
import UserContext from "../../utils/UserContext";


const CUSuccess = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext)

  const clearAndRedirect = () => {
    localStorageService.delete("tempToken");
    localStorageService.delete("role");
    localStorageService.delete("uuid");

    if(!user) return navigate("/login");
    else if(user.role === "AREAADMIN") return navigate("/");
    else navigate("/waiting");
  }

  return (
    <div className="cu-success">
      <div className="div">
        <div className="rectangle" />
        <div className="group">
          <div className="overlap-group">
            <img className="fe-check" alt="Fe check" src={feCheck} />
          </div>
          <div className="text-wrapper">כל הכבוד</div>
          <br/>
          <div style={{width:"fit-content",maxWidth:"90vw"}} className="text-wrapper-2">
           {user && user.role === "AREAADMIN"? "נרשמת בהצלחה כשומר": "הפרטים שהזנת נרשמו וממתינים לאישור מנהל"}
          </div>

          <button
            type="submit"
            className="rectangle-2"
            onClick={clearAndRedirect}
          >
            <p className="p">סיום</p>
          </button>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="cu-success">
  //     <div className="div">
  //       <div className="rectangle" />
  //       <img className="progress-one" src={Progress} alt="" />
  //       <div className="text-wrapper">משתמש נוצר</div>
  //       <div className="text-wrapper-2">ממתין לאישור מנהל</div>
  //       <form className="overlap" action='/create-assistant'>
  //         <button type="submit" className="rectangle-2" >
  //         <p className="p">אני רוצה להיות גם סייען</p>
  //         </button>
  //       </form>
  //       <div className="group">
  //         <div className="overlap-group">
  //           <img className="fe-check" alt="Fe check" src={feCheck} />
  //           <div className="ellipse" />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default CUSuccess;