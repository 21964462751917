import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import whitePinMarkerPath from '../../icons/location-white.svg'
import './MapDisplay.css'


const MapDisplay = ({ center, participants }) => {    
    const [selectedCenter, setSelectedCenter] = useState(null);


    const setCustomLocationMarker = () => {
        const iconMarker = {
            url: whitePinMarkerPath,
            scaledSize: {width: 32, height: 32},
            size: new window.google.maps.Size(32,32),
        }
        return iconMarker;
    }
    const setIconMarker = (imgSrc) => {
        const iconMarker = {
            url: imgSrc + '#custom_marker',
            scaledSize: {width: 32, height: 32},
            size: new window.google.maps.Size(40,40),
            origin: new window.google.maps.Point(0,0),
            anchor: new window.google.maps.Point(18, 40)
        }
        return iconMarker;
    }

      
      return (
      <GoogleMap 
        zoom={15} 
        center={center} 
        mapContainerClassName='event-details__map'>
        {participants
        .filter(p => !!p["latitude"] && !!p["longitude"])
        .map((p, idx) => 
                <Marker 
                    icon={setCustomLocationMarker()}
                    key={"location_marker_" + p["phone"] + idx} 
                    title={`${p["firstName"]} ${p["lastName"]}`}
                    position={{lat: p.latitude, lng: p.longitude}} 
                    onClick={() => setSelectedCenter({
                        lat: p.latitude, 
                        lng: p.longitude,
                        description: `${p["firstName"]} ${p["lastName"]}`,
                        img: p["avatar"]
                    })}
                />)
        }
        {participants
        .filter(p => !!p["latitude"] && !!p["longitude"])
        .map((p, idx) => 
                <Marker 
                    icon={{...setIconMarker(p.avatar)}}
                    key={"marker_" + p["phone"] + idx}
                    
                    title={`${p["firstName"]} ${p["lastName"]}`}
                    position={{lat: p.latitude, lng: p.longitude}} 
                    onClick={() => setSelectedCenter({
                        lat: p.latitude, 
                        lng: p.longitude,
                        description: `${p["firstName"]} ${p["lastName"]}`,
                        img: p["avatar"]
                    })}
                />)
        }
        <Marker position={center} 
          onClick={() => setSelectedCenter({
              ...center, 
              description: "מיקום האירוע",
              img: null
          })}
          title="מיקום האירוע"
          key="event_marker"/>

        {selectedCenter && (
            <InfoWindow
                onCloseClick={() => {
                    setSelectedCenter(null);
                }}
                position={{
                    lat: selectedCenter.lat,
                    lng: selectedCenter.lng
                }}
                options={{ 
                    pixelOffset: new window.google.maps.Size(0, -40),
                    textAlign: "center"
                }}
            >
                <div style={{textAlign:"center", paddingLeft:"20px", paddingTop:"5px"}}>
                    <div style={{margin:"auto", padding:"auto"}}>{selectedCenter.description}</div>
                    <div style={{width:"fit-content", margin:"auto"}}>
                        {selectedCenter.img && <img 
                        alt="avatar img"
                        className='info-window-avatar'
                        src={selectedCenter.img}
                        />}
                    </div>
                </div>
            </InfoWindow>
        )}
      </GoogleMap>)
  }

export default MapDisplay