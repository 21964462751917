import Input from "../Input";
import Button from "../Button";
import Dropdown from "../DropDown";
import "../../styles/CreateAssistantLandingPage/CAStepOne.css";
import React, { useEffect, useState } from "react";
import {
  isInputEmpty,
  validateEmail,
  validateNumber,
  isValidIDNumber
} from "../../components/validation/inputValidation";
import Progress from "../../icons/Group1.png";

const languages = [
  {
    id: 1,
    description: "אמהרית",
  },
  {
    id: 2,
    description: "ערבית",
  },
  {
    id: 3,
    description: "אנגלית",
  },
  {
    id: 4,
    description: "צרפתית",
  },
  {
    id: 5,
    description: "רוסית",
  },
  {
    id: 6,
    description: "אחר",
  },
];

const CAStepOne = ({
  email,
  setEmail,
  age,
  setAge,
  onButtonClick,
  homeAddress,
  setHomeAddress,
  idNumber,
  setIdNumber,
  selectedLanguages,
  setSelectedLanguages,
  setOtherLanguage,
  otherLanguage,
  validationText,
}) => {
  //const isButtonDisabled = !email || !age || !homeAddress || !idNumber || !selectedLanguages;

  const [emailError, setEmailError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [homeAddressError, setHomeAddressError] = useState("");
  const [idNumberError, setIdNumberError] = useState("");
  const [selectedLanguagesError, setSelectedLanguagesError] = useState("");


  const validateFields = () => {

    setEmailError("");
    setIdNumberError("");
    setAgeError("");
    setHomeAddressError("");
    
    let isValid = true;
    //-----------email validation-------------------------------
    if (isInputEmpty(email)) {
      setEmailError("שדה זה הינו חובה");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError("אמייל שהוזן לא תקין");
      isValid = false;
    }
    
    //-----------age validation-------------------------------
    if (!age) {
      setAgeError("שדה זה הינו חובה");
      isValid = false;
    }else if (!validateNumber(age)) {
      setAgeError("שדה זה חייב להכיל רק מספרים");
      isValid = false;
    }else if (age < 0 || age > 120) {
      setAgeError("חייב להכיל מספר בין 0 ל 120");
      isValid = false;
    }

    //-----------homeAddress validation-------------------------------

    if (isInputEmpty(homeAddress)) {
      setHomeAddressError("שדה זה הינו חובה");
      isValid = false;
    } 
    //-----------idNumber validation-------------------------------

    if (!idNumber) {
      setIdNumberError("שדה זה הינו חובה");
      isValid = false;
    }else if (!isValidIDNumber(idNumber)) {
      setIdNumberError("מספר תעודת הזהות לא תקין");
      isValid = false;
    }

    if(isValid){
      setEmailError("");
      setAgeError("");
      setHomeAddressError("");
      setIdNumberError("");
      setSelectedLanguagesError("");
    }
    return isValid;
  };
  //==================================================
  const onButtonClick1 = () => {

    const isValid = validateFields();
    if (isValid) {
      onButtonClick();
    }
  };

  useEffect(() => {
    if(!!email && !validateEmail(email)) return setEmailError("אמייל שהוזן לא תקין") 
    setEmailError("")
  }, [email])

  useEffect(() => {
    if (age < 0 || age > 120) return setAgeError("חייב להכיל מספר בין 1 ל 120");
    setAgeError("");
  }, [age])

  useEffect(() => {if(!!homeAddress) setHomeAddressError("")}, [homeAddress])

  useEffect(()=>{
    if (!!idNumber && !isValidIDNumber(idNumber)) return setIdNumberError("מספר תעודת הזהות לא תקין");
    setIdNumberError("");
  },[idNumber])

  return (
    <div className="ca-one">
      <div className="div-2">
        <div className="rectangle" />
        <div className="frame">
          <div className="frame-2">
            <div className="progress-one">
              <img className="" src={Progress} alt="שלב 1" />
              <div className="steps-btn-container">
                <div className="box"></div>
                <div className="box"></div>
                <div className="box"></div>
              </div>
            </div>
            <div className="text-wrapper-2">פרטים אישיים</div>
          </div>
          <div className="frame-wrapper">
            <div className="frame-3">
              <Input
                className="component-22"
                divClassName="component-instance"
                property1="variant-2"
                text="כתובת מייל"
                inputValue={email}
                setInputValue={setEmail}
                inputType='email'
              />
              <div className="error-message">{emailError}</div>


              <Input
                className="component-22"
                divClassName="component-22-instance"
                property1="variant-2"
                text="גיל"
                inputValue={age}
                setInputValue={setAge}
                inputType="number"
              />
              <div className="error-message">{ageError}</div>

              <Input
                className="component-22"
                divClassName="component-3"
                property1="variant-2"
                text="כתובת מגורים"
                inputValue={homeAddress}
                setInputValue={setHomeAddress}
              />
              <div className="error-message">{homeAddressError}</div>

              <Input
                className="component-22"
                divClassName="component-4"
                property1="variant-2"
                text="מספר זהות"
                inputValue={idNumber}
                setInputValue={setIdNumber}
                inputType="number"
              />
              <div className="error-message">{idNumberError}</div>

              <Dropdown
                items={languages}
                selectedItems={selectedLanguages}
                setSelectedItems={setSelectedLanguages}
                label={"שפות"}
                otherItemOption={otherLanguage}
                setOtherOptionContent={setOtherLanguage}
              />
              {/* <div className="error-message">{selectedLanguagesError}</div> */}
            </div>
          </div>
          <Button
            textButton={"שלב הבא"}
            className={"button-instance text-element-class"}
            onClick={onButtonClick1}
          //disabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default CAStepOne;
