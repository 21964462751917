import React from "react";
import '../styles/CancelEvent.css';
import alertheader from '../icons/emergency-alarm-grey.svg';
import Location from "./Location";

function ButtonActive({ className, text, onClick }) {
  return (
    <button onClick={onClick} className={`cancel-event__button-active ${className}`}>
      {text}
    </button>
  )
}

export default function CancelEvent({ handleReturnToEvent, handleLeaveEvent, gpsLocation }) {
  return (
    <div className="cancel-event">
      <div className="cancel-event__wrapper">
        <ButtonActive
          className="cancel-event__button-active-1"
          text="כן, בטל מצב חירום"
          onClick={handleLeaveEvent}
        />
        <ButtonActive
          className="cancel-event__button-active-2"
          text="השאר מצב חירום"
          onClick={handleReturnToEvent} />
        <Location gpsLocation={gpsLocation} />

        <p className="cancel-event__text">האם הנך בטוח/ה ברצונך לבטל את האירוע?</p>
        <div className='cancel-event__alert-wrapper' />
        <img src={alertheader} className="cancel-event__alert-header" alt="alert" />
      </div>
    </div>
  );
};
