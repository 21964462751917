import location from '../assets/location.svg';
import '../styles/Location.css';

export default function Location({ gpsLocation }) {
  return (
    <div className="location">
      <div className="location__address">{gpsLocation}</div>
      <img className="location__icon" alt="Octicon location" src={location} />
    </div>
  )
}