import { useState, useRef } from "react";
import Button from "../Button";
import image from "../../assets/rafiki.png";
import "../../styles/PhoneVerification/VerifyPhone.css";

const PhoneVerification = ({ handleResendCode, handleRegistration, loading, verificationCodeError }) => {
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");
  const [forgetPasswordTitle, setForgetPassowrdTitle] = useState("הקוד לא הגיע? כאן מקבלים חדש")

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleInputFocus = (value, nextInput, currentInput) => {
    if (value.length === 1 && nextInput) {
      nextInput.current.focus();
    } else if (value.length === 0 && currentInput) {
      currentInput.current.focus();
    }
  };

  const handleClick = () => {
    if (!code1) return inputRefs[0].current.focus();
    if (!code2) return inputRefs[1].current.focus();
    if (!code3) return inputRefs[2].current.focus();
    if (!code4) return inputRefs[3].current.focus();
    if (!code5) return inputRefs[4].current.focus();
    if (!code6) return inputRefs[5].current.focus();
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      const currentInputs = inputRefs.map((ref) => ref.current);
      const currInputIndex = currentInputs.indexOf(e.target);
      if (currInputIndex > 0 && e.target.value === "") {
        // Call the corresponding state setter function to clear the value
        const stateSetters = [setCode1, setCode2, setCode3, setCode4, setCode5, setCode6];
        stateSetters[currInputIndex]('');
        // Move focus to the previous input
        inputRefs[currInputIndex - 1].current.focus();
      }
    }
  };
  
  const handleSubmit = async () => {
    const fullCode = code1 + code2 + code3 + code4 + code5 + code6;

    try {
      await handleRegistration(fullCode);
    } catch (error) {
      console.log(error);
    }
  };

  const sendCodeAgain = async () => {
    try {
      setForgetPassowrdTitle("שולח קוד מחדש")
      console.log("resending...");
      await handleResendCode();
    } catch (error) {
      console.log(error)
    } finally {
      setForgetPassowrdTitle("הקוד לא הגיע? כאן מקבלים חדש")
    }
  };

  return (
    <div className="verifcation-page">
      <div className="rectangle" />
      <div className="div">
        <img className="fire-prevention" alt="Fire prevention" src={image} />
        <div className="text-wrapper-3">כאן מזינים את הקוד</div>

        <div className="code">
          <div className="overlap-group">
            <div className="input-wrapper" onClick={handleClick}>
              <input
                ref={inputRefs[0]}
                maxLength="1"
                value={code1}
                onChange={(e) => {
                  setCode1(e.target.value);
                  handleInputFocus(e.target.value, inputRefs[1], inputRefs[0]);
                }}
                type="tel"
                onKeyUp={handleKeyUp}
              />

              <input
                ref={inputRefs[1]}
                maxLength="1"
                value={code2}
                onChange={(e) => {
                  setCode2(e.target.value);
                  handleInputFocus(e.target.value, inputRefs[2], inputRefs[1]);
                }}
                type="tel"
                onKeyUp={handleKeyUp}
              />

              <input
                ref={inputRefs[2]}
                maxLength="1"
                value={code3}
                onChange={(e) => {
                  setCode3(e.target.value);
                  handleInputFocus(e.target.value, inputRefs[3], inputRefs[2]);
                }}
                type="tel"
                onKeyUp={handleKeyUp}
              />

              <input
                ref={inputRefs[3]}
                maxLength="1"
                value={code4}
                onChange={(e) => {
                  setCode4(e.target.value);
                  handleInputFocus(e.target.value, inputRefs[4], inputRefs[3]);
                }}
                type="tel"
                onKeyUp={handleKeyUp}
              />

              <input
                ref={inputRefs[4]}
                maxLength="1"
                value={code5}
                onChange={(e) => {
                  setCode5(e.target.value);
                  handleInputFocus(e.target.value, inputRefs[5], inputRefs[4]);
                }}
                type="tel"
                onKeyUp={handleKeyUp}
              />

              <input
                ref={inputRefs[5]}
                maxLength="1"
                value={code6}
                onChange={(e) => {
                  setCode6(e.target.value);
                  handleInputFocus(e.target.value, null, inputRefs[5]);
                }}
                type="tel"
                onKeyUp={handleKeyUp}
              />
            </div>
          </div>
        </div>
          <p style={{color:"red"}} className="code-p">{verificationCodeError}</p>
        <div className="frame-22">
          <br/>
          <p className="code-p" onClick={sendCodeAgain}>{forgetPasswordTitle}</p>
        </div>
        <Button textButton={loading? "...שולח" : "שלח קוד"} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default PhoneVerification;
