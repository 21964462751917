import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PhoneVerification from "./pages/PhoneVerification";
import Login from "./pages/Login";
import CreateUser from "./pages/CreateUser";
import CreateAssistant from "./pages/CreateAssistant";
import InviteAssistant from "./pages/InviteAssistant";
import Home from "./pages/Home";
import AdminPanelContainer from "./components/AdminPanel/AdminPanelContainer";
import ReadEditUser from "./components/CreateUser/ReadEditUser";
import "./App.css";
import AdminPhoneVerification from "./pages/AdminPhoneVerification";
import CreateAdmin from "./pages/CreateAdmin";
import PrivateRoute from "./utils/PrivateRoute";
import React, { useEffect, useState } from "react";
import UserProvider from "./utils/UserProvider";
import WaitingForApprove from "./pages/WaitingForApprove";
import EventDetails from "./pages/EventDetails";
import SlideUpModalWrapper from "./components/AdminPanel/AdminPanelComponents/DetailsModal/SlideUpModalWrapper";
import ForgotPassword from "./pages/ForgotPassword";
import InitialLogoScreen from "./pages/InitialLogoScreen";
import AdminLogin from "./pages/AdminLogin";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const isProduction = process.env.NODE_ENV === 'production';

function App() {

  const [isInitialLoding, setIsInitialLoading] = useState(true);
  
  useEffect(() => { setTimeout(() => {setIsInitialLoading(false); }, 2500) }, [])


  return (<>
    {isProduction && isInitialLoding? <InitialLogoScreen />: null}
    <UserProvider>
      <Router>
        <>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            {/* <Route path="/verify-phone" element={<PhoneVerification />} /> */}
            <Route
              path="/verify-phone/:uuid?"
              element={<PhoneVerification />}
            />
            <Route
              path="/forgot-password"
              element={<ForgotPassword />}
            />
            <Route path="/waiting" element={<WaitingForApprove />} />

            <Route path="/create-user/:uuid?" element={<CreateUser />} />
            <Route path="/create-assistant" element={<CreateAssistant />} />
            <Route
              path="/admin-registration"
              element={<AdminPhoneVerification />}
            />
            <Route path="/create-admin" element={<CreateAdmin />} />

            <Route
              path="/read-edit-user/:uuid"
              element={
                <PrivateRoute>
                  <ReadEditUser
                    readOnlyMode={true}
                  />
                </PrivateRoute>
              }
            />
            {/* <Route path="/inviteAdmin" element={<InviteAreaAdmin />} /> */}
            <Route path="/invite-assistant" element={<InviteAssistant />} />
            <Route
              path="/admin-panel/"
              element={
                <PrivateRoute>
                  <AdminPanelContainer />
                </PrivateRoute>
              }
            />
            <Route path='/admin-panel/edit-user/:uuid' element={
              <PrivateRoute>
                <SlideUpModalWrapper />
              </PrivateRoute>
            } />
            <Route path='/event/:uuid' element={<EventDetails />} />
            {/* <Route path="/animation" element={<OpeningAnimation />} /> */}
            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        </>
      </Router>
    </UserProvider>
  </>);
}

export default App;