import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosService";
import Button from "../Button";
import "../../styles/CreateAssistantLandingPage/CAStepThird.css";
import Select from "../Select";
import Progress from "../../icons/Group3.svg";

// todo: fix the radio
const CAStepThird = ({
  setStep,
  onButtonClick,
  selectedRanks,
  setSelectedRanks,
  selectedTraining,
  setSelectedTraining,
  submissionIsLoading,
  shiftAvailability,
  setShiftAvailability,
  selectedMiluim,
  setSelectedMiluim,
  selectedWeapon,
  setSelectedWeapon
}) => {
  const [rankData, setRankData] = useState([]);
  const [trainingData, setTrainingData] = useState([]);
  const [miluimData, setMiluimData] = useState([]);
  const [weaponData, setWeaponData] = useState([]);
  //rank
  useEffect(() => {
    const fetchMilitaryRank = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/militaryRank');
        const MRD = optionRanks(response.data)
        setRankData(MRD)
      } catch (error) {}
    };
    const optionRanks = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMilitaryRank();
  }, []);
  //training
  useEffect(() => {
    const fetchMilitaryTraining = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/militaryTraining');
        const MTD = optiontTraining(response.data)
        setTrainingData(MTD)
      } catch (error) {}
    };
    const optiontTraining = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMilitaryTraining();
  }, []);

  useEffect(() => {
    const fetchMiluimStatus = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/miluimStatus');
        const MSD = optionMiluimStatus(response.data)
        setMiluimData(MSD)
      } 
      catch (error) { }
    };
    const optionMiluimStatus = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMiluimStatus();
  }, []);
  //weapon
  useEffect(() => {
    const fetchWeaponStatus = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/weaponStatus');
        const WSD = optionWeaponStatus(response.data)
        setWeaponData(WSD)
      } catch (error) { }
    };
    const optionWeaponStatus = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchWeaponStatus();
  }, []);

  return (
    <div className="ca-third">
      <div className="div-2">
        <div className="rectangle-4" />
        <div className="progress-one">
          <img src={Progress} alt="שלב 2" />
          <div className="steps-btn-container">
            <div className="box"></div>
            <div className="box" onClick={()=> setStep(2)}></div>
            <div className="box" onClick={()=> setStep(1)}></div>
          </div>
        </div>
        <div className="frame-5">
          <div className="frame-6">
            <div className="text-wrapper-7">רקע צבאי וזמינות</div>
          </div>
          <div style={{width:"100%"}} className="frame-wrapper">
            <div style={{width:"100%"}}  className="frame-7">
              <Select
                label="דרגה צבאית"
                options={rankData}
                selectedOption={selectedRanks}
                setSelectedOption={setSelectedRanks} // This should be a function to update the state
              />
              <Select
                label="שירות צבאי"
                options={trainingData}
                selectedOption={selectedTraining}
                setSelectedOption={setSelectedTraining} // This should be a function to update the state
              />
              <Select
                label="שירות מילואים"
                options={miluimData}
                selectedOption={selectedMiluim}
                setSelectedOption={setSelectedMiluim} // This should be a function to update the state
              />
              <Select
                label="נשק"
                options={weaponData}
                selectedOption={selectedWeapon}
                setSelectedOption={setSelectedWeapon} // This should be a function to update the state
              />
              <div className="shiftAvailability-wrapper">
                <div>זמינות למשמרות</div>
                <div className="shiftAvailability-inputs-wrapper">
                  <div className="shiftAvailability-input">
                    <label>זמין</label>
                    <input type="checkbox"
                      checked={shiftAvailability}
                      onChange={() => setShiftAvailability(!shiftAvailability)}
                    />
                  </div>
                  <div className="shiftAvailability-input unavailable">
                    <label>לא זמין</label>
                    <input type="checkbox"
                      checked={!shiftAvailability}
                      onChange={() => setShiftAvailability(!shiftAvailability)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <Button
            textButton={submissionIsLoading? "אנא המתן...": "שלב הבא"}
            className={"button-instance text-element-class"}
            onClick={onButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default CAStepThird;
