import CAStepOne from "../components/CreateAssistantLandingPage/CAStepOne";
import CAStepTwo from "../components/CreateAssistantLandingPage/CAStepTwo";
import CAStepThird from "../components/CreateAssistantLandingPage/CAStepThird";
import CASuccess from "../components/CreateAssistantLandingPage/CASuccess";
import React, { useContext, useState } from "react";
import axiosInstance from "../utils/axiosService";
import { filterAndSwitchLanguagesFromServer, filterAndSwitchManagementAbilitiesFromServer, filterAndSwitchTechAbilitiesFromServer } from "../helpers/prefill-assistant-form-helper";
import UserContext from "../utils/UserContext";


function CreateAssistant() {
  const [step, setStep] = useState(1);
  const {setUser} = useContext(UserContext);
  //step one
  //personal info
  //phone replace with email
  const [email, setEmail] = useState("")
  const [homeAddress, setHomeAddress] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [age, setAge] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [otherLanguage, setOtherLanguage] = useState('');

  //step two
  const [selectedCar, setSelectedCar] = useState('');
  const [selectedMedic, setSelectedMedic] = useState('');
  const [selectedFitness, setSelectedFitness] = useState('');
  const [selectedTechAbilities, setSelectedTechAbilities] = useState([]);
  const [selectedManagmentAbilities, setSelectedManagmentAbilities] = useState([]);
  const [otherAbilities, setOtherAbilities] = useState('');

  //step three
  const [submissionIsLoading, setSubmissionIsLoading] = useState(false);

  const [selectedRanks, setSelectedRanks] = useState("");
  const [selectedTraining, setSelectedTraining] = useState("");
  const [selectedUnits, setSelectedUnits] = useState("");
  const [selectedMiluim, setSelectedMiluim] = useState("");
  const [selectedWeapon, setSelectedWeapon] = useState("");
  const [shiftAvailability, setShiftAvailability] = useState(false);
  const [validationText, setValidationText] = useState({
    email: '',
    password: '',
    // Add more keys for other input fields as needed
  });
  
  const handleButtonClick = () => {
    // step 1:
    console.log(`email:`, email)
    console.log(`homeAddress:`, homeAddress)
    console.log(`idNumber:`, idNumber)

    setStep((prevStep) => prevStep + 1);
  };

  const submitForm = async () => {
    setSubmissionIsLoading(true);
    try {
      let data = {
        "abilityToLead": selectedManagmentAbilities.includes("abilityToLead"),
        "age": age,
        "amharicSpeaker": selectedLanguages.includes(1),
        "arabicSpeaker": selectedLanguages.includes(2),
        "contactWithSecurityForces": selectedTechAbilities.includes("contactWithSecurityForces"),
        "email": email,
        "englishSpeaker": selectedLanguages.includes(3),
        "fitnessLevelId": !!selectedFitness ? selectedFitness : 0,
        "frenchSpeaker": selectedLanguages.includes(4),
        "hasDrone": selectedTechAbilities.includes("drone"),
        "homeAddress": homeAddress,
        "idNumber": idNumber,
        "intelligenceGatheringAbilities": selectedTechAbilities.includes("intelgence"),
        "martialArts": false,
        "medicalAbilitiesId": !!selectedMedic ? selectedMedic : 0,
        "militaryRankId": selectedRanks ? selectedRanks : 0,
        "militaryTrainingId": selectedTraining ? selectedTraining : 0,
        "militaryUnitId": selectedUnits ? selectedUnits : 0,
        "miluimStatusId": selectedMiluim ? selectedMiluim : 0,
        "otherAbilities": selectedTechAbilities.includes("intelgence") ? otherAbilities : '',
        "otherSpeakingLanguages": selectedLanguages.includes(6) ? otherLanguage : '',
        "russianSpeaker": selectedLanguages.includes(5),
        "techAbilities": selectedTechAbilities.includes("techHigh"),
        "vehicleOwnershipId": !!selectedCar ? selectedCar : 0,
        "volunteersManagementAbilities": selectedManagmentAbilities.includes("noAlotOfKnoldge"),
        "warRoomLeadAbilities": selectedTechAbilities.includes("complecited"),
        "weaponStatusId": selectedWeapon ? selectedWeapon : 0,
        "shiftAvailability": shiftAvailability
      };

      console.log(`data:`, data)

      axiosInstance.enableAuthHeader()
      const response = await axiosInstance.put("/api/user/guardian", data);
      setUser(response.data);
      setStep((prevStep) => prevStep + 1);
      console.log(response, "res")

    } catch (error) {
      console.log(error)
    }
    finally{setSubmissionIsLoading(false)}
  };

  const prefillForm = async () => {
    try{
      axiosInstance.enableAuthHeader()
      const serverReqUrl = "/api/user/prefilled_details";
      const data = await axiosInstance.get(serverReqUrl)
      .then(data => data.data);

      if(data){
        // first step fill
        const fillStep1 = (data) => {
          setEmail(data.email || "")
          setHomeAddress(data.homeAddress || "")
          setIdNumber(data.idNumber || "")
          setAge(data.age || "")
          setSelectedLanguages(filterAndSwitchLanguagesFromServer(data))
          setOtherLanguage(!!data["otherSpeakingLanguages"]? data["otherSpeakingLanguages"]: "");
        }
        fillStep1(data);
  
        // 2nd step fill

        setSelectedTechAbilities(filterAndSwitchTechAbilitiesFromServer(data))
        setSelectedManagmentAbilities(filterAndSwitchManagementAbilitiesFromServer(data))
        setSelectedCar(!!data["vehicleOwnershipId"]? data["vehicleOwnershipId"]:0)
        setSelectedMedic(!!data["medicalAbilitiesId"]? data["medicalAbilitiesId"]:0)
        setSelectedFitness(!!data["fitnessLevelId"]? data["fitnessLevelId"]:0)
  
        // // 3rd step fill
        console.log("training", data["militaryTrainingId"]);
        setSelectedRanks(!!data["militaryRankId"]? data["militaryRankId"]:0)
        setSelectedTraining(!!data["militaryTrainingId"]? data["militaryTrainingId"]:0)
        setSelectedUnits(!!data["militaryUnitId"]? data["militaryUnitId"]:0)
        setSelectedMiluim(!!data["miluimStatusId"]? data["miluimStatusId"]:0)
        setSelectedWeapon(!!data["weaponStatusId"]? data["weaponStatusId"]:0)
        setShiftAvailability(!!data["shiftAvailability"])
      }
    }
    catch(err){console.log(err)}
  }

  React.useEffect(() => {prefillForm()},[])

  return (
    <>
      {step === 1 && <CAStepOne
        onButtonClick={handleButtonClick}
        homeAddress={homeAddress}
        setHomeAddress={setHomeAddress}
        idNumber={idNumber}
        setIdNumber={setIdNumber}
        email={email}
        setEmail={setEmail}
        age={age}
        setAge={setAge}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
        otherLanguage={otherLanguage}
        setOtherLanguage={setOtherLanguage}  // Pass setOtherLanguage to CAStepOne
        validationText={validationText}
      />}
      {step === 2 && <CAStepTwo
        setStep={setStep}
        onButtonClick={handleButtonClick}
        selectedCar={selectedCar}
        setSelectedCar={setSelectedCar}
        selectedMedic={selectedMedic}
        setSelectedMedic={setSelectedMedic}
        selectedFitness={selectedFitness}
        setSelectedFitness={setSelectedFitness}
        selectedTechAbilities={selectedTechAbilities}
        setSelectedTechAbilities={setSelectedTechAbilities}
        selectedManagmentAbilities={selectedManagmentAbilities}
        setSelectedManagmentAbilities={setSelectedManagmentAbilities}
        setOtherAbilities={setOtherAbilities}
      />}
      {step === 3 && <CAStepThird
        setStep={setStep}
        onButtonClick={submitForm}
        selectedRanks={selectedRanks}
        setSelectedRanks={setSelectedRanks}
        selectedTraining={selectedTraining}
        setSelectedTraining={setSelectedTraining}
        selectedUnits={selectedUnits}
        setSelectedUnits={setSelectedUnits}
        selectedMiluim={selectedMiluim}
        setSelectedMiluim={setSelectedMiluim}
        selectedWeapon={selectedWeapon}
        setSelectedWeapon={setSelectedWeapon}
        shiftAvailability={shiftAvailability}
        setShiftAvailability={setShiftAvailability}
        submissionIsLoading={submissionIsLoading}
      />}
      {step === 4 && <CASuccess />}
    </>
  );
}

export default CreateAssistant;
