import { useEffect, useRef, useState } from "react"
import '../../styles/CreateUser/TakeIDPhotoModal.css'
import Button from "../Button";


const TakeIDPhotoModal = ({
    setIdFile,
    idFile,
    closeModal
    }) => {

    const PHOTO_TYPES = {
        CANVAS: "CANVAS",
        IMAGE:"IMAGE"
    }
    const [photoType, setPhotoType] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const hiddenFileInput = useRef(null);
    // photo
    const idVideoRef = useRef(null);
    const idPhotoRef = useRef(null);
    const idStreamRef = useRef(null);
    const [takeIdphoto, setTakeIdphoto] = useState(false);

    const handleHiddenInputBtnClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleImageFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
            setIdFile(file);
            convetFileToImgSrc(file)
        }
      };

    const convetFileToImgSrc = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageSrc(reader.result);
        };
        reader.readAsDataURL(file);
        
        setPhotoType(PHOTO_TYPES.IMAGE);
    }

    // handle photo: video and canvas

    const getIdVideo = () => {
        setTakeIdphoto(true);
        setPhotoType(PHOTO_TYPES.CANVAS);

        const videoConstraints = {
          facingMode: "environment", // Use "user" for front camera, "environment" for back camera
          width: { ideal: 640 },
          height: { ideal: 480 },
        };
        
        navigator.mediaDevices
          .getUserMedia({ video: videoConstraints })
          .then((stream) => {
            let video = idVideoRef.current;
            video.srcObject = stream;
            video.play();
            // video.style.transform = "scaleX(-1)"; 
    
            const aspectRatio = video.videoWidth / video.videoHeight;
            const width = 200;
            const height = width / aspectRatio;
            video.width = width;
            video.height = height;
            
            idStreamRef.current = stream;
          })
          .catch((err) => {
            // Handle errors
          });
      };
    
      const takeIdPhoto = () => {

        setPhotoType(PHOTO_TYPES.CANVAS)
    
        let video = idVideoRef.current;
        let photo = idPhotoRef.current;
    
        photo.width = video.videoWidth;
        photo.height = video.videoHeight;
    

        let ctx = photo.getContext("2d");
    
        ctx.drawImage(video, 0, 0, photo.width, photo.height);
      

        let dataURL = photo.toDataURL("image/jpeg");
        dataURLToBlob(dataURL).then((blob) => {
          let imageFile = new File([blob], "photo.jpg", { type: "image/jpeg" });
          setIdFile(imageFile);
          convetFileToImgSrc(imageFile);
        });
        
        setTakeIdphoto(false);
    
        if (idStreamRef.current) {
          idStreamRef.current.getTracks().forEach((track) => track.stop());
        }
      };
    
      const dataURLToBlob = async (dataURL) => {
        return fetch(dataURL).then((response) => response.blob());
      };
    
      const renderIdPopup = () => {

        const handleCancelVideoBtnClick = () => {
          setTakeIdphoto(false);
          setPhotoType(PHOTO_TYPES.IMAGE);
    
          if (idStreamRef.current) {
            idStreamRef.current.getTracks().forEach((track) => track.stop());
          }
        }

        if (takeIdphoto) {
          return (
            <div className="popup-wrapper">
              <div className="popup">
                <video ref={idVideoRef}></video>
                <div className="button-container">
                  <Button
                    className="button-save text-element-class"
                    textButton="שמור"
                    onClick={takeIdPhoto}
                  />
                  <Button
                    className="button-cancel text-element-class"
                    textButton="ביטול"
                    onClick={handleCancelVideoBtnClick}
                  />
                </div>
              </div>
            </div>
          );
        }
        return null;
      };

    useEffect(() => {if(idFile) convetFileToImgSrc(idFile) }, []);

    return (
        <div className="id-photo-modal-container">
            <div className="rectangle" />

            <h2 className="title">איך תרצה להוסיף תעודה מזהה?</h2>

            <div className="id-photo-btns-wrapper">
                <Button
                    className="button-active-instance text-element-class"
                    textButton="צילום"
                    onClick={getIdVideo}
                />
                <h3>או</h3>
                <Button
                    className="button-active-instance text-element-class"
                    textButton="העלאת תמונה"
                    onClick={handleHiddenInputBtnClick}
                />
            </div>

            <input
                ref={hiddenFileInput}
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }} // NOTICE!
                onChange={handleImageFileChange}
            />
            {renderIdPopup()}

            <div className="id-photo-display-wrapper">
                {
                    photoType === PHOTO_TYPES.CANVAS && 
                    <div className="takeApick__container">
                        <canvas ref={idPhotoRef}></canvas>
                    </div>
                }
                
                {
                    photoType === "IMAGE" && !!imageSrc &&
                    <img 
                    src={imageSrc} 
                    alt="Uploaded" 
                    style={{ maxWidth: '100%' }} />
                }
            </div>

            {
                !!idFile && <div className="continue-btn-wrapper">
                    <Button
                        className="button green text-element-class"
                        textButton="אישור והמשך"
                        onClick={closeModal}
                    />
                </div>
            }
        </div>
  )
}

export default TakeIDPhotoModal