import { ButtonApproveChanges } from '../../AdminPanel/AdminPanelComponents/ButtonApproveChanges';
import { useEffect, useState } from 'react'
import Dropdown from '../../DropDown';
import axiosInstance from '../../../utils/axiosService';
import Select from "../../Select";

const EditTrainingDetails = ({user, submitUserUpdate}) => {

  const [userToEdit, setUserToEdit] = useState({...user});
  const [selectedLeadAbilities, setSelectedLeadAbilities] = useState([]);
  const [selectedTechAbilities, setSelectedTechAbilities] = useState([]);
  const [carsData, setCarsData] = useState([]);
  const [medicData, setMedicData] = useState([]);
  const [fitnessData, setFitnessData] = useState([]);
  const [selectedcar, setSelectedcar] = useState(0);
  const [selectedmedic, setSelectedmedic] = useState(user["medicalAbilities"]);
  const [selectedfitness, setSelectedfitness] = useState(user["fitnessLevel"]);

  const title = "הכשרות";

  const dropdowns = [
    { 
      items: [
        {description: "יכולת טכנולוגית גבוהה", id: "techAbilities"},
        {description: "ניסיון מודיעיני", id: "intelligenceGatheringAbilities"},
        {description: "ניסיון בחמ\"ל", id: "warRoomLeadAbilities"},
        {description: "קשר עם גורמי ביטחון", id: "contactWithSecurityForces"},
        {description: "בעל רחפן", id: "hasDrone"},
      ], 
      selectedItems: selectedTechAbilities, 
      setSelectedItems: setSelectedTechAbilities,
      label: "אמצעים טכנולוגיים"
    },
    {
        items: [
          {description: "יכולת לפקד על צוות", id: "abilityToLead"},
          {description: "ניסיון בניהול מתנדבים", id: "volunteersManagementAbilities"},
        ], 
      selectedItems: selectedLeadAbilities, 
      setSelectedItems: setSelectedLeadAbilities,
      label: "יכולות פיקוד וניהול"
    },
  ]
  const selectInputs = [
    { 
      items: carsData, 
      selectedItems: selectedcar, 
      setSelectedItems: setSelectedcar,
      label: "האם קיים ברשותך רכב שטח?",
      serverkey: "vehicleOwnershipId"
      // serverkey: "vehicleOwnership"
    },
    { 
      items: medicData, 
      selectedItems: selectedmedic, 
      setSelectedItems: setSelectedmedic,
      label: "האם קיימת הכשרה רפואית?",
      serverkey: "medicalAbilitiesId"
      // serverkey: "medicalAbilities"
    },
    { 
      items: fitnessData, 
      selectedItems: selectedfitness, 
      setSelectedItems: setSelectedfitness,
      label: "דרגת כושר גופני",
      serverkey: "fitnessLevelId"
      // serverkey: "fitnessLevel"
    }
  ]

  const setInitialSelects = () => {
    selectInputs.forEach(s => s["setSelectedItems"](user[s["serverkey"]]) )
  }

  const setInitDropdownSelections = () => {
    dropdowns.forEach(dd => {
      const selected = dd.items.map(item => user[item.id]? item.id: "")
      .filter(id => !!id)
      dd["setSelectedItems"](selected);
    })
  } 

  const updateDropdownSelections = () => {
    const updatedUser = {...userToEdit};
    dropdowns.forEach(dd => {
      dd.items.forEach(item => {
        updatedUser[item.id] = dd.selectedItems.includes(item.id);
      })
    })

    setUserToEdit(updatedUser);
  }

  const onSubmit = () => { 
    const updatedUser = {...userToEdit};
    selectInputs.forEach(inp => { 
        updatedUser[inp.serverkey] = inp.selectedItems
    })
    submitUserUpdate(updatedUser) 
  };

  useEffect(() => {
    const fetchVehicleOwnership = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/vehicleOwnership');
        const CD = optionCars(response.data)
        setCarsData(CD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };

    const optionCars = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchVehicleOwnership();
  }, []);
  //medic
  useEffect(() => {
    const fetchMedicalAbilities = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/medicalAbilities');
        const MD = optionMedic(response.data)
        setMedicData(MD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optionMedic = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMedicalAbilities();
  }, []);
  //fitness
  useEffect(() => {
    const fetchFitnessLevel = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/fitnessLevel');
        const FD = optionFitness(response.data)
        setFitnessData(FD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optionFitness = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchFitnessLevel();
  }, []);

  useEffect(() => {setInitialSelects()}, [])
  useEffect(() => {setInitDropdownSelections()}, [user])
  useEffect(() => {updateDropdownSelections()}, [selectedLeadAbilities, selectedTechAbilities]);

  return (
    <section className="read-edit-container">
    <div className="overlap">
    <h2  style={{color:"white", textAlign: "end",width:"100%"}}>{title}</h2>

    </div>
    <div  className='read-edit-form-wrapper' style={{textAlign: "right"}}>
        {dropdowns.map((dd) => 
        <div key={dd.label} style={{ margin:"20px auto", padding: "auto", minWidth:"90vw", maxWidth: "90vw"}}>
          <Dropdown
            items={dd.items}
            selectedItems={dd.selectedItems}
            setSelectedItems={dd.setSelectedItems}
            label={dd.label}
          />
        </div>)}
        <div style={{margin:"20px auto", width:"70%"}}>
          {selectInputs.map((input) => <Select
            key={input.label}
            label={input.label}
            options={input.items}
            selectedOption={input.selectedItems}
            setSelectedOption={input.setSelectedItems}
          />)}
        </div>
    <ButtonApproveChanges style={{maxWidth:"80%"}} onClick={onSubmit}/>
    </div>
  </section>
  )
}

export default EditTrainingDetails