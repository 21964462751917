import  {createContext} from 'react';

const UserContext = createContext({
    user: null,
    setUser: () => {
      throw new Error("setUser function not initialized");
    },
    loading: true,
  });

export default UserContext;
