import { useState, useContext, useRef, useEffect } from "react";
import Input from "../components/Input";
import magen from "../assets/logo-magen.png";
import Button from "../components/Button";
import axiosInstance from "../utils/axiosService";
import localStorageService from "../utils/localStorageService";
import { useNavigate } from "react-router-dom";
import UserContext from "../utils/UserContext";
import "../styles/pages/Login.css";
import { isInputEmpty } from "../components/validation/inputValidation";

const AdminLogin = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState("");
  const formRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorageService.get("token");
    console.log(token)
    if (token) {
      navigate("/"); 
    }
  }, [navigate]);

  const isButtonDisabled = !userName || !password;

  const handleValidationAndSubmit = (e) => {
    e.preventDefault();

    if (isInputEmpty(userName)) {
      setError("שם משתמש הוא שדה חובה");
      return;
    }

    if (isInputEmpty(password)) {
      setError("סיסמה היא שדה חובה");
      return;
    }

    setError("");
    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();
    setLoading(true);
    const data = {
      username: userName,
      password: password,
    };
    axiosInstance.disableAuthHeader();

    
    try {
      const response = await axiosInstance.post("/api/authenticate", data);
      localStorageService.store(response.data);
      axiosInstance.enableAuthHeader();
      const userDetails = await axiosInstance.get("/api/user/details");
      setUser(userDetails.data);
      const userNotApproved = userDetails.data.approved === false;
      if (userNotApproved) {
        navigate("/waiting");
        return;
      }

      navigate("/");
    } catch (error) {
      setError("שם משתמש או סיסמה שגויים");
    }
    finally{ setLoading(false) }
  };

  return (
    <div className="login-page">
      <div className="div-2">
        <div className="overlap">
          <img className="magen-ezrahi" alt="Magen ezrahi" src={magen} />
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-2">
            <form className="frame" onSubmit={handleValidationAndSubmit} ref={formRef}>
              <div className="form-input-area">
                <Input
                  className="component-22"
                  divClassName="component-22-instance"
                  overlapGroupClassName="component-instance"
                  property1="variant-2"
                  text="שם משתמש"
                  inputValue={userName}
                  setInputValue={setUserName}
                  inputType="text"
                />
                <Input
                  className="component-22"
                  divClassName="design-component-instance-node"
                  overlapGroupClassName="component-instance"
                  property1="variant-2"
                  text="סיסמה"
                  inputValue={password}
                  setInputValue={setPassword}
                  inputType="password"
                />
                <div className="error-message" style={{ color: 'red' }}>{error}</div>
              </div>
              <div className="frame-2">
                <Button
                  textButton={!loading? "התחברות" : "אנא המתן..."}
                  className={"button-instance last-text-element-class"}
                  type="submit"
                  disabled={isButtonDisabled}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="rectangle-2" />
      </div>
    </div>
  );
};

export default AdminLogin;