import React, { useContext, useEffect, useState } from "react";
import "../../../styles/AdminPanel/ButtonAreaManager.css";
import axiosInstance from "../../../utils/axiosService";
import UserContext from "../../../utils/UserContext";

// const getAvailableRoles = (role) => {
//   switch (role) {
//     case "AREAADMIN":
//       return ["USER", "APPROVER"];
//     case "APPROVER":
//       return ["USER", "AREAADMIN"];
//     case "USER":
//       return ["APPROVER", "AREAADMIN"];
//     default:
//       return [];
//   }
// };

export const ButtonAreaManager = ({ user, setUser }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPopupOption, setSelectedPopupOption] = useState("");
  const loggedUser = useContext(UserContext)

  const isApprover = user.canApprove ? true : false;
  const userRole = user.role === "USER" && isApprover ? "APPROVER" : user.role;
  const availableRoles = loggedUser.user.role === "USER"? ["USER", "APPROVER"] :["USER", "APPROVER", "AREAADMIN"];

  // const roles = availableRoles.filter((role) => role !== userRole);
  const roles = availableRoles;

  useEffect(() => {
    setSelectedPopupOption(userRole);
  }, [userRole]);

  const getRoleDisplayName = (role) => {
    switch (role) {
      case "AREAADMIN":
        return "מנהל איזור";
      case "APPROVER":
        return "משתמש מאשר";
      default:
        return "משתמש רגיל";
    }
  };

 

  const onClose = () => {
    setShowPopup(false);
  };

  const onConfirm = async () => {
    try {
      axiosInstance.enableAuthHeader();
      if (selectedPopupOption === "USER") {
        await axiosInstance.post("/api/areaadmin/makeRegUser", {
          phone: user.phone,
        });
        const newUser = { ...user, role: "USER", canApprove: false };
        setUser(newUser);
        return;
      }
      if (selectedPopupOption === "APPROVER") {
        await axiosInstance.post("/api/areaadmin/makeApprover", {
          phone: user.phone,
        });
        const newUser = { ...user, role: "USER", canApprove: true };
        setUser(newUser);
      }

      if (selectedPopupOption === "AREAADMIN") {
        await axiosInstance.post("/api/areaadmin/makeAreaAdmin", {
          phone: user.phone,
        });
        const newUser = { ...user, role: "AREAADMIN", canApprove: true };
        setUser(newUser);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowPopup(false);
      setSelectedPopupOption("");
    }
  };

  const Popup = ({
    onClose,
    onConfirm,
    options,
    selectedOption,
    setSelectedOption,
  }) => {
    return (
      <div className="popup-container">
        <div className="popup-content">
          <div className="popup-header" />
          <div className="popup-titles">
            <button onClick={onClose}>X</button>

            <div>
              <h2>הרשאות משתמש</h2>
              <p>בחר סוג משתמש עבור שומר</p>
            </div>
          </div>
          <div className="popup-left">
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {getRoleDisplayName(option)}
                </option>
              ))}
            </select>
          </div>
          <div className="popup-right">
            <button onClick={onConfirm}>אישור</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="area-manager-container">
      <div
        className="area-manager-not-clicked"
        onClick={() => {
          setShowPopup(true);
        }}
      >
        <p>{getRoleDisplayName(userRole)}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
        >
          <path d="M7 10.5L0.937822 0L13.0622 0L7 10.5Z" fill="#CCCCCC" />
        </svg>
      </div>
      {showPopup && (
        <Popup
          onClose={onClose}
          onConfirm={onConfirm}
          options={roles}
          selectedOption={selectedPopupOption}
          setSelectedOption={setSelectedPopupOption}
        />
      )}
    </div>
  );
};
