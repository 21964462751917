import polygon from "../images/polygon-4.png";
import SlideUpModal from "./DetailsModal/SlideUpModal";
import { useState, useEffect } from "react"; 
import "../../../styles/AdminPanel/GuardListItem.css";

const GuardListItem = ({ className, polygonClassName, name, area, phone, uuid, avatar }) => {
  const [detailsWindow, setDetailsWindow] = useState(false)
  const openDetails = () => {
    // console.log("openDetails")
    setDetailsWindow(!detailsWindow)
  }
  useEffect(() => {
    // console.log("GuardListItem", uuid)
  }, [uuid])
  return (
    <div className={`guard-list-item ${className}`}>
      <div className="ant-design-setting">
        <button onClick={openDetails} >פרטים</button>
      </div>
      <div className="text">
        <div className="div">{name}</div>
        {/* <p className="p">
          <span className="span">איזור</span>
          <span className="text-wrapper-2">{` ${area}`}</span>
        </p> */}
        <p className="p">
          <span className="span">טלפון</span>
          <span className="text-wrapper-2"> {phone}</span>
        </p>
      </div>
      <img className="avatar" alt="Avatar" src={avatar} />
      <SlideUpModal onClose={openDetails} isOpen={detailsWindow} uuid={uuid} readOnlyMode={true} />
    </div>
  );
};



export default GuardListItem;