import { useState, useContext, useEffect } from "react";
import AdminHome from "./AdminHome";
import OpenEvents from "./OpenEvents";
import ActiveGuards from "./ActiveGuards";
import NavBar from "./AdminPanelComponents/NavBar";
import UserContext from "../../utils/UserContext";
import { useNavigate } from "react-router-dom";
import "../../styles/AdminPanel/AdminPanelContainer.css"

const AdminPanelContainer = () => {

  const {user} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const userNotApproved = user?.approved === false;
    if (userNotApproved) {
      navigate("/waiting");
      return;
    }
  },[user, navigate])
  const [activeComponent, setActiveComponent] = useState("AdminHome");

  const changeActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };


  return (
    <div className="admin-pannel-container">
      {activeComponent === 'OpenEvents' && <OpenEvents />}
      {activeComponent === 'AdminHome' &&  <AdminHome />}
      {activeComponent === 'ActiveGuards' && <ActiveGuards />}
      <NavBar changeActiveComponent={changeActiveComponent} />
    </div>
  );
};

export default AdminPanelContainer;