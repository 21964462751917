import React from "react";
import '../../../styles/AdminPanel/UserDetailsTraining.css'

export const UserDetailsAdditionalSkills = ({user}) => {

  const details = [    
    {title: "נשיאת נשק", value: user.weaponStatus},
    {title: "זמינות למשמרות", value: user["shiftAvailability"]? "זמין": "לא זמין"},
    {title: "דרגה צבאית", value: user.militaryRank},
    {title: "שירות צבאי", value: user.militaryTraining},
    {title: "שירות מילואים", value: user.miluimStatus},
  ]

  return (
    
    <div className="Training-container">
      {details.map(line => {
        return (<div key={line.title} className="training-div">
          <div className="training-text-wrapper">{line.value}</div>
          <div className="training-text-wrapper-2">{line.title}</div>
        </div>)
      })}
    </div>
  );
};

