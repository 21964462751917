import { useEffect, useState } from "react";
import VerifyPhone from "../components/PhoneVerification/VerifyPhone";
import SendCode from "../components/PhoneVerification/SendCode";
import axiosInstance from "../utils/axiosService";
import { useNavigate, useParams } from "react-router-dom";

const PhoneVerification = () => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, seUserName] = useState("")
  const navigate = useNavigate();


  const handleButtonClick = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const { uuid, areaUuid } = useParams()


  useEffect(() => {
    const updateFirstName = async () => {
      if (!!uuid) {
        try {
            await axiosInstance
              .get(`api/reguser/firstName/${uuid}`)
              .then((res) => {
                if(!!res && !!res.data && !!res.data.firstName) {
                  seUserName(res.data.firstName);
                }
              })
        } catch (error) {
          navigate("/login")
        }
      }
    }
    updateFirstName()

  }, [uuid]);

  return (
    <>
      {step === 1 && (
        <SendCode
          handleButtonClick={handleButtonClick}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          userName={userName}
        />
      )}
      {step === 2 && (
        <VerifyPhone
          phoneNumber={phoneNumber}
          uuid={uuid}
          areaUuid={areaUuid} />
      )}
    </>
  );
};

export default PhoneVerification;
