import React, { useState, useEffect, useRef, useContext } from "react";
import mdiUsers from "./images/mdi-users.svg";
import biFilter from "./images/bi-filter.svg";
import "../../styles/AdminPanel/OpenEvents.css";

import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosService";
import PropertyDefaultWrapper from "./AdminPanelComponents/PropertyDefaultWrapper";
import UserContext from "../../utils/UserContext";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const OpenEvents = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const {user} = useContext(UserContext);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    isGuardian: false,
    hasWeapon: false,
    isCommander: false,
    invitedBy: false,
    isMedic: false,
    isWarRoomCommander: false
  });
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const usersFilterOptions = [
    {title: "שומרים", key: "isGuardian"},
    {title: "מחזיק בנשק", key: "hasWeapon"},
    {title: "מפקדים", key: "isCommander"},
    {title: "הכשרה רפואית", key: "isMedic" },
    {title: "יכולות חמ\"ל", key: "isWarRoomCommander" },
    {title: "הוזמנו על ידי", key: "invitedBy"},
  ]

  const approveUser = async (userPhone, friendUuid) => {
    try {
      axiosInstance.enableAuthHeader();
      const body = { phone: userPhone };
      await axiosInstance.post("/api/areaadmin/approve", body);
      console.log("Success");
      // Remove the approved friend from the list
      setUsers((prev) => prev.filter((friend) => friend.uuid !== friendUuid));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getFriendsRequest = async () => {
      try {
        axiosInstance.enableAuthHeader();
        await axiosInstance
          .get("/api/areaadmin/pendingUsers?invitedByMe=false")
          .then((res) => { 
            setUsers(res.data); 
          });
          
      } catch (error) {
        // console.log(error);
      }
    };
    getFriendsRequest();
  }, []);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter(user =>
        user.firstName.includes(searchQuery) ||
        user.lastName.includes(searchQuery) ||
        user.phone.includes(searchQuery) ||
        user.area.includes(searchQuery)
      ));
    }
  }, [searchQuery, users]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = event => {
    if (
      dropdownRef.current && !dropdownRef.current.contains(event.target) &&
      toggleButtonRef.current && !toggleButtonRef.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [option]: !prevState[option]
    }));
  };

  const clearAll = () => {
    setSelectedOptions({
      isGuardian: false,
      hasWeapon: false,
      isCommander: false,
      invitedBy: false,
      isMedic: false,
      isWarRoomCommander: false,
    });
  };

  useEffect(() => {
    if (!users) return
    let filtered = [...users];  // Create a shallow copy of the users array to avoid modifying the original array

    if (searchQuery !== '') {
      filtered = filtered.filter(user =>
        user.firstName.includes(searchQuery) ||
        user.lastName.includes(searchQuery) ||
        user.phone.includes(searchQuery) ||
        user.area.includes(searchQuery)
      );
    }

    // If any filter is selected, apply the selected filters
    if (selectedOptions.isGuardian || selectedOptions.hasWeapon 
      || selectedOptions.isWarRoomCommander || selectedOptions.isMedic
      || selectedOptions.isCommander || selectedOptions.invitedBy) {
      filtered = filtered.filter(filteredUser =>
        (selectedOptions.isGuardian ? filteredUser.isGuardian : true) &&
        (selectedOptions.hasWeapon ? filteredUser.hasWeapon : true) &&
        (selectedOptions.isCommander ? filteredUser.isCommander : true) && 
        (selectedOptions.isMedic ? filteredUser.isMedic : true) && 
        (selectedOptions.isWarRoomCommander ? filteredUser.isWarRoomCommander : true) && 
        (selectedOptions.invitedBy ? !!filteredUser.invitedBy && filteredUser.invitedBy === user.phone : true) 
      );
    }

    setFilteredUsers(filtered);
  }, [searchQuery, selectedOptions, users]);

  
  return (<>
    <div className="active-guards">
      <div className="div-3">
        <div className="overlap">
          <div style={{color:"white", fontSize:"30px"}}>
            <FontAwesomeIcon icon={faExclamationCircle} className={`icon`} />
          </div>
          <div>
            <div className="text-wrapper-9">ממתינים</div>
          </div>
        </div>

        {/* copied from active-guards */}
        <div className="items-container">

          <div className="active-guard-info">
            <div className="frame-wrapper">
              <div className="group-wrapper">
                <div className="group">
                  <div className="text-wrapper-6">({users?.length})</div>
                </div>
              </div>
            </div>
            <div className="div-wrapper">
              <div className="frame-3">
                <div className="group-2">
                  <div className="text-wrapper-7">חברים ממתינים</div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-4">
            <img
              className="bi-filter"
              alt="Bi filter"
              src={biFilter}
              onClick={toggleDropdown}
              ref={toggleButtonRef}
            />
            {dropdownVisible && (
              <div className="users-dropdown" ref={dropdownRef}>
                <button className="close-btn" onClick={toggleDropdown}>X</button>
                {usersFilterOptions.map(opt => 
                <div key={opt.key} className="option" onClick={() => handleOptionSelect(opt.key)}>
                  <input type="checkbox" checked={selectedOptions[opt.key]} readOnly /> {opt.title}
                </div>)}
                <button className="clear-all-btn" onClick={clearAll}>נקה הכל</button>
              </div>
            )}
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                <input
                  type="text"
                  className="text-wrapper-8"
                  placeholder="חפש שומר"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  ref={inputRef}
                />
                <svg
                  className="ri-search-line"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  onClick={() => inputRef.current.focus()}  // Use the ref to focus the input
                >
                  <path d="M13.2745 12.1552L16.105 14.985C16.4144 15.2943 16.4144 15.7959 16.1051 16.1052C15.7957 16.4145 15.2942 16.4145 14.9849 16.1051L12.155 13.2746C10.8937 14.2857 9.32484 14.8357 7.70825 14.8334C3.77525 14.8334 0.583252 11.6414 0.583252 7.70837C0.583252 3.77537 3.77525 0.583374 7.70825 0.583374C11.6413 0.583374 14.8333 3.77537 14.8333 7.70837C14.8356 9.32496 14.2856 10.8938 13.2745 12.1552ZM11.6864 11.5677C12.6911 10.5345 13.2522 9.14955 13.2499 7.70837C13.2499 4.647 10.7696 2.16671 7.70825 2.16671C4.64688 2.16671 2.16659 4.647 2.16659 7.70837C2.16659 10.7697 4.64688 13.25 7.70825 13.25C9.14943 13.2523 10.5344 12.6912 11.5676 11.6865L11.6864 11.5677Z" fill="#303030" />
                </svg>
              </div>
            </div>
          </div>


          </div>


      </div>
    </div>
      <div className="frame-5 waiting-users-wrapper">
        {filteredUsers.map((friend) => (
          <PropertyDefaultWrapper
            key={friend.uuid}
            className="swipe-to-delete-instance"
            property1="default"
            friend={friend}
            approveUser={approveUser}
          />
        ))}
      </div>
    </>
  );
};

export default OpenEvents;
