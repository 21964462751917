import { useState, useEffect, useContext } from "react";
import {
  setDefaults,
  fromAddress,
  geocode,
  RequestType,
} from "react-geocode";
import PanicButton from "../components/PanicButton";
import Emergency from "../components/Emergency";
import OEStepOne from "../components/OpenEmergency/OEStepOne";
import OEStepTwo from "../components/OpenEmergency/OEStepTwo";
import OEStepThree from "../components/OpenEmergency/OEStepThree";
import CancelEvent from "../components/CancelEvent";
import axiosInstance from "../utils/axiosService";
import { useNavigate } from "react-router-dom";
import UserContext from "../utils/UserContext";
import '../styles/pages/Home.css';

const Home = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");


  useEffect(() => {
    if(!user) navigate('/login')
    setUserRole(user?.role);
    const userNotApproved = user?.approved === false;
    if (userNotApproved) {
      navigate("/waiting");
      return;
    }
  }, [user, navigate])

  const [step, setStep] = useState(1);
  const [emergencyType, setEmergencyType] = useState("");
  const [isCancelling, setIsCancelling] = useState(false);
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const [manualAddressError, setMenualAddressError] = useState("");
  const [isSubmittingLocation, setIsSubmittingLocation] = useState(false);
  const [gpsLocation, setGpsLocation] = useState("כתובת לא מזוהה");
  const [userLocation, setUserLocation] = useState("");
  const [eventUuid, setEventUuid] = useState('');


  //GOOGLE MAP GEOCODING SETTINGS
  setDefaults({
    key: "AIzaSyD5Mfm8l-OYK6wBoizmx5qzlMczMnF_XCA",
    language: "he",
    region: "es",
  });

  //GET CURRENT COORDINATES WHEN PAGE IS OPEN (MAYBE TO MOVE TO FIRST SCREENS)
  useEffect(() => {
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        function (error) {
          // console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    }
  }, []);

  //REVERSE COORDINATES TO AN ADDRESS
  useEffect(() => {
    if (coordinates.latitude && coordinates.longitude && !userLocation) {
      const gps = coordinates.latitude + "," + coordinates.longitude;
      geocode(RequestType.LATLNG, gps)
        .then(({ results }) => {
          const address = results[0].formatted_address;
          setGpsLocation(address);
          setUserLocation(address);
        })
        .catch(error => {
          // console.log(error)
        });
    }
  }, [coordinates]);

  const handleManualLocationChange = async (newAddress) => {
    setMenualAddressError("");
    setIsSubmittingLocation(true);
    const qAddress = newAddress.replace(' ', '+');
    
    fetch(`https://nominatim.openstreetmap.org/search?accept-language=he&format=jsonv2&q=${qAddress}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if(data.length > 0){
          const locationData = data[0];
          setCoordinates({
            latitude: locationData.lat,
            longitude: locationData.lon,
          });
          setUserLocation(newAddress);
        }
        else{
          setMenualAddressError("כתובת לא נמצאה")
        }
      })
      .catch(error => {
        console.error(error);
        setMenualAddressError("אופס, אירעה שגיאה")
      })
      .finally(() => {
        setIsSubmittingLocation(false);
      });
  }

  function handleCreateEvent(args) {
    if (userLocation) {
      fromAddress(userLocation)
        .then(({ results }) => {
          const { lat, lng } = results[0].geometry.location;
          setCoordinates({ latitude: lat, longitude: lng });
        })
        .catch(console.error);
    }

    const params = {
      "address": userLocation || gpsLocation,
      "eventTypeId": emergencyType || args.eventTypeId,
      "latitude": coordinates.latitude,
      "longitude": coordinates.longitude,
      "numberOfAttackers": args.amount || null
    }

    axiosInstance.enableAuthHeader();
    axiosInstance.post('/api/event', params)
      .then((res) => setEventUuid(res.data.uuid))
      .catch(err => { console.log(err.message) });
  }

  //COMMON HANDLERS
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleCancelEvent = () => {
    setIsCancelling(true);
  };

  function handleReturnToEvent() {
    setIsCancelling(false);
  }

  function handleLeaveEvent() {
    setStep(1);
    setIsCancelling(false);
    if (eventUuid)
      cancelEventQuery(eventUuid);
      setEventUuid('');
  }

  //FIRST QUESTION HANDLER
  function handleWeaponStep(value) {
    setEmergencyType(value);
    if (value === 4) {
      handleCreateEvent({ eventTypeId: value });
      setStep(4);
    } else
      handleNextStep();
  }

  //SECOND QUESTION HANDLER
  function handleAmountStep(value) {
    handleNextStep();
    handleCreateEvent({ amount: value });
  }

  //CLOSE EVENT AFTER OPENING ON THE THIRD STEP
  function cancelEventQuery(eventUuid) {
    axiosInstance.enableAuthHeader();
    axiosInstance.put(`/api/event/event/${eventUuid}/close`);
  }

  function handleShowEventDetails(){
    navigate('/event/' + eventUuid)
  }


  return (
    /* eslint no-mixed-operators: "off" */

    <div className="home">
      {userRole === "USER" || userRole === "AREAADMIN" ?

        <div>
          {isCancelling
            ? <CancelEvent
              gpsLocation={userLocation || gpsLocation}
              handleReturnToEvent={handleReturnToEvent}
              handleLeaveEvent={handleLeaveEvent} />
            : step === 1 &&
            <PanicButton
              gpsLocation={userLocation || gpsLocation}
              handleButtonClick={handleNextStep}
              manualAddressError={manualAddressError}
              isSubmittingLocation={isSubmittingLocation}
              handleChangeInput={handleManualLocationChange}
              userLocation={userLocation} />
            || step === 2 &&
            <OEStepOne
              gpsLocation={userLocation || gpsLocation}
              handleClick={handleWeaponStep}
              handleCancelEvent={handleCancelEvent}

              setEmergencyType={setEmergencyType} />
            || step === 3 &&
            <OEStepTwo
              gpsLocation={userLocation || gpsLocation}
              handleClick={handleAmountStep}
              emergencyType={emergencyType}
              handleCancelEvent={handleCancelEvent} />
            || step === 4 &&
            <OEStepThree
              gpsLocation={userLocation || gpsLocation}
              emergencyType={emergencyType}
              eventUuid={eventUuid}
              handleShowEventDetails={handleShowEventDetails}
              handleCancelEvent={handleCancelEvent} />}
        </div>
        :
        <Emergency />
      }

      <div className="rectangle" />
    </div>
  );
};

export default Home;


//FREE API REQUESTS

// fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=he&format=jsonv2&lat=${coordinates.latitude}&lon=${coordinates.longitude}`)
//   .then(response => response.json())
//   .then(data => {
//     const address = data.address.road + ' ' + (data.address.house_number || '-') + ', ' + data.address.town;
//     setGpsLocation(address);
//     console.log("Address is :", data.address);
//   })
//   .catch(error => {
//     console.error(error);
//   });

// const qAddress = userLocation.replace(' ', '+');

// fetch(`https://nominatim.openstreetmap.org/search?accept-language=he&format=jsonv2&q=${qAddress}`)
//   .then(response => response.json())
//   .then(data => {
//     console.log(data);
//   })
//   .catch(error => {
//     console.error(error);
//   });