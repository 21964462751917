import React from "react";
import '../../styles/OpenEmergency/OEStepTwo.css';
import Location from "../Location";
import alertheader from '../../icons/emergency-alarm.svg';

export default function EOStepTwo({
  emergencyType,
  handleCancelEvent,
  handleClick,
  gpsLocation }) {
  return (
    <div className="oe-step-two">
      <div className="overlap-wrapper">
        {/* <div className="rectangle" /> */}
        <div className="overlap-group-wrapper">
          <button className="overlap-group" onClick={handleCancelEvent}>
            <div className="div" type='button'>ביטול מצב חירום</div>
          </button>
        </div>

        <div className="text-wrapper-2">
          {emergencyType === 2 && 'אירוע נשק קר'}
          {emergencyType === 1 && 'אירוע נשק חם'}
          {emergencyType === 3 && 'אירוע אלימות'}
          {emergencyType === 4 && 'אירוע כללי'}
        </div>
        <div className="text-wrapper-3">מספר מפגעים משוער</div>
        <button
          className="group-2 amount-button"
          onClick={() => handleClick('1+')}>
          <div className="text-wrapper-5">עד 5</div>
          <div className="text-wrapper-6">מפגעים</div>
        </button>
        <button
          className="group-3 amount-button"
          onClick={() => handleClick('5+')}>
          <div className="text-wrapper-5">מעל 5</div>
          <div className="text-wrapper-6">מפגעים</div>
        </button>
        <button
          className="group-4 amount-button"
          onClick={() => handleClick('10+')}>
          <div className="text-wrapper-5">מעל 10</div>
          <div className="text-wrapper-6">מפגעים</div>
        </button>

        <Location gpsLocation={gpsLocation} />
        <div className='alert-wrapper' />
        <img src={alertheader} className="alert-header" alt="alert" />
      </div>
    </div>
  );
};