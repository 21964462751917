import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Card from "./Card";
import "../../../styles/AdminPanel/SwipeToDelete.css";
import Button from "../../Button";
import axiosInstance from "../../../utils/axiosService";
import { useNavigate } from "react-router-dom";
import CancelEventPopup from "./CancelEventPopup/CancelEventPopup";

const SwipeToDelete = ({
  property1,
  className,
  cardDivClassName,
  cardSubtract = "/img/subtract-7.svg",
  cardText = "דרוש טיפול",
  cardFrameClassName,
  cardText1 = "מעל 10",
  address,
  areaDescription,
  eventTypeDescription,
  activeComponent,
  closeEvent,
  event,
}) => {
  const navigate = useNavigate();

  const openEventDetails = (eventUuid) => {
    navigate(`/event/${eventUuid}`, { state: { event } });
  };

  useEffect(() => {}, [event]);
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className={`swipe-to-delete ${property1} ${className}`}>
      <Card
        address={address}
        areaDescription={eventTypeDescription}
        className={`${
          property1 === "deleted-message"
            ? "class-3"
            : property1 === "default"
            ? "class-4"
            : "class-5"
        }`}
        divClassName={cardDivClassName}
        frameClassName='card-instance'
        frameClassNameOverride={cardFrameClassName}
        subtract={cardSubtract}
        subtractClassName={`${
          property1 === "deleted-message" ? "class" : "class-2"
        }`}
        text={cardText1}
        text1={cardText}
        textClassName='instance-node'
        createdAt={event.createdAt}
        eventType={event.eventTypeId}
      />
      {activeComponent === "OpenEvents" ? (
        <div className='SwipeToDelete__buttons'>
          <Button
            onClick={() => openEventDetails(event.uuid)}
            className='mangeEvent text-element-class'
            textButton='נהל אירוע'
          />
          <Button
            onClick={() => {
              setShowPopup(true);
              // closeEvent(event.uuid);
            }}
            className='mangeEvent delete text-element-class'
            textButton='בטל אירוע'
          />
          {showPopup && (
            <CancelEventPopup
              closeEvent={closeEvent}
              setShowPopup={setShowPopup}
              uidd={event.uuid}
              address={address}
              areaDescription={areaDescription}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

SwipeToDelete.propTypes = {
  property1: PropTypes.oneOf(["deleted-message", "delete", "default"]),
  cardSubtract: PropTypes.string,
  cardText: PropTypes.string,
  cardText1: PropTypes.string,
};

export default SwipeToDelete;
