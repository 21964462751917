import { useState, useContext, useRef, useEffect } from "react";
import Input from "../components/Input";
import magen from "../assets/logo-magen.png";
import Button from "../components/Button";
import axiosInstance from "../utils/axiosService";
import localStorageService from "../utils/localStorageService";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../utils/UserContext";
import "../styles/pages/Login.css";
import VerifyPhone from "../components/PhoneVerification/VerifyPhone";

import { isInputEmpty } from "../components/validation/inputValidation";

const Login = () => {
  const [phoneNumber, setphoneNumber] = useState("");
  const { setUser } = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorageService.get("token");
    console.log(token)
    if (token) { navigate("/"); }
  }, []);

  const handleValidationAndSubmit = (e) => {
    e.preventDefault();
    if(loading) return;
    const phoneRegex = /^\d{10,}$/;

    if (isInputEmpty(phoneNumber)) {
      setError("שדה חובה");
      return;
    }
    if (!phoneRegex.test(phoneNumber) && phoneNumber.length > 0) {
      setError("מספר לא תקין");
      return;
    }

    setError("");
    handleSendCode(e);
  };

  const handleSendCode = async (e) => {
    if(e) e.preventDefault();
    if(loading) return;
    setLoading(true);
    const data = {
      phone: phoneNumber,
    };
    
    try {
      axiosInstance.disableAuthHeader();
      const response = await axiosInstance.post("/api/reguser/verification2", data);
      localStorageService.store(response.data, "tempToken")
      setStep(2);
    } catch (error) {
      setError("משהו השתבש, נסה שוב");
    }
    finally{ setLoading(false) }
  };

  const handleRegistration = async (verificationCode) => {
    const tempToken = localStorageService.get("token", "tempToken")
    const data = {
      phone: phoneNumber,
      token: tempToken,
      verificationCode: verificationCode,
    };
    setVerificationCodeError("");

    const getUserDetails = async () => {
      axiosInstance.enableAuthHeader();
      const userDetails = await axiosInstance.get("/api/user/details");
      setUser(userDetails.data);
    }
    setLoading(true);
    try{
      axiosInstance.disableAuthHeader();
      const response = await axiosInstance.post("/api/reguser/verify2", data);
      const status = response.data.status;

      if(status === "NOT_REGISTERED"){
        navigate('/create-user');
      }
      else if(status === "REGISTERED"){
        localStorageService.store({token: response.data.access_token});
        await getUserDetails();
        localStorageService.delete("tempToken");
        navigate('/');
      }
      else if(status === "NOT_APPROVED"){
        localStorageService.store({token: response.data.access_token});
        await getUserDetails()
        localStorageService.delete("tempToken");
        navigate('/waiting');
      }
      
    }
    catch(err){ setVerificationCodeError("קוד שגוי") }
    finally{ setLoading(false) }

  }


  return (<>
    {step === 1 && (
      <div className="login-page">
        <div className="div-2">
          <div className="overlap">
            <img className="magen-ezrahi" alt="Magen ezrahi" src={magen} />
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-2">
              <form className="frame" onSubmit={handleValidationAndSubmit} ref={formRef}>
                <div className="form-input-area">
                  <Input
                    className="component-22"
                    divClassName="component-22-instance"
                    overlapGroupClassName="component-instance"
                    property1="variant-2"
                    text="מספר טלפון"
                    inputValue={phoneNumber}
                    setInputValue={setphoneNumber}
                    inputType="text"
                  />
                  <div className="error-message" style={{ color: 'red' }}>{error}</div>
                </div>
                <div className="frame-2">
                  <Button
                    textButton={loading? "אנא המתן...":"התחברות"}
                    className={"button-instance last-text-element-class"}
                    type="submit"
                  />
                  <Link 
                    style={{color: '#006ece'}} 
                    to='/privacy'>
                      privacy policy - מדיניות פרטיות
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div className="rectangle-2" />
        </div>
      </div>
    )}
    {step === 2 && (
        <VerifyPhone
          loading={loading}
          verificationCodeError={verificationCodeError}
          handleResendCode={handleSendCode}
          handleRegistration={handleRegistration} />
      )}
  </>);
};

export default Login;
