import React, { useState, useEffect, useContext } from "react";
import SwipeToDelete from "./AdminPanelComponents/SwipeToDelete";
import mdiUsers from "./images/mdi-users.svg";
import subtractTwo from "./images/subtract-2.svg";
import subtract from "./images/subtract.svg";
import "../../styles/AdminPanel/AdminHome.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosService";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import InviteAdminPopup from "../InviteAdminPopup/InviteAdminPopup";
import UserContext from "../../utils/UserContext";
import Logout from "../Logout/Logout";
import OpenEvents from './OpenEvents'
import SlideUpModal from '../../components/AdminPanel/AdminPanelComponents/DetailsModal/SlideUpModal.js'

const AdminHome = () => {
  const { user } = useContext(UserContext);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [events, setEvents] = useState([]);
  const [freindsRequest, setFreindsRequest] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [detailsWindow, setDetailsWindow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(3);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const navigate = useNavigate();
  useEffect(() => {
    try {
      axiosInstance.enableAuthHeader();
      axiosInstance.get("/api/event/event").then((res) => {
        console.log("events res", res.data);
        const newEvents = res.data;
        const filteredAndSortedEvents = newEvents
          .filter((event) => !event.isResolved)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setEvents(filteredAndSortedEvents);
      })
      .finally(() => setIsLoadingEvents(false));
      getFriendsRequest();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getFriendsRequest = () => {
    try {
      axiosInstance
        .get("/api/areaadmin/pendingUsers?invitedByMe=false")
        .then((res) => {
          setFreindsRequest(res.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const openDetails = () => {
    console.log("/admin-panel/edit-user/" + user.uuid)
    navigate("/admin-panel/edit-user/" + user.uuid);
  };
  
  const approveUser = async (userPhone, friendUuid) => {
    try {
      axiosInstance.enableAuthHeader();
      const body = { phone: userPhone };
      await axiosInstance.post("/api/areaadmin/approve", body);
      console.log("Success");
      // Remove the approved friend from the list
      setFreindsRequest((prev) => prev.filter((friend) => friend.uuid !== friendUuid));
    } catch (error) {
      console.log(error);
    }
  };

  const closeEvent = (eventUuid) => {
    try {
      axiosInstance.enableAuthHeader();
      axiosInstance.put(`/api/event/event/${eventUuid}/close`)
      .then((res) => {
        console.log(res, "Event was closed");
        const updatedEvents = [...events].filter(e => e.uuid !== eventUuid)
        setEvents(updatedEvents) // Assuming the API returns an array of events
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {user?.canApprove && user?.role !== "AREAADMIN" ? (
        <div className="admin-home">
          <div className="div-3">
            <div className="overlap">
              <div className="icons-container">
                <EditIcon
                  fontSize="large"
                  className="admin-invite action-icon"
                  onClick={openDetails}
                />
                {user && (
                  <SlideUpModal
                    isOpen={detailsWindow}
                    onClose={openDetails}
                    uuid={user.uuid}
                  />
                )}
                <HomeIcon
                  className="mdi-users action-icon"
                  src={mdiUsers}
                  onClick={() => navigate("/")}
                />
              </div>

              <div>
                <div className="text-wrapper-9">ערב טוב {user?.firstName}</div>
                <div className="text-wrapper-10">{ }</div>
              </div>
              <div>
                <Logout className="action-icon" />
              </div>
            </div>

            <div className="user-waitings-warpper">
              <OpenEvents />
            </div>
            {isPopupOpen && (
              <InviteAdminPopup setIsPopupOpen={setIsPopupOpen} />
            )}
          </div>
        </div>
      ) : (
        <div className="admin-home">
          <div className="div-3">
            <div className="overlap">
              <div className="icons-container">
                <EditIcon
                  fontSize="large"
                  className="admin-invite  action-icon"
                  onClick={openDetails}
                />
                {user && (
                  <SlideUpModal
                    isOpen={detailsWindow}
                    onClose={openDetails}
                    uuid={user.uuid}
                  />
                )}
                <HomeIcon
                  className="mdi-users action-icon"
                  src={mdiUsers}
                  onClick={() => navigate("/")}
                />
              </div>

              <div>
                <div className="text-wrapper-9">ערב טוב {user?.firstName}</div>
                <div className="text-wrapper-10">{ }</div>
              </div>
              <div>
                <Logout />
              </div>
            </div>

            {currentEvents.length > 0 ? 
            <div className="frame-4">
              <div className="group">
                <div className="text-wrapper-11">אירועים פתוחים</div>
                <div className="pagination-controls">
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      currentPage === Math.ceil(events.length / eventsPerPage)
                    }
                  >
                    הבא
                  </button>

                  <span>
                    עמוד {currentPage} מתוך{" "}
                    {Math.ceil(events.length / eventsPerPage)}
                  </span>

                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    הקודם
                  </button>
                </div>
                <div className="frame-5">
                  {currentEvents.map((event) => (
                    <SwipeToDelete
                      key={event.id}
                      areaDescription={event.areaDescription}
                      eventTypeDescription={event.eventTypeDescription}
                      address={event.address}
                      cardDivClassName={
                        event.isResolved === null
                          ? "orangeBg"
                          : event.isResolved
                            ? "greenBg"
                            : "redBg"
                      }
                      cardFrameClassName={
                        event.isResolved
                          ? "swipe-to-delete-4"
                          : "swipe-to-delete-2"
                      }
                      cardSubtract={event.isResolved ? subtract : subtractTwo}
                      cardText={event.isResolved ? "לאחר טיפול" : "דורש טיפול"}
                      cardText1={event.numberOfAttackers}
                      className="swipe-to-delete-instance"
                      activeComponent={"OpenEvents"}
                      property1="default"
                      closeEvent={closeEvent}
                      event={event}
                    />
                  ))}
                </div>
              </div>
            </div>:
            <div className="frame-4">
              <div className="group">
                <div className="text-wrapper-11">{isLoadingEvents? "טוען אירועים...": "אין אירועים פתוחים"}</div>
              </div>
            </div>}
            {isPopupOpen && (
              <InviteAdminPopup setIsPopupOpen={setIsPopupOpen} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminHome;
