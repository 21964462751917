import { createRef } from 'react'
import './ActiveGuardCard.css'
import gun from '../../icons/black-gun.svg';

const ActiveGuardCard = ({ 
    phone, 
    avatar, 
    isCommander,
    isGuardian,
    hasWeapon,
    loggedUser,
    firstName, 
    lastName,
    distInWords}) => {

        // const WKButtonRef = createRef();

        // const isRecording = (event) => {
        //     event.preventDefault();
        //     WKButtonRef.current.style.backgroundColor = "#04ac04"
        //     WKButtonRef.current.style.borderColor = "#04ac04"
        // }
    
        // const stopRecording = () => {
        //     WKButtonRef.current.style.backgroundColor = "#013D70"
        //     WKButtonRef.current.style.borderColor = "#013D70"
        // }

    return (<>
        <div className="active-guard-card">
            <div className={`avatar-img ${isCommander? "is-commander": isGuardian?"is-guardian":"is-user"}`}>
                {hasWeapon && <img src={gun} alt="weapon icon" className='weapon-icon' />}
                <img src={avatar} alt="guard avatar" />
            </div>
            <div className="guard-details">
                <div className="guard-name">{loggedUser? "את/ה":firstName + " " + lastName}</div>
                <div className="distance">{distInWords}</div>
            </div>
            <div className="guard-action-btns">
                <a href={`tel:${phone}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    className="bi bi-telephone-fill phone-btn" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                </a>
                <div className="walky-btn">
                    <img src="/images/walkie-talkie.png" alt="walkie talkie icon"/>
                </div>
            </div>
        </div>
        <hr/>
</>)
}

export default ActiveGuardCard