import PropTypes from "prop-types";
import "../../../styles/AdminPanel/NewMember.css";
import { useNavigate } from 'react-router-dom';

 const NewMember = ({
  className,
  frameClassName,
  textClassName,
  avatarClassName,
   friend,
   approveUser
   
}) => {
   const navigate = useNavigate()
   const handleDetailsClick = () => {
     navigate(`/read-edit-user/${friend.uuid}`);
   };
  return (
    <div className={`new-member ${className}`}>
      <div className={`frame-2 ${frameClassName}`}>
        <div className="rectangle" />
        <div className="rectangle-2" />
        <div className="text-wrapper-2" onClick={approveUser}>אישור</div>
        <div className="text-wrapper-3" onClick={handleDetailsClick}>
          פרטים
        </div>
      </div>
      <div onClick={handleDetailsClick} className={`text-2 ${textClassName}`}>
        <div className="text-wrapper-4">{friend.firstName}</div>
        <p className="div-2">
          <span className="span">איזור</span>
          <span className="text-wrapper-5"> {friend.area}</span>
        </p>
        <p className="div-2">
          <span className="span">טלפון</span>
          <span className="text-wrapper-5"> {friend.phone}</span>
        </p>
      </div>
      <img onClick={handleDetailsClick} className={`avatar ${avatarClassName}`} alt="Avatar" src={friend.avatar} />
    </div>
  );
 
};

NewMember.propTypes = {
  avatar: PropTypes.string,
};


export default NewMember