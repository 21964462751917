import React, { useState, useEffect } from "react";
import "../../../styles/AdminPanel/UserDetailsPrivate.css";
import PortraitIcon from '@mui/icons-material/Portrait';
import IdPhoto from "../../CreateUser/IdPhoto";
import UserDetailLine from "./UserDetailLine";

const Frame = ({ user }) => {
  const [idShown, setIdShown] = useState(false);

  const languages = [
    user.englishSpeaker ? 'אנגלית': undefined,
    user.arabicSpeaker ? 'ערבית': undefined,
    user.russianSpeaker ? 'רוסית' : undefined,
    user.frenchSpeaker ? 'צרפתית': undefined,
    user.amharicSpeaker ? 'אמהרית': undefined,
    !!user.otherSpeakingLanguages ? user.otherSpeakingLanguages : undefined,
  ].filter(lan => !!lan).join(', ')

  const details = [
    {title: "גיל", value: user.age},
    {title: "כתובת", value: user.homeAddress},
    {title: "מייל", value: user.email},
    {title: "שפות", value: !!languages? languages: "אין מידע"},
  ]

  return (
    <div className="private-details-container">
      <div className="div">
        <p className="p">
          <span className="span">איזור:</span>
          <span className="span"> {user.area}</span>
        </p>
        <div className="text-wrapper-2">{user.firstName} {user.lastName}</div>
      </div>
      {details.map(line => (<UserDetailLine {...line} key={line.title}/>) )}
      <div className="text-wrapper-3">
        <a href={`tel:${user.phone}`}>{user.phone}</a>
      </div>
      <div className='user-details-id-area'>
        {!idShown &&<PortraitIcon onClick={()=>setIdShown(true)} />}
      
      <IdPhoto isActive={idShown} turnOff={setIdShown} imageSrc={user.idPhoto}/>


      <p className="element">
        <span className="text-title-wrapper">מספר ת.ז. </span>
        <span className="text-wrapper-4">{!!user.idNumber? user.idNumber: "אין מידע"}</span>
      </p>
      </div>
    </div>
  );
};

export default Frame;
