
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosService";
import Button from "../Button";
import "../../styles/CreateAssistantLandingPage/CAStepTwo.css";
import Dropdown from "../DropDown";
import Select from "../Select";
import Progress from "../../icons/progressBar2.svg";

const techAbilities = [
  {
    id: "techHigh",
    description: "יכולת טכנולוגית גבוהה",
  },
  {
    id: "intelgence",
    description: "נסיון מודיעיני, תצפיות, ניתוח שטח",
  },
  {
    id: "complecited",
    description: "ניסיון בחמל וניהול מורכב",
  },
  {
    id: "contactWithSecurityForces",
    description: "קשר והיכרות עם גורמי ביטחון ומשטרה",
  },
  {
    id: "drone",
    description: "בעל רחפן",
  },
  {
    id: "else",
    description: "אחר",
  },
];

const managmentAbilities = [
  {
    id: "abilityToLead",
    description: "כן, יש לי יכולת לפקד על צוות",
  },
  {
    id: "noAlotOfKnoldge",
    description: "ניסיון בניהול צוות מתנדבים",
  },
];

const CAStepTwo = ({
  setStep,
  onButtonClick,
  selectedCar,
  setSelectedCar,
  selectedMedic,
  setSelectedMedic,
  selectedFitness,
  setSelectedFitness,
  selectedTechAbilities,
  setSelectedTechAbilities,
  selectedManagmentAbilities,
  setSelectedManagmentAbilities,
  setOtherAbilities
}) => {
  const [carsData, setCarsData] = useState([]);
  const [medicData, setMedicData] = useState([]);
  const [fitnessData, setFitnessData] = useState([]);

  //cars
  useEffect(() => {
    const fetchVehicleOwnership = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/vehicleOwnership');
        const CD = optionCars(response.data)
        setCarsData(CD)
      } catch (error) {}
    };
    const optionCars = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchVehicleOwnership();
  }, []);
  //medic
  useEffect(() => {
    const fetchMedicalAbilities = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/medicalAbilities');
        const MD = optionMedic(response.data)
        setMedicData(MD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optionMedic = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMedicalAbilities();
  }, []);

  //fitness
  useEffect(() => {
    const fetchFitnessLevel = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/fitnessLevel');
        const FD = optionFitness(response.data)
        setFitnessData(FD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optionFitness = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchFitnessLevel();
  }, []);


  return (
    <div className="ca-two">
      <div className="div-2">
        <div className="rectangle-2" />
        <div className="frame-2">
          <div className="frame-3">
            <div className="frame-4">
              <div className="progress-one">
                <img src={Progress} alt="שלב 2" />
                <div className="steps-btn-container">
                  <div className="box"></div>
                  <div className="box"></div>
                  <div className="box" onClick={()=> setStep(1)}></div>
                </div>
              </div>
              <div className="text-wrapper-4">הכשרות ויכולות נוספות</div>
            </div>
            <div className="frame-wrapper">
              <div className="frame-5">

                <Dropdown
                className="tech"
                  items={techAbilities}
                  selectedItems={selectedTechAbilities}
                  setSelectedItems={setSelectedTechAbilities}
                  label={"אמצעים טכנולוגיים"}
                  setOtherOptionContent={setOtherAbilities}
                />
                <Dropdown
                  className="command"
                  items={managmentAbilities}
                  selectedItems={selectedManagmentAbilities}
                  setSelectedItems={setSelectedManagmentAbilities}
                  label={"יכולות פיקוד וניהול"}
                />
                <Select
                  label="?האם קיים ברשותך רכב שטח"
                  options={carsData}
                  selectedOption={selectedCar}
                  setSelectedOption={setSelectedCar} // This should be a function to update the state
                />
                <Select
                  label="?האם קיימת הכשרה רפואית"
                  options={medicData}
                  selectedOption={selectedMedic}
                  setSelectedOption={setSelectedMedic} // This should be a function to update the state
                />
                <Select
                  label="דרגת כושר גופני"
                  options={fitnessData}
                  selectedOption={selectedFitness}
                  setSelectedOption={setSelectedFitness} // This should be a function to update the state
                />
              </div>
            </div>
          </div>
          <Button
            textButton={"שלב הבא"}
            className={"assistant text-element-class"}
            onClick={onButtonClick}
            // disabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default CAStepTwo;
