import "../../../../styles/AdminPanel/SwipeToDelete.css";
import "../../../../styles/AdminPanel/PopupEvent.css";

import { useRef } from "react";

export default function CancelEventPopup({
  closeEvent,
  setShowPopup,
  uidd,
  address,
  areaDescription,
}) {
  const popupRef = useRef(null);

  const handleMouseDown = (event) => {
    if (!popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  return (
    <div className='popup-overlay' dir='rtl' onMouseDown={handleMouseDown}>
      <div className='popup' ref={popupRef}>
        <h5>
          {address} - {areaDescription}
        </h5>
        <div className='popup-header'>
          <h3>האם ברצונך לבטל את האירוע?</h3>
        </div>
        <div className='popup-content'>
          <button
            className='popup-button-blue'
            onClick={() => {
              closeEvent(uidd);
              setShowPopup(false);
            }}
          >
            כן בטל מצב חירום
          </button>
          <button
            className='popup-button-red'
            onClick={() => setShowPopup(false)}
          >
            לא, השאר מצב חירום
          </button>
        </div>
      </div>
    </div>
  );
}
