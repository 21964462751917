import Input from "../Input";
import Button from "../Button";
import Progress from "../../icons/Group1.png";
import Select from "../Select";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosService";
import "../../styles/CreateUser/CUStepOne.css";

import {
  validateAlphabetic,
} from "../../components/validation/inputValidation";

const CUStepOne = ({
  firstName = "",
  setFirstName,
  lastName = "",
  setLastName,
  handleButtonClick,
  areaName = "",
  setAreaName,
  setAreas,
  uuid
}) => {
  const [areasData, setAreasData] = useState([]);

  useEffect(() => {
    const getAreas = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get("/api/area");

        const AD = optionsAreas(response.data);
        setAreasData(AD);
        setAreas(response.data);
        if(!uuid){
          setAreaName(0);
        }

        // setInviterArea(areasData.find((area) => area.uuid === areaUuid).description);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    const optionsAreas = (dataArray) => {
      return dataArray.map((item) => ({
        id: item.id,
        description: item.description,
        uuid: item.uuid,
      }));
    };

    getAreas();
  }, []);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [areaNameError, setAreaNameError] = useState("");

  const handleValidationAndSubmit = () => {
    let validationPassed = true;

    if (validationPassed) {
      setFirstNameError("");
      setLastNameError("");
      setAreaNameError("");
    }

    // --------------first name validation-----------------------------
    if (firstName.length === 0) {
      setFirstNameError("שדה זה הוא חובה");
      validationPassed = false;
    } else {
      if (!validateAlphabetic(firstName)) {
        setFirstNameError("שדה זה מכיל רק אותיות");
        validationPassed = false;
      }
    }

    // --------------last name validation-----------------------------
    if (lastName.length === 0) {
      setLastNameError("שדה זה הוא חובה");
      validationPassed = false;
    } else {
      if (!validateAlphabetic(lastName)) {
        setLastNameError("שדה זה מכיל רק אותיות");
        validationPassed = false;
      }
    }

    // ---- area name validation ----
    if (!uuid && !areaName) {
      setAreaNameError("שדה זה הוא חובה");
      validationPassed = false;
    }

    if (validationPassed) {
      setFirstNameError("");
      setLastNameError("");
    }
    return validationPassed;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidationAndSubmit();
    if (!isValid) return;
    handleButtonClick();
  };

  return (
    <div className="cu-one">
      <div className="div-2">
        <div className="rectangle" />
          <div className="progress-one">
            <img src={Progress} alt="שלב 2" />
            <div className="steps-btn-container">
              <div className="box"></div>
              <div className="box"></div>
              <div className="box"></div>
            </div>
          </div>

            <div className="text-wrapper-2">יצירת משתמש</div>
            <form className="frame" onSubmit={handleSubmit}>
              <Input
                className="design-component-instance-node"
                divClassName="component-22-instance"
                property1="variant-2"
                text="שם פרטי"
                inputValue={firstName}
                setInputValue={setFirstName}
                // required={true}
              />
              <div className="error-message">{firstNameError}</div>

              <Input
                className="design-component-instance-node"
                divClassName="component-2"
                property1="variant-2"
                text="שם משפחה"
                inputValue={lastName}
                setInputValue={setLastName}
                // required={true}
              />
              <div className="error-message">{lastNameError}</div>

              {!uuid && (
                <>
                <Select
                  label="בחר איזור"
                  options={areasData}
                  selectedOption={areaName}
                  setSelectedOption={setAreaName}
                />
              <div className="error-message">{areaNameError}</div>
              </>)}

              <Button
                type="submit"
                textButton={"לשלב הבא"}
                className={"button-instance"}
              />
            </form>
      </div>
    </div>
  );
};

export default CUStepOne;