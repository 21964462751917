import Button from "../Button";
import Input from "../Input";
import image from "../../assets/rafiki.png";
import axiosService from "../../utils/axiosService";
import localStorageService from "../../utils/localStorageService";
import "../../styles/AdminPhoneVerification/AdminSendCode.css";
import { useEffect, useState } from "react";
import { validateNumber } from "../../components/validation/inputValidation";
import HomeScreen from "../HomeScreen"

const AdminSendCode = ({ handleButtonClick, phoneNumber, setPhoneNumber }) => {
  let isButtonDisabled = phoneNumber.length < 10;
  const [btnText, setBtnText] = useState("לשלב הבא");
  const [error, setError] = useState("");

  const validInput = () => {
    let validationPassed = true;

    if (!validateNumber(phoneNumber)) {
      setError("שדה זה יכול להכיל רק ספרות");
      validationPassed = false;
    }

    if (validationPassed) {
      setError("");
      return true;
    }
  };

  const handleSubmit = async () => {
    const isValid = validInput();
    if (!isValid) return;

    const data = {
      phone: phoneNumber,
    };

    try {
      setBtnText("אנא המתן...");
      isButtonDisabled = false;
      const response = await axiosService.post(
        "/api/areaadmin/verification",
        data
      );
      console.log(response.data);

      const tempToken = response.data;
      localStorageService.store(tempToken, "tempToken");

      handleButtonClick();
    } catch (error) {
      if (error.response?.data?.error === "Not Found") {
        setBtnText("מספר קיים במערכת");
        setPhoneNumber("");
      } else {
        setBtnText("אנא נסה שנית");
      }

      isButtonDisabled = phoneNumber.length < 10;
    }
  };
  useEffect(() => {}, [isButtonDisabled]);

  return (
    <div className="admin-send-code">
      <div className="div">
        {/* <div className="rectangle" /> */}

        <img className="fire-prevention" alt="Fire prevention" src={image} />
        <div className="group">
          <div className="text-wrapper-2">הוזמנת להיות מנהל אזור</div>
          {/* <p className="p">
        
          </p> */}
          <div className="group-2">
            <div className="component">
              <div className="text-wrapper-3">מה מספר הטלפון שלך?</div>
              <Input
                className="component-22"
                divClassName="component-22-instance"
                overlapGroupClassName="component-instance"
                property1="variant-2"
                text="מספר טלפון"
                inputValue={phoneNumber}
                setInputValue={setPhoneNumber}
              />
            </div>
          </div>
        </div>
        <div className="error-message" style={{ color: "red" }}>
          {error}
        </div>

        <div className="line-button" alt="Line"></div>
        <Button
          textButton={btnText}
          className={
            isButtonDisabled
              ? "button-instance disabled"
              : "button-instance text-element-class"
          }
          disabled={isButtonDisabled}
          onClick={handleSubmit}
        />
      </div>
      <HomeScreen/>
    </div>
  );
};

export default AdminSendCode;
