import React from "react";
import '../../styles/OpenEmergency/OEStepOne.css';
import knife from '../../icons/knife.svg';
import gun from '../../icons/gun.svg';
import fist from '../../icons/fist.svg';
import Location from "../Location";
import alertheader from '../../icons/emergency-alarm.svg';

export default function OEStepOne({ handleClick, handleCancelEvent, gpsLocation }) {
  return (
    <div className="oe-step-one">
      <div className="div">
        <div className="overlap-group-wrapper">
          <button className="overlap-group" onClick={handleCancelEvent}>
            <div className="text-wrapper-2">ביטול מצב חירום</div>
          </button>
        </div>

        <div className="text-wrapper-3">סוג אירוע</div>
        <button
          className="overlap"
          type='button'
          onClick={() => handleClick(2)}>
          <div className="ri-knife-fill">
            <img className="subtract" alt="Subtract" src={knife} />
          </div>
          <div className="text-wrapper-4">נשק קר</div>
        </button>
        <button
          className="div-wrapper"
          type='button'
          onClick={() => handleClick(4)}>
          <div className="text-wrapper-5">אחר</div>
        </button>
        <button
          className="overlap-2"
          type='button'
          onClick={() => handleClick(1)}>
          <div className="mdi-gun">
            <img className="img" alt="Subtract" src={gun} />
          </div>
          <div className="text-wrapper-4">נשק חם</div>
        </button>
        <button
          type="button"
          className="overlap-3"
          onClick={() => handleClick(3)} >
          <img className="game-icons-mailed" alt="Game icons mailed" src={fist} />
          <div className="text-wrapper-6">אלימות</div>
        </button>

        <Location gpsLocation={gpsLocation} />
        <div className='alert-wrapper' />
        <img src={alertheader} className="alert-header" alt="alert" />
      </div>
    </div>
  );
};
