import { useEffect } from "react"
import '../styles/pages/InitialLogoScreen.css'
import Loader from "../components/Loader";


const InitialLogoScreen = () => {


  return (
    <div className="logo-screen-wrapper">
        <img 
        className="shomrey-logo"
        src="/logo-magen.png" 
        alt="shomrey-logo"/>
        <div className="logo-screen-spinner">
            <Loader />
        </div>
    </div>
  )
}

export default InitialLogoScreen