import { useEffect } from "react";
import Loader from "../Loader";
import "../../styles/CreateUser/LoadingPage.css";
import Progress from "../../icons/Group3.svg";

const CALoading = ({ onButtonClick, isUpdate }) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      // localStorageService.delete();
      // axiosInstance.disableAuthHeader();
      onButtonClick();
    }, 1000);

    return () => clearTimeout(timer);
  }, [onButtonClick]); 

  return (
    <div className="cu-loading">
      <div className="div">
        <div className="rectangle" />
        <img style={{opacity: isUpdate?0:1}} className="progress-one" src={Progress} alt="finish" />
        <div className="cu-loading__container">
          <div className="cu-loading__text">{isUpdate? "מעדכן פרטים": "יוצר מנהל אזור"}</div>
          <div className="text-wrapper-2">נא להמתין...</div>
          <Loader className="component" property1="loader-1" />
        </div>      
      </div>
    </div>
  );
};

export default CALoading;
