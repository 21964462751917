import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import CUStepOne from "../components/CreateUser/CUStepOne";
import CUStepTwo from "../components/CreateUser/CUStepTwo";
import CULoading from "../components/CreateUser/CULoading";
import CUSuccess from "../components/CreateUser/CUSuccess";
import axiosInstance from "../utils/axiosService";
import localStorageService from "../utils/localStorageService";
import UserContext from "../utils/UserContext";

function CreateUser() {
  const [step, setStep] = useState(1);
  const [areas, setAreas] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [areaName, setAreaName] = useState("");
  const {setUser, user} = useContext(UserContext);

  const [idFile, setIdFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const { uuid, areaUuid } = useParams();

  const handleButtonClick = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const uploadProfilePhoto = async () => {
    try {
      const formData = new FormData();
      formData.append("image", profilePhoto);
      axiosInstance.enableAuthHeader();
      const response = await axiosInstance.put("/api/user/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      return response.data;
    } catch (error) {
      // console.error("Error uploading the image: ", error);
    }
  };

  const uploadID = async () => {
    try {
      const formData = new FormData();
      formData.append("image", idFile);
      axiosInstance.enableAuthHeader();
      const response = await axiosInstance.put("/api/user/idPhoto", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      return response.data;
    } catch (error) {
      // console.error("Error uploading the image:", error);
    }
  };

  const submitRegistration = async () => {
    try {
      axiosInstance.disableAuthHeader();
      const tempToken = localStorageService.get("token", "tempToken");
      const item = areas.find((obj) => obj.description === areaName || obj.id === Number(areaName));
      const requestBodyNoInviter = item && {
        areaUuid: item.uuid,
        firstName: firstName,
        lastName: lastName,
        token: tempToken,
      };

      let url = "/api/reguser/register/noInviter2";
      let body = requestBodyNoInviter;
      const response = await axiosInstance.post(url, body);

      console.log("Registration successful:", response.data);
      localStorageService.store(response.data);

      axiosInstance.enableAuthHeader();
      const userDetails = await axiosInstance.get("/api/user/details");
      setUser(userDetails.data);
      localStorageService.store(userDetails.data.role, "role");
      localStorageService.store(userDetails.data.uuid, "uuid");
      handleButtonClick();
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  const loadAndSubmitAll = async() => {
    setIsLoading(true);
    handleButtonClick();
    try{
      await submitRegistration();
      if(!!idFile) await uploadID();
      if(!!profilePhoto) await uploadProfilePhoto()
      axiosInstance.enableAuthHeader();
      const userDetails = await axiosInstance.get("/api/user/details");
      setUser(userDetails.data)
      localStorageService.store(userDetails.data.role, "role");
      localStorageService.store(userDetails.data.uuid, "uuid");
      setIsLoading(false);
    }
    catch(err){ setIsLoading(false); }
  }

  return (
    <>

      {step === 1 && (
        <CUStepOne
          handleButtonClick={handleButtonClick}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          areas={areas}
          setAreas={setAreas}
          setAreaName={setAreaName}
          areaName={areaName}
          uuid={uuid}
        />
      )}
      {step === 2 && (
        <CUStepTwo
          setStep={setStep}
          idFile={idFile} 
          profilePhoto={profilePhoto}
          setProfilePhoto={setProfilePhoto}
          setIdFile={setIdFile}
          onButtonClick={loadAndSubmitAll}
        />
      )}
      {step === 3 && <CULoading 
        setSuccess={handleButtonClick} 
        loading={loading} />}
      {step === 4 && <CUSuccess />}
    </>
  );
}

export default CreateUser;
