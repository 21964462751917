

export const filterAndSwitchLanguagesFromServer = (assistantData) => {
    const userLan = [
        assistantData["amharicSpeaker"],
        assistantData["arabicSpeaker"],
        assistantData["englishSpeaker"],
        assistantData["frenchSpeaker"],
        assistantData["russianSpeaker"],
    ]
    .map((lan,idx) => lan? idx+1:null)
    .filter(lan => !!lan)
    return userLan;
}

export const filterAndSwitchTechAbilitiesFromServer = (data) => {
    const abilities = [
        data["contactWithSecurityForces"]? "contactWithSecurityForces": null,
        data["hasDrone"]? "drone": null,
        data["intelligenceGatheringAbilities"]? "intelgence": null,
        data["techAbilities"]? "techHigh": null,
        data["warRoomLeadAbilities"]? "complecited": null,
    ].filter(ability => !!ability);
    return abilities;
}

export const filterAndSwitchManagementAbilitiesFromServer = (data) => {
    const abilities = [
        data["abilityToLead"]? "abilityToLead": null,
        data["volunteersManagementAbilities"]? "noAlotOfKnoldge": null
    ].filter(ability => !!ability);
    return abilities
}
