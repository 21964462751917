import "../styles/Button.css";

const Button = ({ className, textButton, onClick, disabled, width, height, type }) => {
  const buttonClass = `button ${className} ${disabled ? 'button-disable' : ''}`;
  const buttonStyle = {
    width: width,
    height: height,
  };

  return (
    <button 
      onClick={onClick} 
      className={buttonClass}
      //disabled={disabled}
      style={buttonStyle}
      type={type}
    >
      {textButton}
    </button>
  );
};

export default Button;
