import React from "react";
import '../../../styles/AdminPanel/UserDetailsTraining.css'

const Frame = ({user}) => {

  const details = [
    {title: "יכולת טכנולוגית גבוהה", value: user.techAbilities? "יש": "אין"},
    {title: "ניסיון מודיעיני", value: user.intelligenceGatheringAbilities? "יש": "אין"},
    {title: "ניסיון בחמ\"ל", value: user.warRoomLeadAbilities? "יש": "אין"},
    {title: "קשר עם גורמי ביטחון", value: user.contactWithSecurityForces? "יש": "אין"},
    {title: "בעל רחפן", value: user.hasDrone? "כן": "לא"},
    {title: "יכולת לפקד על צוות", value: user.abilityToLead? "יש": "אין"},
    {title: "ניסיון בניהול מתנדבים", value: user.volunteersManagementAbilities? "יש": "אין"},
    {title: "החזקת רכב", value: user.vehicleOwnership},
    {title: "הכשרה רפואית", value: user.medicalAbilities},
    {title: "רמת כושר גופני", value: user.fitnessLevel? user.fitnessLevel: "אין מידע"},
  ]

  return (
    
<div className="Training-container">
      {details.map(line => {
        return (<div className="training-div" key={line.title}>
          <div className="training-text-wrapper">{line.value? line.value: "אין מידע"}</div>
          <div className="training-text-wrapper-2">{line.title}</div>
        </div>)
      })}
    </div>
  );
};

export default Frame