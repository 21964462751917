import Input from "../Input";
import Button from "../Button";
import "../../styles/InviteAssistant/IAStepOne.css";
import { useState } from "react";

import {isPhoneNumber,
  isInputEmpty,
    } from '../../components/validation/inputValidation'


const IAStepOne = ({ phone, setPhone, handleButtonClick }) => {

  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");


  const validate = (e) => {
    e.preventDefault();

    setErrorPhoneNumber("");

    if (isInputEmpty(phone)) {
      setErrorPhoneNumber("מספר פלאפון הוא שדה חובה");
      return;
    }
    else if ((!isPhoneNumber(phone))) {
      setErrorPhoneNumber("יש להקיש מספר פלאפון תקין");
      return;
    }

    setErrorPhoneNumber("");
    handleButtonClick();

  };


  return (
    <div className="screen-one">
      <div className="div-2">
        <Input
          className="component-22"
          divClassName="component-instance"
          property1="default"
          text="מספר טלפון"
          inputValue={phone}
          setInputValue={setPhone}
        />
        <div className="error-message" style={{ color: 'red' }}>{errorPhoneNumber}</div>

        <div className="text-wrapper-2">הזמנת סייען</div>
        <p className="p">כאן אפשר להזמין משתמש חדש לאפליקציה</p>
        <div className="group">
          <div className="overlap">
            <Button onClick={validate}
              textButton={"להזמנה"}
              className={"button-instance text-element-class"}
            />
          </div>
        </div>
        <div className="rectangle" />
      </div>
    </div>
  );
};

export default IAStepOne;
