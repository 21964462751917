import IAStepOne from "../components/InviteAssistant/IAStepOne";
import IAStepTwo from "../components/InviteAssistant/IAStepTwo";
import { useState } from "react";

export default function InviteAssistant() {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");

  const handleButtonClick = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const sendRequest = () => {
  };

  return (
    <>
      {step === 1 && (
        <IAStepOne
          handleButtonClick={handleButtonClick}
          phone={phone}
          setPhone={setPhone}
        />
      )}
      {step === 2 && <IAStepTwo phone={phone} sendRequest={sendRequest} />}
    </>
  );
}
