import { ButtonApproveChanges } from '../../AdminPanel/AdminPanelComponents/ButtonApproveChanges';
import { useEffect, useState } from 'react'
import axiosInstance from '../../../utils/axiosService';
import Select from "../../Select";
import './EditEditionalSkillsDetails.css'

const EditEdittionalSkillsDetails = ({user, submitUserUpdate}) => {

  const [userToEdit, setUserToEdit] = useState({...user});

  const [rankData, setRankData] = useState([]);
  const [trainingData, setTrainingData] = useState([]);
  const [miluimData, setMiluimData] = useState([]);
  const [weaponData, setWeaponData] = useState([]);

  const [selectedTraining, setSelectedTraining] = useState(0);
  const [selectedMiluim, setSelectedMiluim] = useState(0);
  const [selectedRank, setSelectedRank] = useState([]);
  const [shiftAvailability, setShiftAvailability] = useState(false);
  const [selectedWeapon, setSelectedWeapon] = useState(0);

  const title = "יכולות נוספות";

  const selectInputs = [
    { 
      items: trainingData, 
      selectedItems: selectedTraining, 
      setSelectedItems: setSelectedTraining,
      label: "הכשרה צבאית",
      serverkey: "militaryTrainingId"
    },
    { 
        items: rankData, 
        selectedItems: selectedRank, 
        setSelectedItems: setSelectedRank,
        label: "דרגה צבאית",
        serverkey: "militaryRankId"
    },
    { 
      items: miluimData, 
      selectedItems: selectedMiluim, 
      setSelectedItems: setSelectedMiluim,
      label: "שירות מילואים",
      serverkey: "miluimStatusId"
    },
    { 
        items: weaponData, 
        selectedItems: selectedWeapon, 
        setSelectedItems: setSelectedWeapon,
        label: "נשק",
        serverkey: "weaponStatusId"
    },
  ]

  const setInitialSelects = () => {
    selectInputs.forEach(s => s["setSelectedItems"](user[s["serverkey"]]) )
    setShiftAvailability(!!user["shiftAvailability"])
  }

  const onSubmit = () => { 
    const updatedUser = {...userToEdit};
    selectInputs.forEach(inp => { 
        updatedUser[inp.serverkey] = inp.selectedItems
    })
    updatedUser["shiftAvailability"] = shiftAvailability;
    submitUserUpdate(updatedUser);
  };

  //rank
  useEffect(() => {
    const fetchMilitaryRank = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/militaryRank');
        const MRD = optionRanks(response.data)
        setRankData(MRD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optionRanks = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMilitaryRank();
  }, []);
  //training
  useEffect(() => {
    const fetchMilitaryTraining = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/militaryTraining');
        const MTD = optiontTraining(response.data)
        setTrainingData(MTD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optiontTraining = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMilitaryTraining();
  }, []);
  //miluim
  useEffect(() => {
    const fetchMiluimStatus = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/miluimStatus');
        const MSD = optionMiluimStatus(response.data)
        setMiluimData(MSD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optionMiluimStatus = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchMiluimStatus();
  }, []);
  //weapon
  useEffect(() => {
    const fetchWeaponStatus = async () => {
      try {
        axiosInstance.disableAuthHeader();
        const response = await axiosInstance.get('/api/lists/weaponStatus');
        const WSD = optionWeaponStatus(response.data)
        setWeaponData(WSD)
      } catch (error) {
        // console.error('Failed to fetch vehicle ownership:', error);
      }
    };
    const optionWeaponStatus = (dataArray) => {
      return dataArray.map(item => ({ id: item.id, description: item.description }));
    }
    fetchWeaponStatus();
  }, []);

  useEffect(() => {setInitialSelects()}, [])

  return (
    <section className="read-edit-container">
    <div className="overlap">
    <h2 style={{color:"white", textAlign: "end",width:"100%"}}>{title}</h2>

    </div>
    <div className='read-edit-form-wrapper' style={{alignItems: "center"}}>
        <div style={{margin:"20px auto", minWidth:"200px"}}>
          {selectInputs.map((input) => <Select
            key={input.label}
            label={input.label}
            options={input.items}
            selectedOption={input.selectedItems}
            setSelectedOption={input.setSelectedItems}
          />)}
        </div>
        <div className="shiftAvailability-wrapper">
          <div>זמינות למשמרות</div>
          <div className="shiftAvailability-inputs-wrapper">
            <div className="shiftAvailability-input">
              <label>זמין</label>
              <input type="checkbox"
                checked={shiftAvailability}
                onChange={() => setShiftAvailability(!shiftAvailability)}
              />
            </div>
            <div className="shiftAvailability-input unavailable">
              <label>לא זמין</label>
              <input type="checkbox"
                checked={!shiftAvailability}
                onChange={() => setShiftAvailability(!shiftAvailability)}
              />
            </div>
          </div>
        </div>
    <ButtonApproveChanges style={{maxWidth:"80%"}} onClick={onSubmit}/>
    </div>
  </section>
  )
}

export default EditEdittionalSkillsDetails