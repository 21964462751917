import NewMember from "./NewMember";
import { useState } from "react";
import Loader from "../../Loader";
import "../../../styles/AdminPanel/PropertyDefaultWrapper.css";

const PropertyDefaultWrapper = ({
  property1,
  approveUser,
  friend,
  className,
}) => {
  const [loading, setLoading] = useState(false);

  const handleApprove = async () => {
    setLoading(true);
    await approveUser(friend.phone, friend.uuid);
    setLoading(false);
  };

  if (loading) {
    return (
      <div style={{"text-align": 'center', width:"100%"}}>
        <Loader newLoader="smaller-loader" />
        <p>...מאשר משתמש אנא המתן</p>
      </div>
    );
  }

  return (
    <div
      className={`property-default-wrapper property-1-0-${property1} ${className}`}
    >
      <div className="swipe-2">
        <NewMember
          approveUser={handleApprove}
          friend={friend}
          avatar={
            property1 === "delete"
              ? "/img/avatar-3.png"
              : property1 === "deleted-message"
              ? "/img/delete-icon-7.png"
              : "/img/avatar-6.png"
          }
          avatarClassName={`${
            property1 === "deleted-message" ? "class-6" : "class-7"
          }`}
          className={`${
            property1 === "delete"
              ? "class-8"
              : property1 === "deleted-message"
              ? "class-9"
              : "class-10"
          }`}
          frameClassName="new-member-2"
          textClassName="new-member-instance"
        />
        {/* {icon} */}
      </div>
    </div>
  );
};

export default PropertyDefaultWrapper;
