import React, {  useState, useEffect } from 'react';
import axiosInstance from './axiosService';
import localStorageService from './localStorageService';
import UserContext from './UserContext';


function UserProvider({ children }) {
  const [user, setUser] = useState(() => {
    // Load initial user state from local storage (if it exists)
    return localStorageService.get('user', "urm") || null;
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        axiosInstance.enableAuthHeader();
        const userDetails = await axiosInstance.get("/api/user/details");
        setUser(userDetails.data);
        localStorageService.store({ user: userDetails.data },"urm"); // Save user to local storage
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      } finally {
        setLoading(false);
      }
    }

    const token = localStorageService.get("token");
    if (token) {
      fetchUserInfo();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Save user state to local storage when it updates
    if (user) {
      localStorageService.store({ user });
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
