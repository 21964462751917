import MyImageSvg from '../assets/octicon_location-16.svg';
import { useContext, useEffect, useState, } from 'react';
import usr from '../assets/icons/material-symbols_admin-panel-settings.svg';
import { Link, useNavigate } from 'react-router-dom';
import usr_add from '../assets/icons/mdi_user-add.png';
import '../styles/PanicButton/PanicButton.css';
import Logout from './Logout/Logout';
import UserContext from '../utils/UserContext';
// import LocationUpdater from './LocationUpdater';
import EditIcon from "@mui/icons-material/Edit";

function PanicButton({ 
  handleButtonClick, 
  gpsLocation, 
  handleChangeInput, 
  manualAddressError,
  isSubmittingLocation,
  userLocation 
  }) {
  const { user } = useContext(UserContext);
  const [location, setLocation] = useState(userLocation);
  const LINK_TO_APP = encodeURIComponent('https://s3.eu-central-1.amazonaws.com/files.shomrey/shomrey.apk');
  const invitationText = `הזמנה למערכת שומרי הארץ, דרך המערכת ניתן לדווח על מצוקה ולהתנדב לעזרה באירוע ביטחוני, להורדת האפליקציה:%0a${LINK_TO_APP}%0a** האפליקציה בשלב הרצה לקראת כניסה לחנויות ולכן יש אזהרה בזמן ההתקנה`;
  const userRole = user.role;
  const navigate = useNavigate();

  useEffect(() => {
    setLocation(userLocation);
  }, [userLocation]);

  const handleLocationInput = (e) => {
    const { value } = e.target;
    setLocation(value);
  }

  const handleInputButtonClick = () => {
    handleChangeInput(location);
  }

  const openDetails = () => {
    navigate("/admin-panel/edit-user/" + user.uuid);
  };


  return (
    <div className="panic">
      <header className='panic-header'>
        <nav className="panic-rectangle1">
          <div className='panic-nav_container'>
            <div className='panic-link_container'>
              {(userRole === 'AREAADMIN') &&
                <span style={{marginRight: '30px', marginLeft: "-30px"}}>
                  <Link className='panic-nav_link' to="/admin-panel">
                    <img className='panic-admin-button' src={usr} alt="יוזר" />
                  </Link>
                </span>
              }
              <Logout className="panic-nav_link" />
            </div>
            {(userRole === 'USER' && user.approved) &&
                <div style={{minWidth:"40vw"}} className='panic-link_container'>
                  <EditIcon
                    fontSize="large"
                    style={{marginBottom:"10px"}}
                    className="admin-invite action-icon panic-nav_link"
                    onClick={openDetails}
                  />
                </div>
              }
            <div className='panic-link_container'>
              <Link className='panic-nav_link' to={`https://wa.me?text=${invitationText}`}>
                <img className='panic-usr-add' src={usr_add} alt="יוזר" onClick={() => { }} />
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <div className="panic-rectangle2" />
      <main className='panic-main'>
        <div className='outerContainer'>
          <div className='inerContainer'>
            <div className='panic-button' onClick={handleButtonClick}>
              <button className='panic-circle' >לחצן <br />מצוקה</button>
              <div className='panicPulse' ></div>
              <div className='panicPulse' ></div>
              <div className='panicPulse' ></div>
              <div className='panicPulse' ></div>
            </div>
           
          </div>
        </div>

        <section className='panic_location-container'>
          <div className="panic-gps-location">
            <p className="panic-location-text">{gpsLocation}</p>
            <img className="panic-icon-location" src={MyImageSvg} alt="תיאור" />
          </div>
          <img className="panic-line" src={require('../assets/line.png')} alt="תמונה" /><br /><br /><br />
          <div className="panic-input-area">
            <label className="panic-input-text">?כתובת לא נכונה</label>
            <div className="panic-input-wrapper">
              <input
                className="panic-input placeholder-text-element-class"
                type="text"
                placeholder="הזן מיקום"
                value={location}
                onChange={handleLocationInput} />
              <button
                className="panic-input-btn"
                onClick={handleInputButtonClick}>{isSubmittingLocation?"מחפש":"אישור"}</button>
            </div>
            {manualAddressError &&
              <span className='location-error-text'>{manualAddressError}</span>
            }
            <span>דוגמא: ארלוזורוב 21, תל אביב</span>
            <img className="panic-image" src={require('../assets/logo-magen.png')} alt="תמונה" />
          </div>
        </section>
      </main>

    </div>
  );
}

export default PanicButton;
