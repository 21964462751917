import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import './Logout.css'

const logoutFromUser = () =>{
    localStorage.clear();

}

export default function Logout() {
return(
    <Link className='logout action-icon' onClick={() =>logoutFromUser()} to="/login">
        <LogoutIcon sx={{ fontSize:40}} className='logout-img' />
    </Link>
);
}