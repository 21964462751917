import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SlideUpModal from "./SlideUpModal";  // Adjust the import path accordingly

const SlideUpModalWrapper = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const handleOnClose = () => {
    navigate('/admin-panel');
  };

  return <SlideUpModal onClose={handleOnClose} isOpen={true} uuid={uuid} />;
};

export default SlideUpModalWrapper;
