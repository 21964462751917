import '../../styles/InviteAdminPopup/InviteAdminPopup_Approval.css';
import axiosInstance from '../../utils/axiosService';


export default function InviteAdminPopup_Approval({ phone, area, setIsPopupOpen, setIsApprovalStage }) {
  
  const DOMAIN = process.env.NODE_ENV === "development"? 'http://localhost:3000': process.env.REACT_APP_DOMAIN_URL;
  const inviteText = `הוזמנת להיות מנהל אזור ${area} במערכת שומרי הארץ. ניתן להמשיך את ההרשמה בלינק הזה: ${DOMAIN}/admin-registration`;
  const handleApprove = () => {
    axiosInstance.enableAuthHeader();
    axiosInstance.post('/api/sysadmin/inviteAreaAdmin', 
    {
      "areaDescription": area,
      "phone": phone
    })
    .then((res) => {
      if(phone[0] === '0') {
        phone = '+972' + phone.slice(1);
      }
      setIsPopupOpen(false);
      window.open(`https://wa.me/${phone}?text=${inviteText}`)
    })
    .catch((err) => {console.log(err)});
    

  }
  return (
    <>
      <p className="invite-admin_approve-title">האם מספר הטלפון שהזנת נכון?</p>
      <p className="invite-admin_number">{phone}</p>
      <p className="invite-admin_fix-number" onClick={() => setIsApprovalStage(false)}>תיקון מספר </p>
      <button
        type="button"
        className="invite-admin_button invite-admin_button_approval"
        onClick={handleApprove}>אישור</button>
    </>
  )
}