import UserDetailsPrivate from "../AdminPanel/AdminPanelComponents/UserDetailsPrivate.js";
import UserDetailsTraining from "../AdminPanel/AdminPanelComponents/UserDetailsTraining.js";
import { UserDetailsAdditionalSkills } from "../AdminPanel/AdminPanelComponents/UserDetailsAdditionalSkills";
import { ButtonAreaManager } from "../AdminPanel/AdminPanelComponents/ButtonAreaManager";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IsModal } from "../AdminPanel/AdminPanelComponents/IsModal";
import axiosInstance from "../../utils/axiosService";
import SlideUpEditModal from "../AdminPanel/AdminPanelComponents/DetailsModal/SlideUpEditModal.js";
import "../../styles/AdminPanel/ReadEditUser.css";
import BackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "../Avatar.js";
import EditPrivateDetails from "./ReadEditUser/EditPrivateDetails.js";
import EditTrainingDetails from "./ReadEditUser/EditTrainingDetails.js";
import EditEdittionalSkillsDetails from "./ReadEditUser/EditEdittionalSkillsDetails.js";
import UserContext from "../../utils/UserContext.js";


const ReadEditUser = ({ uuid, readOnlyMode = false, onClose }) => {
  const loggedUser = useContext(UserContext);
  const [user, setUser] = useState({});
  const { uuid: uuidFromUrl } = useParams();
  const navigate = useNavigate()

  const [isButtonManagerClicked, setIsButtonManagerIsClicked] = useState(false);

  /*EDIT USER*/
  const [isEditPrivateClicked, setIsEditPrivateClicked] = useState(false);
  const [isEditAdditionalskillsClicked, setIsEditAdditionalskillsClicked] = useState(false);
  const [isEditTrainingClicked, setIsEditTrainingClicked] = useState(false);

  useEffect(() => {
    getUserData();
  }, [uuidFromUrl ]);

  const getMyUserData = async () => {
    try {
      axiosInstance.enableAuthHeader();
      const res = await axiosInstance.get("/api/user/details");
      console.log(res.data);
      setUser(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  const getUserDataFromPending = async (urlUuid) => {
    try {
      axiosInstance.enableAuthHeader();
      // /api/areaadmin/pendingUsers
      let penUser = await axiosInstance.get(`/api/areaadmin/pendingUsers`)
      .then(res => res.data.filter(pUser => pUser.uuid === urlUuid))
      penUser = penUser.length > 0? penUser[0]: {};
      console.log('pending user details, not my details: ', penUser)
      setUser(penUser);
    } catch (error) {
      console.log(error);
    }
  }

  const getUserDataFromDetails = async(urlUuid) => {
    try {
      axiosInstance.enableAuthHeader();
      const res = await axiosInstance.get(
        `/api/reguser/fullDetails/${urlUuid}`
      );
      console.log('users details, not my details: ', res.data)
      setUser(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getUserData = async () => {
    const withUuid = uuid || uuidFromUrl;
    
    // case 1: unLogged user:
    if(!loggedUser || !loggedUser.user) return getMyUserData()
    // case 2: admin access details from panel (no uuid):
    else if(!uuidFromUrl && loggedUser.user.role === "AREAADMIN") return getUserDataFromDetails(withUuid)
    // case 3: uuid dont metch logged user when aprove logged:
    else if(!!withUuid && loggedUser.user.canApprove && (loggedUser.user?.uuid !== withUuid) ) return getUserDataFromDetails(withUuid);
    else return getMyUserData();
    
  };

  const SubmitUserDetails = async (updatedUser) => {
    try {
      axiosInstance.enableAuthHeader();
      await axiosInstance.put(`/api/user/guardian`, updatedUser)
        .then(res => setUser(res.data))
        .catch((err) => {})
        .finally(clearAllmodals)
    } catch (error) { console.log(error); }
  };

  function clearAllmodals(){
    setIsEditPrivateClicked(false);
    setIsEditTrainingClicked(false);
    setIsEditAdditionalskillsClicked(false);
  }

  function HandleClickButtonAreaManager() {
    setIsButtonManagerIsClicked(!isButtonManagerClicked);
  }

  function handleClickEditPrivate() {
    setIsEditPrivateClicked(!isEditPrivateClicked);
  }

  function handleClickEditTraining() {
    setIsEditTrainingClicked(!isEditTrainingClicked);
  }

  const handleCloseWhenNoClose = () => {
    if(!loggedUser.user.canApprove) return navigate(-1);
    if(onClose){
      onClose();
    }
    else{
      navigate(-1)
    }
  }

  function handleClickEditAdditionalSkills() {
    setIsEditAdditionalskillsClicked(!isEditAdditionalskillsClicked);
  }

  if (user && isEditAdditionalskillsClicked) {
    return (
      <SlideUpEditModal
        isActive={isEditAdditionalskillsClicked}
        toggle={handleClickEditAdditionalSkills}
      >
        <EditEdittionalSkillsDetails 
          setUser={setUser} 
          submitUserUpdate={SubmitUserDetails}
          user={user} />
      </SlideUpEditModal>
    );
  }

  if (user && isEditPrivateClicked) {
    return (
      <SlideUpEditModal
        isActive={isEditPrivateClicked}
        toggle={handleClickEditPrivate}
      >
        <EditPrivateDetails 
          setUser={setUser} 
          submitUserUpdate={SubmitUserDetails}
          user={user}/>
      </SlideUpEditModal>
    );
  }

  if (user && isEditTrainingClicked) {
    return (
      <SlideUpEditModal
        isActive={isEditTrainingClicked}
        toggle={handleClickEditTraining}
      >
       <EditTrainingDetails           
          setUser={setUser} 
          submitUserUpdate={SubmitUserDetails}
          user={user}/> 
      </SlideUpEditModal>
      // <h1>sdfkjh</h1>
    );
  }

  // if (user && isEditPrivateClicked) {
  //   <SlideUpEditModal
  //     isActive={isEditPrivateClicked}
  //     toggle={handleClickEditPrivate}
  //   ></SlideUpEditModal>;
  // }

  if (user) {
    return (
      <>
        <IsModal
          onClick={HandleClickButtonAreaManager}
          openModal={isButtonManagerClicked}
        ></IsModal>

        <div className="read-edit-container">
          <div
            className="overlap"
            style={{
              height: "80px",
              justifyContent: "space-between",
              position: "fixed",
              top: '0',
              zIndex: '3000',
              flexDirection: 'row',
              alignItems: 'center',
              boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
              padding: '0 20px',
              boxSizing: 'border-box'
            }}
          >
            <BackIcon
              fontSize="large"
              className="admin-invite action-icon"
              onClick={handleCloseWhenNoClose}
              style={{ fill: 'white' }}
            />
            {readOnlyMode && (
              <ButtonAreaManager
                user={user}
                setUser={setUser}
              />
            )}
            <Avatar user={user} />
          </div>

          <section className="scrollArea" style={{width:'100%', margin: "30px auto"}}>
            <main className="main">
              <section className="details-section">
                <header className="section-header">
                  <h2 className="h2">
                    פרטים אישיים
                  </h2>
                  {!readOnlyMode && (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={handleClickEditPrivate}
                    >
                      <div style={{ marginLeft: "10px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                            fill="#012C55"
                          />
                        </svg>
                      </div>
                    </div>
                  )}

                </header>
                <div
                  style={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "10px",
                    padding: "20px 20px",
                  }}
                >
                  <UserDetailsPrivate user={user} />
                </div>
              </section>

              <section  className="details-section">
                <header className="section-header">
                  <h2 className="h2">
                    הכשרות
                  </h2>
                  {!readOnlyMode && (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={handleClickEditTraining}
                    >
                      <div style={{ marginLeft: "10px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                            fill="#012C55"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </header>
                <div
                  style={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "10px",
                    padding: "20px 20px",
                  }}
                >
                  <UserDetailsTraining user={user} />
                </div>
              </section>

              <section  className="details-section">
                <header className="section-header">
                  <h2 className="h2">
                    יכולות נוספות
                  </h2>
                  {!readOnlyMode && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{ marginLeft: "10px" }}
                        onClick={handleClickEditAdditionalSkills}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                            fill="#012C55"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </header>
                <div
                  style={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "10px",
                    padding: "20px 20px",
                  }}
                >
                  <UserDetailsAdditionalSkills user={user} />
                </div>
              </section>
            </main>
          </section>
        </div>
      </>
    );
  }
};

export default ReadEditUser;