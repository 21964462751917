import { useEffect, useContext, useState } from 'react'
import UserContext from '../utils/UserContext'
import { useNavigate } from "react-router-dom";
import '../styles/pages/WaitingForApprove.css';
import logo from '../assets/logo-magen.png';
import '../styles/Emergency.css';
import CAStepTwo from "../components/CreateAdmin/CAStepTwo";
import CALoading from "../components/CreateAdmin/CALoading";
import Logout from '../components/Logout/Logout';
import axiosInstance from '../utils/axiosService';
import Button from '../components/Button';
const WaitngForApprove = () => {
  const { user, setUser } = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [profileImage, setProfileImage] = useState(null);
  const [IDImage, setIDImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
      if(!!user && user.approved){
          navigate("/")
        }
  },[user, navigate])

  const saveUserAndProcceed = async() => {
    setStep(3);
    try{
      axiosInstance.enableAuthHeader();
      const userDetails = await axiosInstance.get("/api/user/details");
      console.log("User details:", userDetails.data);
      setUser(userDetails.data);
    }
    catch(err){}
  }

  const editGuardianNavigation = () => {
    navigate('/create-assistant')
  }



  return (
    <>
      {step === 1 &&
      <div className="waiting">
        <div className="waiting__wrapper">
        <header className="sysAdmin_header">
            <nav className="sysAdmin-rectangle2" >
              <div className="sysAdmin-nav_container">
                <Logout className="panic-nav_link" />
              </div>
            </nav>
          </header>
          <div className="waiting__content">
            <img className="waiting__logo" alt="logo" src={logo} />
            <p className="waiting__text">ממתין לאישור מנהל</p>
            <p className="waiting__text-small">נעדכן אותך כשתאושר</p>
          </div>
          <div className='waiting-buttons-wrapper'>
            <Button
              className="button-active-instance text-element-class"
              textButton={"עדכן תמונות ת.ז. ופרופיל"}
              onClick={() => setStep(2)}
            />
            <Button
              className="button-active-instance text-element-class"
              textButton={user && user.isGuardian? "עדכן פרטי שומר": "אני רוצה להיות שומר"}
              onClick={editGuardianNavigation}
            />
          </div>
        </div>
      </div>}
      {step === 2 && 
        <CAStepTwo 
          onButtonClick={saveUserAndProcceed}
          isUpdate={true}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          IDImage={IDImage}
          cancelUpdate={() => setStep(1)}
          setIDImage={setIDImage}
        />}
      {step === 3 && <CALoading 
        onButtonClick={() => setStep(1)} 
        isUpdate={true}
      />}
    </>
  )
}

export default WaitngForApprove