import PropTypes from "prop-types";
import React from "react";
import "../../../styles/AdminPanel/Card.css";
import knife from '../../../icons/knife.svg';
import gun from '../../../icons/gun.svg';
import fist from '../../../icons/fist.svg';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const Card = ({
  className,
  frameClassName,
  divClassName,
  textClassName,
  subtractClassName,
  subtract = "/img/subtract-4.svg",
  frameClassNameOverride,
  text = "מעל 10",
  text1 = "דרוש טיפול",
  address,
  areaDescription,
  createdAt,
  eventType
}) => {

  return (
    <div className={`card ${className}`}>
      <div className={`frame ${frameClassName}`}>
        <div className={` ${frameClassNameOverride}`}>
          <div className="element">{text}</div>
        </div>
        <div className={` ${divClassName}`}>{text1}</div>
      </div>
      <div className={`text ${textClassName}`}>
        <div className="div">{areaDescription}</div>
        <p className="p">{address}</p>
        <p className="p">{createdAt}</p>
      </div>

      {eventType === 4
        ? <QuestionMarkIcon className="subtract" />
        : <img
          className={`subtract ${subtractClassName}`}
          alt="Subtract"
          src={eventType === 1 ? gun : eventType === 2 ? knife : fist} />}
    </div>
  );
};

Card.propTypes = {
  subtract: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
};


export default Card