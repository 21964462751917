import Input from "../Input";
import Button from "../Button";
import Progress from "../../icons/Group1.png";
import "../../styles/CreateUser/CUStepOne.css";
import React, { useState } from "react";

import {
  isInputEmpty,
  validateAlphabetic,
  minChracters,
  leastOneCapitalLetter,
  leastOneLowercaseLetter,
} from "../../components/validation/inputValidation";

const CAStepOne = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  password,
  setPassword,
  verifyPassword,
  setVerifyPassword,
  submitRegistertion,
}) => {


  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verifyPasswordError, setVerifyPasswordError] = useState("");

  const handleValidationAndSubmit = (e) => {
    let validationPassed = true;
    
    if (validationPassed) {
      setFirstNameError("");
      setLastNameError("");
      setPasswordError("");
      setVerifyPasswordError("");
    }
    // --------------first name validation-----------------------------
    if (isInputEmpty(firstName)) {
      setFirstNameError("שדה זה הוא חובה");
      validationPassed = false;
    } else {
      if (!validateAlphabetic(firstName)) {
        setFirstNameError("שדה זה מכיל רק אותיות");
        validationPassed = false;
      }
    }

    // --------------last name validation-----------------------------
    if (isInputEmpty(lastName)) {
      setLastNameError("שדה זה הוא חובה");
      validationPassed = false;
    } else {
      if (!validateAlphabetic(lastName)) {
        setLastNameError("שדה זה מכיל רק אותיות");
        validationPassed = false;
      }
    }

    // ------------password1 validation-------------------------------------
    if (isInputEmpty(password)) {
      setPasswordError("שדה זה הוא חובה");
      validationPassed = false;
    } else {
      if(!handlePasswordValidationErrors()) validationPassed = false;
    }

    // ------------password2 validation-------------------------------------
    if (isInputEmpty(verifyPassword)) {
      setVerifyPasswordError("שדה זה הוא חובה");
      validationPassed = false;
    } else {
      if(!handleVerifyPasswordValidationErrors()) validationPassed = false
    }
    
    
    if (validationPassed) {
      setFirstNameError("");
      setLastNameError("");
      setPasswordError("");
      setVerifyPasswordError("");
      return true;
    }
  };

  const handlePasswordValidationErrors = () => {
    if(password.length === 0){
      setPasswordError("");
      return false;
    }
    if (!leastOneCapitalLetter(password)) {
      setPasswordError("חייב להכיל אות גדולה אחת");
      return false;
    }
    if (!leastOneLowercaseLetter(password)) {
      setPasswordError("חייב להכיל אות קטנה אחת");
      return false;
    }
    if (!minChracters(password)) {
      setPasswordError("חייב להכיל לפחות 6 תווים");
      return false;
    }

    setPasswordError("")
    return true;
  }

  const handleVerifyPasswordValidationErrors = () => {
      if(verifyPassword.length === 0){
        setVerifyPasswordError("");
        return false;
      }

      if (!leastOneCapitalLetter(verifyPassword)) {
        setVerifyPasswordError("חייב להכיל אות גדולה אחת");
        return false;
      }
      if (!leastOneLowercaseLetter(verifyPassword)) {
        setVerifyPasswordError("חייב להכיל אות קטנה אחת");
        return false;
      }
      if (verifyPassword !== password) {
        setVerifyPasswordError("סיסמאות חייבות להיות זהות");
        return false;
      }
      if (!minChracters(verifyPassword)) {
        setVerifyPasswordError("חייב להכיל לפחות 6 תווים");
        return false;
      }
    setVerifyPasswordError("");
    return true;
  }

  React.useEffect(() => {handlePasswordValidationErrors(); }, [password]);
  React.useEffect(() => {handleVerifyPasswordValidationErrors(); }, [verifyPassword, password]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidationAndSubmit();
    if (!isValid) return;
    submitRegistertion(e);
  };


  return (
    <div className="cu-one">
      <div className="div-2">
        <div className="rectangle" />
        <img className="progress-one" src={Progress} alt="" />
        <div className="text-wrapper-2">יצירת מנהל אזור</div>
        
        <form className="frame" onSubmit={handleSubmit}>
          <Input
            className="design-component-instance-node"
            divClassName="component-22-instance"
            property1="variant-2"
            text="שם פרטי"
            inputValue={firstName}
            setInputValue={setFirstName}
            //required={true}
          />
          <div className="error-message">{firstNameError}</div>

          <Input
            className="design-component-instance-node"
            divClassName="component-2"
            property1="variant-2"
            text="שם משפחה"
            inputValue={lastName}
            setInputValue={setLastName}
            //required={true}
          />
          <div className="error-message">{lastNameError}</div>

          <Input
            className="design-component-instance-node"
            divClassName="component-3"
            property1="variant-2"
            text="סיסמה"
            inputValue={password}
            setInputValue={setPassword}
            inputType="password"
            //required={true}
          />
          <div className="error-message">{passwordError}</div>

          <Input
            className="design-component-instance-node"
            divClassName="component-4"
            property1="variant-2"
            text="הקלד/י סיסמה שוב"
            inputValue={verifyPassword}
            setInputValue={setVerifyPassword}
            inputType="password"
            //required={true}
          />
          <div className="error-message">{verifyPasswordError}</div>

          <Button
             //onClick={submitRegistertion}
            textButton={"לשלב הבא"}
            className={"button-instance text-element-class"}
            type='submit'
          /> 
        </form>
      </div>
    </div>
  );
};

export default CAStepOne;
