import React from "react";

export const IsModal = ({ onClick, openModal }) => {


  const handleClick = (e) => {
    e.preventDefault();
       return onClick();
    
  }

  if (openModal === true) {
    return (
      <div onClick={handleClick} style={{
        position: 'absolute',
        width: '100vw',
        height: '130vh',
        backgroundColor: 'rgb(0, 0, 0, 0.6)',
        zIndex: '3'
        }}>
        
      </div>
    )

  } 
};
