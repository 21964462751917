import React from "react";
import ReadEditUser from "../../../CreateUser/ReadEditUser";
import "../../../../styles/AdminPanel/SlideUpModal.css";

const SlideUpModal = ({ isOpen, onClose, uuid, readOnlyMode = false }) => {
  if (uuid && isOpen) return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      {/* <button className="close-button" onClick={onClose}>
        &times;
      </button> */}
      <div className="modal-content">
        <ReadEditUser uuid={uuid} readOnlyMode={readOnlyMode} onClose={onClose}/>
      </div>
    </div>
  );
};

export default SlideUpModal;
