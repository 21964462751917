import Button from "../Button";
import Input from "../Input";
import "../../styles/InviteAssistant/IAStepTwo.css";

const IAStepTwo = ({ phone, sendRequest }) => {
  return (
    <div className="screen-two">
      <div className="div">
        <Input
          className="component-22"
          divClassName="component-instance"
          property1="default"
          text="מספר טלפון"
          inputValue={phone}
        />
        <div className="group">
          <div className="overlap-group">
            <Button
              onClick={sendRequest}
              textButton={"אישור"}
              className={"button-instance text-element-class"}
            />
          </div>
        </div>
        <div className="rectangle" />
        <p className="p">האם מספר הטלפון שהזנת נכון?</p>
      </div>
    </div>
  );
};

export default IAStepTwo;
