// interface Coordinates {
//     latitude: number;
//     longitude: number;
// }

export function calculateDistance(eventCoords, participantCoords) {
    if(!eventCoords || !eventCoords.latitude || !eventCoords.longitude 
    || !participantCoords || !participantCoords.latitude || !participantCoords.longitude){
        return -1;
    }
    const earthRadiusKm = 6371; // Radius of the Earth in kilometers

    const toRadians = (angle) => {
        return (angle * Math.PI) / 180;
    };

    const deltaLat = toRadians(participantCoords.latitude - eventCoords.latitude);
    const deltaLon = toRadians(participantCoords.longitude - eventCoords.longitude);

    const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(toRadians(eventCoords.latitude)) * Math.cos(toRadians(participantCoords.latitude)) *
        Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadiusKm * c;
    return distance;
}

// Example usage:
// const eventCoordinates = { latitude: 40.7128, longitude: -74.0060 }; 
// const participantCoordinates = { latitude: 41.8781, longitude: -87.6298 };

// const distance = calculateDistance(eventCoordinates, participantCoordinates);
// console.log(`Distance between event and participant: ${distance.toFixed(2)} km`);
