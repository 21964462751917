import { useState, useEffect } from "react";


function HomeScreen() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
      });
    }, []);
  
    const addToHomeScreen = () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("המשתמש הוסיף את האפליקציה למסך הבית");
          }
          setDeferredPrompt(null);
        });
      }
    };
    

  return (
    <div style={{textAlign: 'center', padding: '20px auto', flexDirection:"row", color:"#014886", fontSize:"18px"}}>
        <a  onClick={addToHomeScreen}>הוסף קיצור דרך למסך הבית</a>
      <link rel="manifest" href="/manifest.json" />
    </div>
  );
}

export default HomeScreen;