import '../../../styles/AdminPanel/ButtonApproveChanges.css'

export const ButtonApproveChanges = ({onClick }) => {
  return (
    <div className={`button-approve-changes text-element-class`} onClick={onClick}>
       {<p>{"שמירת שינויים"}</p>}
    </div>
  );
};


