import React from 'react'

const UserDetailLine = ({value, title}) => {
  return (
    <div className="div">
        <p style={{marginLeft: title === "מייל" && !!value?"20px": "0"}} className="p">
        <span className="text-wrapper-4"> {!!value? value: "אין מידע"}</span>
        </p>
        <div className="text-title-wrapper">{title}</div>
    </div>
  )
}

export default UserDetailLine