import {  useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosService";
import SendCode from "../components/PhoneVerification/SendCode";
import ResetPassword from "../components/PhoneVerification/RestPassword";
import localStorageService from "../utils/localStorageService";
import axiosService from "../utils/axiosService";
import UserContext from "../utils/UserContext";

export const ForgotPassword = () => {

    const [step, setStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const { setUser } = useContext(UserContext);
    const [forgetPasswordTitle, setForgetPassowrdTitle] = useState("הקוד לא הגיע? כאן מקבלים חדש")


    const [resetCode, setResetCode] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const navigate = useNavigate();


    const handleButtonClick = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const submitResetPassword = async() => {
        try{
            const tempToken = localStorageService.get("token","tempToken");
            const data = {
                "otp": resetCode,
                "password": password,
                "phone": phoneNumber,
                "token": tempToken
            };
            await axiosService.post("/api/resetPassword", data);
            localStorageService.delete("tempToken");
            await autoLogin()
        }
        catch(err){
            setResetCode("");
            setStep(1);
        }
    }

    const autoLogin = async () => {
        const data = {
            password,
            username: phoneNumber
        }
        const response = await axiosInstance.post("/api/authenticate", data);
        localStorageService.store(response.data);
        axiosInstance.enableAuthHeader();
        const userDetails = await axiosInstance.get("/api/user/details");
        setUser(userDetails.data);
        const userNotApproved = userDetails.data.approved === false;
        if (userNotApproved) {
            navigate("/waiting");
            return;
        }
        navigate("/");
    }

    const sendCodeAgain = async () => {
      const data = {
        phone: phoneNumber,
      };
  
      try {
        setForgetPassowrdTitle("...שולח קוד מחדש")
        const response = await axiosService.post(
          "/api/forgotPassword",
          data
        );
        
        const tempToken = response.data;
        localStorageService.store(tempToken, "tempToken")
      } catch (error) {
        console.log(error)
      } finally {
        setForgetPassowrdTitle("הקוד לא הגיע? כאן מקבלים חדש")
      }
    };


  return (
    <>
      {step === 1 && (
        <SendCode
          handleButtonClick={handleButtonClick}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          userName={""}
          resetPassword={true}
        />
      )}
      {step === 2 && (
        <ResetPassword
          sendCodeAgain={sendCodeAgain}
          forgetPasswordTitle={forgetPasswordTitle}
          password={password}
          setPassword={setPassword}
          verifyPassword={verifyPassword}
          setVerifyPassword={setVerifyPassword}
          resetCode={resetCode}
          setResetCode={setResetCode}
          handleButtonClick={submitResetPassword} />
      )}
    </>
  );
};

export default ForgotPassword;