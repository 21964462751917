import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import "../../styles/CreateUser/LoadingPage.css";
import Progress from "../../icons/Group3.svg";
import localStorageService from "../../utils/localStorageService";
import axiosInstance from "../../utils/axiosService";

const CULoading = ({ loading, setSuccess }) => {

  const [timerRuning, setTimerRuning] = useState(true);
  useEffect(() => {
    if(!loading && !timerRuning){ setSuccess() }
  },[loading, timerRuning])

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimerRuning(false)
    }, 1000)
    return clearTimeout(timer)
  }, [])

  return (
    <div className="cu-loading">
      <div className="div">
        <div className="rectangle" />
        <div className="progress-one">
            <img src={Progress} alt="שלב 2" />
          </div>
        <div className="cu-loading__container">
          <div className="cu-loading__text">יוצר משתמש</div>
          <div className="text-wrapper-2">נא להמתין...</div>
          <Loader className="component" property1="loader-1" />
        </div>      
      </div>
    </div>
  );
};

export default CULoading;
