import close from '../../assets/icons/close-icon.svg';
import '../../styles/InviteAdminPopup/InviteAdminPopup_Input.css';
import { useState } from 'react';

export default function InviteAdminPopup_Input({
  handleSetPhone,
  handleSetArea,
  phoneNumber,
  setIsPopupOpen,
  setIsApprovalStage }) {
  const [phone, setPhone] = useState(phoneNumber);
  const [area, setArea] = useState('');

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  }

  const handleChangeArea = (e) => {
    setArea(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSetPhone(phone);
    handleSetArea(area);
    setIsApprovalStage(true);
  }

  return (
    <>
      <img className="invite-admin_close" src={close} onClick={() => setIsPopupOpen(false)} alt='icon' />
      <p className="invite-admin_title">הזמנת מנהל איזור</p>
      <p className="invite-admin_subtitle">כאן אפשר להזמין משתמש חדש לאפליקציה</p>
      <form className="invite-admin_form" onSubmit={handleSubmit}>
        <input
          className="invite-admin_input"
          placeholder="מספר טלפון"
          type="tel"
          value={phone}
          onChange={handleChangePhone}
          minLength={10}
          pattern="\+?[0-9]{10,12}"
          required />
          <input
          className="invite-admin_input"
          placeholder="אזור"
          type="text"
          value={area}
          onChange={handleChangeArea}
          required />
        <button className="invite-admin_button" type="submit">להזמנה</button>
      </form>
    </>
  )
}