import axios from "axios";
import localStorageService from "./localStorageService";


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const setAuthHeader = () => {
  const token = localStorageService.get("token");
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

setAuthHeader();

axiosInstance.enableAuthHeader = () => {
  setAuthHeader();
};

axiosInstance.disableAuthHeader = () => {
  delete axiosInstance.defaults.headers.common["Authorization"];
};

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      localStorageService.delete();
      axiosInstance.disableAuthHeader();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
