import { useState, useRef, useEffect } from "react";
import Button from "../Button";
import uploadIcon from "../../assets/icons/upload.svg";
import Progress from "../../icons/progressBar2.svg";
import axiosInstance from "../../utils/axiosService";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import "../../styles/CreateUser/CUStepTwo.css";
import TakeIDPhotoModal from "../CreateUser/TakeIDPhotoModal";

const CAStepTwo = ({ 
  onButtonClick, 
  isUpdate, 
  cancelUpdate,
}) => {
  const [idFile, setIdFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoError, setProfilePhotoError] = useState("");
  const [idFileError, setIdFileError] = useState("");
  const [userHasClicked, setUserHasClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const streamRef = useRef(null);
  const [isIdModalOpen, setIsIdModalOpen] = useState(false)
  const buttonDisable = !idFile || !profilePhoto;

  const [
    // eslint-disable-next-line
    hasPhoto,
    setHasPhoto,
  ] = useState(false);
  const [takeAphoto, setTakeAphoto] = useState(false);

  const openIDModal = () => setIsIdModalOpen(true);
  const closeIDModal = () => setIsIdModalOpen(false);


  const getVideo = () => {
    setTakeAphoto(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        video.style.transform = "scaleX(-1)"; 

        const aspectRatio = video.videoWidth / video.videoHeight;
        const width = 200;
        const height = width / aspectRatio;
        video.width = width;
        video.height = height;

        streamRef.current = stream;
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  const takePhoto = () => {
    // const width = 200;
    // const height = width / (16 / 9);

    let video = videoRef.current;
    let photo = photoRef.current;

    photo.width = video.videoWidth;
    photo.height = video.videoHeight;

    photo.style.transform = "scaleX(-1)";
    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, photo.width, photo.height);

    // Convert canvas to data URL
    let dataURL = photo.toDataURL("image/jpeg");
    dataURLToBlob(dataURL).then((blob) => {
      let imageFile = new File([blob], "photo.jpg", { type: "image/jpeg" });
      setProfilePhoto(imageFile);
    });

    setHasPhoto(true);
    setTakeAphoto(false);

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const dataURLToBlob = async (dataURL) => {
    return fetch(dataURL).then((response) => response.blob());
  };

  const renderPopup = () => {

    const handleCancelBtnClick = () => {
      setTakeAphoto(false);

      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
    if (takeAphoto) {
      return (
        <div className="popup-wrapper">
          <div className="popup">
            <video ref={videoRef}></video>
            <div className="button-container">
              <Button
                className="button-cancel text-element-class"
                textButton="ביטול"
                onClick={handleCancelBtnClick}
              />
              <Button
                className="button-save"
                textButton="שמור"
                onClick={takePhoto}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  // eslint-disable-next-line
  const uploadProfilePhoto = async () => {
    try {
      const formData = new FormData();
      formData.append("image", profilePhoto);
      axiosInstance.enableAuthHeader();
      const response = await axiosInstance.put("/api/user/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      // console.error("Error uploading the image:", error);
    }
  };

  const uploadID = async () => {
    try {
      const formData = new FormData();
      formData.append("image", idFile);
      axiosInstance.enableAuthHeader();
      const response = await axiosInstance.put("/api/user/idPhoto", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      // console.error("Error uploading the image:", error);
    }
  };

  const uploadImage = async () => {
    try {
      setUserHasClicked(true);
      if (!idFile || !profilePhoto) return;
      setIsLoading(true)
      await uploadProfilePhoto();
      await uploadID();
      onButtonClick();
      setIsLoading(false);
    } catch (error) {
      // console.error("Error uploading the image:", error);
    }
  };

  useEffect(() => {
    if(userHasClicked){
      if(!idFile) setIdFileError("יש להוסיף תמונת תעודת זהות")
      else setIdFileError("")
      if(!profilePhoto) setProfilePhotoError("יש להוסיף תמונת פרופיל")
      else setProfilePhotoError("")
    }
  }, [userHasClicked, idFile, profilePhoto])

  return (
    <>
      <div className={takeAphoto ? "cu-two darker" : "cu-two"}>
        <div className={takeAphoto ? "div darker" : "div"}>
          <div className="rectangle" />
          <img style={{opacity: isUpdate? 0:1}} className="progress-one" src={Progress} alt="" />
          <div className="text-wrapper-4">{isUpdate? "עדכון פרטים": "יצירת מנהל אזור"}</div>
          <div className="group">
            <div className="container">
              <div
                className={`overlap ${
                  idFile ? "uploaded-file-border" : ""
                }`}
                onClick={openIDModal}
              >
                <div
                  className={`text-wrapper-2 ${
                    idFile ? "uploaded-file-title" : ""
                  }`}
                >
                  צילום תעודה מזהה
                </div>

                {idFile ? (
                  <>
                    <TaskAltIcon className="solar-upload-bold"  style={{fill: "green"}} />
                  </>
                ) : (
                  <>
                    <img
                      className="solar-upload-bold"
                      alt="Solar upload bold"
                      src={uploadIcon}
                    />
                  </>
                )}


              </div>
            </div>
              {!!idFileError && <p>{idFileError}</p>}

            <div className="overlap-wrapper">
              <div
                className={`overlap ${
                  profilePhoto ? "uploaded-file-border" : ""
                }`}
                onClick={getVideo}
              >
                <div
                  className={`text-wrapper-3 ${
                    profilePhoto ? "uploaded-file-title" : ""
                  }`}
                >
                  תמונת פרופיל
                </div>
                {profilePhoto ? (
                  <>
                    <TaskAltIcon className="solar-upload-bold" style={{fill: "green"}}/>
                  </>
                ) : (
                  <>
                    <img
                      className="solar-upload-bold"
                      alt="Solar upload bold"
                      src={uploadIcon}
                    />
                  </>
                )}
              </div>
            <div className="takeApick__container">
              <canvas ref={photoRef}></canvas>
            </div>
            </div>
            
            {!!profilePhotoError && <p>{profilePhotoError}</p>}
            {renderPopup()}
          </div>
            <Button
              className="button-active-instance text-element-class"
              textButton={isLoading? "...שולח":isUpdate?"עדכן פרטים": "סיום ויצירת פרופיל"}
              onClick={uploadImage}
              disabled={buttonDisable}
            />
            {isUpdate && <Button
              className="button-active-instance text-element-class"
              textButton={"ביטול"}
              onClick={cancelUpdate}
              disabled={buttonDisable}
            />}
        </div>
      </div>
      {  
        isIdModalOpen && <TakeIDPhotoModal 
          closeModal={closeIDModal}
          idFile={idFile}
          setIdFile={setIdFile}
        />
      }
    </>
  );
};

export default CAStepTwo;
